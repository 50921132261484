export const VIOLATIONS_DCT = {
    vLicence: {slug: 'vLicence', name: 'Не переоформлена лицензия'},
    vTransport: {slug: 'vTransport', name: 'Транспортные средства не оснащены ГЛОНАСС'},
    vScheme: {slug: 'vScheme', name: 'Нарушена схема потоков'},
    vInvent: {slug: 'vInvent', name: 'Не проведена инвентаризация ОРО'},
    vGroro: {slug: 'vGroro', name: 'ОРО не внесены в ГРОРО'},
    vEmployee: {slug: 'vEmployee', name: 'Не обучены сотрудники, нарушение лицензионных требований'},
    vAir: {slug: 'vAir', name: 'Нарушения в области охраны атмосферного воздуха'},
    vWater: {slug: 'vWater', name: 'Нарушения в области охраны водных ресурсов'},
    vLand: {slug: 'vLand', name: 'Нарушения в области охраны земель'},
    vDoc: {slug: 'vDoc', name: 'Документарные нарушения'},
};
