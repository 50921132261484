export const DCT_COUNTERS = {
    groro_status: {
        slug: 'groro_status',
        parent: '',
        namePlural: 'Количество полигонов в ГРОРО',
        nameSingle: 'Количество полигонов в ГРОРО',
        type: 'quantity',
        ready: '0',
        warning: '0',
        danger: '0',
        min: 0, max: 100, value: 1175,
    },
    mnro_status: {
        slug: 'mnro_status',
        parent: '',
        namePlural: 'Количество санкционированных МНРО',
        nameSingle: 'Количество санкционированных МНРО',
        type: 'quantity',
        ready: '0',
        warning: '0',
        danger: '0',
        min: 0, max: 10, value: 5471,
    },
    ro_total: {
        slug: 'ro_total',
        parent: '',
        namePlural: 'Общее количество РО',
        nameSingle: 'Количество региональных операторов',
        type: 'quantity',
        ready: '0',
        warning: '0',
        danger: '0',
        min: 0, max: 20, value: 224,
    },
    ro_canceled: {
        slug: 'ro_canceled',
        parent: '',
        namePlural: 'Количество РО, соглашение с которыми расторгнуто',
        nameSingle: 'Количество РО, соглашение с которыми расторгнуто',
        type: 'quantity',
        ready: '0',
        warning: '0',
        danger: '0',
        min: 0, max: 3, value: 9,
    },
}
