import { Component, NgZone } from '@angular/core';
import { StoreService } from 'src/app/store/store.service';
import { StateService } from 'src/app/store/state.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'apm-common-section_map',
    templateUrl: './common-map-section.component.html'
})
export class CommonMapSection {
    private _sectionSubscription:Subscription = new Subscription();

    constructor(
        public state: StateService,
        public router: Router,
        public route: ActivatedRoute,
    ){

    }

    ngOnInit(){
        this._sectionSubscription.add(
            this.route.paramMap.pipe(
                tap(p=> console.log('[DEV] paramMap CommonMapSection', this.route.routeConfig, p)),
                tap(paramsMap =>{
                    let section = paramsMap.get('section');
                    console.log('[DEV] at CommonMap for section', section);
                    this.state.adjustSectionTo('map');
                })
            ).subscribe()
        )
    }

    ngOnDestroy(): void {
        this._sectionSubscription.unsubscribe();
    }
}
