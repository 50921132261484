export const AMDevice = {

    // https://stackoverflow.com/a/4819886
    getIsTouchDevice() {
        if(
            ('ontouchstart' in window)
            // @ts-ignore
            || window.DocumentTouch && document instanceof DocumentTouch
        )
            return true;
        else
            // @ts-ignore
            return window.matchMedia(
                [
                    '(',
                    ' -webkit- -moz- -o- -ms- '.split(' ').join('touch-enabled),('),
                    'heartz',
                    ')'
                ].join('')
            ).matches;
    },
    isTouchDevice: false
}

AMDevice.isTouchDevice = AMDevice.getIsTouchDevice();
