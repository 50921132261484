export const DCT_BUNDLES_FRESHAIR = {
    emission_volume: {
        slug: 'emission_volume',
        name: 'Объем валовых выбросов',
        type: 'ktonn',
        data: 4100,
        max: 1729.30,
        dimension: 'тыс.тонн',
    },
    emission_reduction: {
        slug: 'emission_reduction',
        name: 'Снижение валовых выбросов',
        type: 'ktonn',
        data: 1736,
        dimension: 'тыс.тонн',
    },
    emission_reduction_percent: {
        slug: 'emission_reduction_percent',
        name: 'Снижение валовых выбросов',
        type: 'percent',
        data: 42.3,
        dimension: '%',
    },
    pollution_level: {
        slug: 'pollution_level',
        name: 'Городов с "Высоким" и "Очень высоким" уровнем загрязнения',
        type: 'quantity',
        data: 8,
        max: 100,
    },
}
