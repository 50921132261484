import { BUNDLES_TREATMENT } from './bundles_treatment';
import { DATA_DYNAMIC } from './dynamic';
import { Bundle, Plan, City, Organization } from './types';
import { DATA_STATIC } from './satic';
import { REGIONs } from './regions';
import { AMStore } from './am';
import { DATA_DYNAMIC_ZONEs } from './dynamic_zones';
import { DCT_CRITERIA } from './dct_criteria';
import { DCT_BUNDLES_FRESHAIR } from './dct_bundles_freshair';
import { DATA_READINESS } from './data_readiness';
import { DATA_VIOLATIONS } from './data_violations';
import { DCT_COUNTERS } from './dct_counters';
import { DCT_CITIES } from './dct_cities';
import { DATA_CITY_DYN } from './data_city_dyn';
import { DCT_PLANS } from './dct_plans';
import { DCT_ORGS } from './dct_orgs';
import { ORGANIZATONS } from './organizations';
import { DATA_STATUSTKO } from './data_statustko';
import { DCT_STATUSTKO_SLICES } from './dct_statustko_slices';
import { DATA_STATUSTKO_SUMMARY } from './data_statustko_summary';
export const SLICEsGROUPs = {
    'common': 'Общие',
    'important': 'Важные',
}

export const STOREDCT_ZONEs = Object.keys(DATA_DYNAMIC_ZONEs).reduce(
    (acc, key)=>{
        acc[key] = AMStore.parseFloat(DATA_DYNAMIC_ZONEs[key]);
        return acc;
    }, {}
);

let total = 1;
export const SLICEs_TREATMENT:{[key: string]:Bundle} = BUNDLES_TREATMENT.reduce((acc, item)=>{
    acc[item.slug] = item;
    let storeKey = `${item.slug}_all`;
    if( DATA_DYNAMIC[storeKey] )
        item.total = DATA_DYNAMIC[storeKey];
    else if( DATA_STATIC[storeKey] )
        item.total = DATA_STATIC[storeKey];
    item.cell = {
        type:'via-dim',
        data:{
            value: item.total,
            dimension: item.dimension,
        }
    }
    return acc;
}, {});

Object.keys(DCT_STATUSTKO_SLICES).forEach( slug =>{
    let bundle = DCT_STATUSTKO_SLICES[slug];
    bundle.name = bundle.namePlural;
    // bundle.cell = bundle;
    bundle.cell = {
        type:bundle.type,
        data:bundle,
    }
})
// @ts-ignore
export const SLICEs_STATUSTKO:{[key: string]:Bundle} = {...DCT_STATUSTKO_SLICES};

Object.keys(DCT_COUNTERS).forEach( slug =>{
    let bundle = DCT_COUNTERS[slug];
    bundle.name = bundle.namePlural;
    bundle.cell = bundle;
})
Object.keys(DCT_CRITERIA).forEach( slug =>{
    let bundle = DCT_CRITERIA[slug];
    bundle.name = bundle.namePlural;
    bundle.cell = {
        type:'readiness-stats',
        data: bundle,
    }
})
// @ts-ignore
export const SLICEs_READINESS:{[key: string]:Bundle} = {...DCT_CRITERIA, ...DCT_COUNTERS};

Object.keys(DCT_BUNDLES_FRESHAIR).forEach( slug =>{
    let bundle = DCT_BUNDLES_FRESHAIR[slug];
    bundle.cell = {
        type: bundle.type,
        value: bundle.data
    };
})
// @ts-ignore
export const SLICEs_FRESHAIR:{[key: string]:Bundle} = DCT_BUNDLES_FRESHAIR;

export const STOREDCT = {};
for( let dataKey in DATA_STATIC )
    STOREDCT[dataKey] = AMStore.parseFloat(DATA_STATIC[dataKey]);

for( let dataKey in DATA_DYNAMIC )
    STOREDCT[dataKey] = DATA_DYNAMIC[dataKey].map(valueStr => AMStore.parseFloat(valueStr) );

for( let region of REGIONs )
    [ "quantity", "density_min", "density_max", "norm_min", "norm_max",
    ].forEach( dataKey =>{ STOREDCT[`${dataKey}_${region.slug}`] = AMStore.parseFloat(region[dataKey]); });

for( let dataKey in DCT_CITIES ){
    let city:City = DCT_CITIES[dataKey];
    [ "x","y","emission_volume_2017","emission_volume_2024","emission_reduction","emission_reduction_percent","emission_prom_total","emission_trans_total","emission_comm_total","emission_energo_total","plans_total","money_fed_total","money_cons_total","money_other_total",
    ].forEach( propKey =>{ city[propKey] = AMStore.parseFloat(city[propKey]); });
    city.plans = [];
    city.orgs = [];
}

for( let dataKey in DCT_PLANS ){
    let plan:Plan = DCT_PLANS[dataKey];
    [ "count", "money_fed", "money_cons", "money_other", "result", "percent",
    ].forEach( propKey =>{ plan[propKey] = AMStore.parseFloat(plan[propKey]); });
    if( DCT_CITIES[plan.city_slug] ) DCT_CITIES[plan.city_slug].plans.push(plan);
}
// for( let dataKey in DCT_ORGS ){
//     let organization:Organization = DCT_ORGS[dataKey];
//     [ "emission_volume", "percent ",
//     ].forEach( propKey =>{ organization[propKey] = AMStore.parseFloat(organization[propKey]); });
//     if( DCT_CITIES[organization.city_slug] ) DCT_CITIES[organization.city_slug].orgs.push(organization);
// }
for( let org of ORGANIZATONS ){
    // [ "emission_volume", "percent ",
    // ].forEach( propKey =>{ organization[propKey] = AMStore.parseFloat(organization[propKey]); });
    if( DCT_CITIES[org.city_slug] ) DCT_CITIES[org.city_slug].orgs.push(org);
}

for( let dataKey in DATA_STATUSTKO )
    STOREDCT[dataKey] = DATA_STATUSTKO[dataKey];

for( let dataKey in DATA_READINESS )
    STOREDCT[dataKey] = DATA_READINESS[dataKey];

for( let dataKey in DATA_VIOLATIONS )
    STOREDCT[dataKey] = DATA_VIOLATIONS[dataKey];

for( let dataKey in DATA_CITY_DYN )
    STOREDCT[dataKey] = DATA_CITY_DYN[dataKey];

for( let dataKey in DATA_STATUSTKO_SUMMARY )
    STOREDCT[dataKey] = DATA_STATUSTKO_SUMMARY[dataKey];

const UTILIZATION_MATERIALS = [
    'paper',
    'metall',
    'glass',
    'plastic',
    'other',
];

for( let region of REGIONs ){
    let ringData, additiveRotation = -90;
    let total = UTILIZATION_MATERIALS.reduce(( acc, key )=> acc+STOREDCT[`object_utilization_${key}_power_${region.slug}`], 0);
    if( total > 0 ){
        console.log('UTILIZATION', region, total);
        ringData = UTILIZATION_MATERIALS.map( key =>{
            let power = STOREDCT[`object_utilization_${key}_power_${region.slug}`];
            let ratio = power / total;
            let percent = Math.round(ratio * 100);
            let rotation = additiveRotation;
            additiveRotation = rotation + 360 * ratio;

            return {
                slug: key, value: power,
                ratio, percent, rotation,
            }
        })
        STOREDCT[`utilization_chart_${region.slug}`] = {
            ring: ringData,
            total: total,
        };
        console.log(' ->', ringData);
    };
}
    
console.log('[DEV] STOREDCT', STOREDCT);

export const MONTHs = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
]

export const YEARs = [
    '2019',
    '2020',
    '2021',
    '2022',
    '2023',
    '2024',
]

export const SLICEsDATA = {
    'brzvn_tkhdv_ts_tnn':[
        {}
    ]
}
