import { Bundle } from './types';

export const BUNDLES_TREATMENT:Bundle[] = [
    //DYNAMIC
    {
        slug:'per_person_min',
        name:'Плата граждан (утвержденная и прогноз <br> по данным КД) - минимум',
        dimension: '₽/чел в мес.',
        min: 0,
        max: 174,
        isDynamic: true,
    }, 
    {
        slug:'per_person_max',
        name:'Плата граждан (утвержденная и прогноз <br> по данным КД) - максимум',
        dimension: '₽/чел в мес.',
        min: 0,
        max: 188.59,
        isDynamic: true,
    }, 
    {
        slug:'tarif_min',
        name:'Тариф - минимум',
        dimension: '₽/куб.м',
        min: 0,
        max: 1000,
        isDynamic: true,
    }, 
    {
        slug:'tarif_max',
        name:'Тариф - максимум',
        dimension: '₽/куб.м',
        min: 0,
        max: 1000,
        isDynamic: true,
    }, 
    {
        slug:'invest',
        name:'Требуемые инвестиции',
        dimension: 'млрд.₽',
        min: 0,
        max: 3,
        isDynamic: true,
    }, 
    {
        slug:'rate_disposal',
        name:'Доля обезвреженных ТКО - план',
        dimension: '%',
        min: 0,
        max: 20,
        isDynamic: true,
    }, 
    {
        slug:'rate_treatment',
        name:'Доля обработанных ТКО - план',
        dimension: '%',
        min: 0,
        max: 100,
        isDynamic: true,
    }, 
    {
        slug:'rate_used',
        name:'Доля утилизированных ТКО - sплан',
        dimension: '%',
        min: 0,
        max: 1,
        isDynamic: true,
    }, 

    //STATIC
    { 
        slug:'quantity',
        name:'Объем образуемых отходов',
        dimension: 'тыс.куб.м',
        min: 0,
        max: 2000,
    }, 
    { 
        slug:'density_min',
        name:'Плотность отходов - минимум',
        dimension: 'кг/куб.м',
        min: 0,
        max: 200,
    }, 
    { 
        slug:'density_max',
        name:'Плотность отходов - максимум',
        dimension: 'кг/куб.м',
        min: 0,
        max: 200,
    }, 
    { 
        slug:'norm_min',
        name:'Норматив для граждан - минимум',
        dimension: 'куб.м/чел',
        min: 0,
        max: 5,
    }, 
    { 
        slug:'norm_max',
        name:'Норматив для граждан - максимум',
        dimension: 'куб.м/чел',
        min: 0,
        max: 5,
    }, 
];
