import { Directive, NgZone, Output, EventEmitter, Input } from "@angular/core";
import { Point, Group } from 'paper';
import { AMEvents } from '../am/events';
import { AMDevice } from '../am/device';
import { PaperComponent, PaperEvent, PaperTapEvent } from '../components/paper/paper.component';
declare const Hammer: any;

const ZOOM_MIN = 1;
const ZOOM_MAX = 4;

@Directive({
    selector: "apm-paper[panzoom]"
})
export class PanzoomDirective{
    @Input() zoomDisabled = false;
    @Output() panStart = new EventEmitter<PaperEvent>();
    @Output() panMove = new EventEmitter<PaperEvent>();
    @Output() pinchStart = new EventEmitter<PaperEvent>();
    @Output() pinchMove = new EventEmitter<PaperEvent>();
    @Output() wheelMove = new EventEmitter<PaperEvent>();
    @Output() layerTap = new EventEmitter<PaperTapEvent>();
    @Output() outclick = new EventEmitter<PaperEvent>();

    private onWheelBinded = this.onWheel.bind(this);
    private hammer;
    public rootPaperGroup:Group;

    private panStartX = 0;
    private panStartY = 0;
    private pinchStartZoom = 0;

    private rootPaperGroupPos: Point | null = null;
    private rootPaperGroupZoom: { direction: boolean, point: Point } | null = null;

    constructor( 
        private hostPaper:PaperComponent,  
        private zone: NgZone,
    ){}

    ngOnInit() {
        this.zone.runOutsideAngular(() => {
            this.initHandlers();
        });
    }

    public onFrameBinded = this.onFrame.bind(this);

    public normalizeZoom (zoom: number): number {
        if( zoom < ZOOM_MIN ) return ZOOM_MIN;
        if( zoom > ZOOM_MAX ) return ZOOM_MAX;
        return zoom;
    }

    private initHandlers() {
        this.hammer = new Hammer(this.hostPaper.element);
        this.hammer.get('pinch').set({ enable: true });
        this.hammer
            // .on('tap', this.onTapBinded)
            .on('panstart', this.onPanStartBinded)
            .on('panmove', this.onPanMoveBinded)
            .on('pinchstart', this.onPinchStartBinded)
            .on('pinchmove', this.onPinchMoveBinded);
        
        // this.hostPaper.element.addEventListener('click', this.onTap.bind(this) );
        this.hostPaper.element.addEventListener('click', event => {
            console.log('[DEV] paper clicked', event);
            this.outclick.emit( new PaperEvent(this.hostPaper, event));
        } );
        this.hostPaper.element.addEventListener('wheel', this.onWheelBinded);
    }

    private onFrame(){
        if (this.rootPaperGroupZoom !== null) {
            const oldZoom = this.hostPaper.projectView.zoom;
            let zoom = this.rootPaperGroupZoom.direction ? oldZoom * 0.9 : oldZoom / 0.9;
            zoom = this.normalizeZoom(zoom);
            if (this.rootPaperGroupPos === null) {

                const factor = 1 / oldZoom - 1 / zoom;
                const wrapPos = this.rootPaperGroup.position;
                const rootPaperGroupNewPos = wrapPos.subtract(this.rootPaperGroupZoom.point.subtract(wrapPos).multiply(factor));
                this.rootPaperGroupPos = rootPaperGroupNewPos;
            }
            this.hostPaper.projectView.zoom = zoom;
            this.rootPaperGroupZoom = null;
        }

        if (this.rootPaperGroupPos !== null) {
            this.rootPaperGroup.position = this.rootPaperGroupPos;
            this.rootPaperGroupPos = null;
        }
    }

    private onTap( event ){
        // event.
        let point = this.getOffsetPointByEventTarget(event);
        this.layerTap.emit(new PaperTapEvent(point, this.hostPaper, event))
    }

    private getOffsetPointByEventTarget( event ){
        if( event.srcEvent ) event = event.srcEvent;
        console.log('[DEV] event offset', event.offsetX, event.offsetY);
        
        if( event.offsetX && event.offsetY )
            return new Point(event.offsetX, event.offsetY);

        const rect = event.target.getBoundingClientRect();
        console.log('[DEV] rect', rect);
        return new Point(
            event.clientX - rect.left,
            event.clientY - rect.top
        );
    }

    // перемещение карты
    private onPanStartBinded = this.onPanStart.bind(this);
    private onPanStart (event) {
        event.preventDefault();
        const pos = this.rootPaperGroup.position;
        this.panStartX = pos.x;
        this.panStartY = pos.y;
        this.panStart.emit(new PaperEvent(this.hostPaper, event));
        // прячем попап
    }
    private onPanMoveBinded = this.onPanMove.bind(this);
    private onPanMove (event) {
        event.preventDefault();
        this.rootPaperGroupPos = new Point(
            this.panStartX + (event.deltaX / this.hostPaper.projectView.zoom),
            this.panStartY + (event.deltaY / this.hostPaper.projectView.zoom)
        );
        this.panMove.emit(new PaperEvent(this.hostPaper, event));
    }

    // масштабированеи карты
    private onPinchStartBinded = this.onPinchStart.bind(this);
    private onPinchStart( event ){
        if( this.zoomDisabled ) return;
        event.preventDefault();
        this.pinchStartZoom = this.hostPaper.projectView.zoom;
        this.pinchStart.emit(new PaperEvent(this.hostPaper, event));
        // прячем попап
    }
    private onPinchMoveBinded = this.onPinchMove.bind(this);
    private onPinchMove( event ){
        if( this.zoomDisabled ) return;
        event.preventDefault();
        const view = this.hostPaper.projectView;
        view.zoom = this.normalizeZoom(this.pinchStartZoom * event.scale);
        event.paper = this.hostPaper;
        this.pinchMove.emit(new PaperEvent(this.hostPaper, event));
    }

    private onWheel( event: WheelEvent ) {
        if( this.zoomDisabled ) return;
        event.preventDefault();
        const normalWheelEvent = this.normalizeWheel(event);
        const point = AMEvents.getOffsetPointByEventTarget(event);
        this.rootPaperGroupZoom = {
            direction: normalWheelEvent.spinY > 0,
            point
        };
        this.wheelMove.emit(new PaperEvent(this.hostPaper, event));
    }

    // https://github.com/facebookarchive/fixed-data-table/blob/master/src/vendor_upstream/dom/normalizeWheel.js
    private normalizeWheel (event) {
        const PIXEL_STEP  = 10;
        const LINE_HEIGHT = 40;
        const PAGE_HEIGHT = 800;

        let sX = 0, sY = 0,       // spinX, spinY
            pX = 0, pY = 0;       // pixelX, pixelY

        // Legacy
        if ('detail'      in event) { sY = event.detail; }
        if ('wheelDelta'  in event) { sY = -event.wheelDelta / 120; }
        if ('wheelDeltaY' in event) { sY = -event.wheelDeltaY / 120; }
        if ('wheelDeltaX' in event) { sX = -event.wheelDeltaX / 120; }

        // side scrolling on FF with DOMMouseScroll
        if ( 'axis' in event && event.axis === event.HORIZONTAL_AXIS ) {
            sX = sY;
            sY = 0;
        }

        pX = sX * PIXEL_STEP;
        pY = sY * PIXEL_STEP;

        if ('deltaY' in event) { pY = event.deltaY; }
        if ('deltaX' in event) { pX = event.deltaX; }

        if ((pX || pY) && event.deltaMode) {
            if (event.deltaMode === 1) {          // delta in LINE units
            pX *= LINE_HEIGHT;
            pY *= LINE_HEIGHT;
            } else {                             // delta in PAGE units
            pX *= PAGE_HEIGHT;
            pY *= PAGE_HEIGHT;
            }
        }

        // Fall-back if spin cannot be determined
        if (pX && !sX) { sX = (pX < 1) ? -1 : 1; }
        if (pY && !sY) { sY = (pY < 1) ? -1 : 1; }

        return {
            spinX  : sX,
            spinY  : sY,
            pixelX : pX,
            pixelY : pY
        };
    }
}
