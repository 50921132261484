import { Input, HostBinding, Component } from '@angular/core';
import { core } from '@angular/compiler';
import { StateService } from 'src/app/store/state.service';

export interface ICell{
    type:string,
    value?:any,
    data?:{
        value?:any | any[],
        dimension?:string,
        stats?:any,
    },
}

export const CellBaseComponentConfig = {
    host:{ class: 'b3-band b3-band_flex' },
    encapsulation: core.ViewEncapsulation.None,
    // changeDetection: core.ChangeDetectionStrategy.OnPush,
}
export class CellBaseComponent{
    protected state:StateService

    @Input() data:ICell;
    public get cell(): ICell { return this.data; }
    // @HostBinding('class.b3-group__parent') isParent: boolean;
    // @HostBinding('class.b3-group__child') isChild: boolean;
    constructor(  ){
        // console.log('[DEVDK] CellBaseComponent', this);
    }

    public get currentValue(){
        if( this.data && this.data.data && this.data.data.value ){
            let value = this.data.data.value;
            return (value instanceof Array) ? value[this.state.currentPeriod] : value;
        }else if( this.data && this.data.value ){
            let value = this.data.value;
            return (value instanceof Array) ? value[this.state.currentPeriod] : value;
        }
        return '-';
    }

    public get currentDimension(){
        if( this.data && this.data.data && this.data.data.dimension ){
            return this.data.data.dimension || '';
        }else
            return 'DIM?';
    }
}