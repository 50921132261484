import { Component } from "@angular/core";
import { StoreService } from '../../store/store.service';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

export const _filter = (opt: any[], value: string): string[] => {
    console.log('[DEV] form', {opt, value});
    const filterValue = value.toLowerCase();
    return opt.filter(item => item.name.toLowerCase().indexOf(filterValue) > -1);
};

@Component({
    selector: 'apm-data-grouped-selector',
    templateUrl: './data-grouped-selector.component.html',
    host:{class:'apm-data-grouped-selector'}
})
export class DataGroupedSelectorComponent{
    public myControl = new FormControl();
    stateForm: FormGroup = this.fb.group({
        stateGroup: '',
    });
    constructor(
        private fb: FormBuilder,
        public store:StoreService
    ){

    }

    stateGroupOptions: Observable<any[]>;

    ngOnInit() {
        this.stateGroupOptions =
            this.stateForm.get('stateGroup')!.valueChanges
            .pipe(
                startWith(''),
                map(value => this._filterGroup(value))
            );
    }

      private _filterGroup(value: string): any[] {
        if( value ){
          return this.store.dataBundlesGroups
            .map(group => ({...group, list: _filter(group.list, value)}))
            .filter(group => group.list.length > 0);
        }
    
        return this.store.dataBundlesGroups;
      }
}
