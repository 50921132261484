export const DATA_VIOLATIONS = {
    vLicence_ALT:{count:0,ro_names:''}, vTransport_ALT:{count:0,ro_names:''}, vScheme_ALT:{count:0,ro_names:''}, vInvent_ALT:{count:0,ro_names:''}, vGroro_ALT:{count:0,ro_names:''}, vEmployee_ALT:{count:0,ro_names:''}, vAir_ALT:{count:0,ro_names:''}, vWater_ALT:{count:0,ro_names:''}, vLand_ALT:{count:0,ro_names:''}, vDoc_ALT:{count:0,ro_names:''}, 
    vLicence_KDA:{count:0,ro_names:''}, vTransport_KDA:{count:0,ro_names:''}, vScheme_KDA:{count:0,ro_names:''}, vInvent_KDA:{count:0,ro_names:''}, vGroro_KDA:{count:0,ro_names:''}, vEmployee_KDA:{count:0,ro_names:''}, vAir_KDA:{count:1,ro_names:''}, vWater_KDA:{count:0,ro_names:''}, vLand_KDA:{count:0,ro_names:''}, vDoc_KDA:{count:5,ro_names:''}, 
    vLicence_KYA:{count:0,ro_names:''}, vTransport_KYA:{count:0,ro_names:''}, vScheme_KYA:{count:0,ro_names:''}, vInvent_KYA:{count:0,ro_names:''}, vGroro_KYA:{count:0,ro_names:''}, vEmployee_KYA:{count:0,ro_names:''}, vAir_KYA:{count:0,ro_names:''}, vWater_KYA:{count:0,ro_names:''}, vLand_KYA:{count:0,ro_names:''}, vDoc_KYA:{count:0,ro_names:''}, 
    vLicence_PRI:{count:0,ro_names:''}, vTransport_PRI:{count:0,ro_names:''}, vScheme_PRI:{count:0,ro_names:''}, vInvent_PRI:{count:0,ro_names:''}, vGroro_PRI:{count:0,ro_names:''}, vEmployee_PRI:{count:0,ro_names:''}, vAir_PRI:{count:0,ro_names:''}, vWater_PRI:{count:0,ro_names:''}, vLand_PRI:{count:0,ro_names:''}, vDoc_PRI:{count:0,ro_names:''}, 
    vLicence_STA:{count:4,ro_names:''}, vTransport_STA:{count:0,ro_names:''}, vScheme_STA:{count:0,ro_names:''}, vInvent_STA:{count:0,ro_names:''}, vGroro_STA:{count:0,ro_names:''}, vEmployee_STA:{count:1,ro_names:''}, vAir_STA:{count:4,ro_names:''}, vWater_STA:{count:13,ro_names:''}, vLand_STA:{count:7,ro_names:''}, vDoc_STA:{count:29,ro_names:''}, 
    vLicence_KHA:{count:0,ro_names:''}, vTransport_KHA:{count:0,ro_names:''}, vScheme_KHA:{count:0,ro_names:''}, vInvent_KHA:{count:0,ro_names:''}, vGroro_KHA:{count:0,ro_names:''}, vEmployee_KHA:{count:0,ro_names:''}, vAir_KHA:{count:0,ro_names:''}, vWater_KHA:{count:0,ro_names:''}, vLand_KHA:{count:0,ro_names:''}, vDoc_KHA:{count:0,ro_names:''}, 
    vLicence_AMU:{count:0,ro_names:''}, vTransport_AMU:{count:0,ro_names:''}, vScheme_AMU:{count:0,ro_names:''}, vInvent_AMU:{count:0,ro_names:''}, vGroro_AMU:{count:0,ro_names:''}, vEmployee_AMU:{count:2,ro_names:''}, vAir_AMU:{count:1,ro_names:''}, vWater_AMU:{count:0,ro_names:''}, vLand_AMU:{count:0,ro_names:''}, vDoc_AMU:{count:0,ro_names:''}, 
    vLicence_ARK:{count:0,ro_names:''}, vTransport_ARK:{count:0,ro_names:''}, vScheme_ARK:{count:0,ro_names:''}, vInvent_ARK:{count:0,ro_names:''}, vGroro_ARK:{count:0,ro_names:''}, vEmployee_ARK:{count:0,ro_names:''}, vAir_ARK:{count:0,ro_names:''}, vWater_ARK:{count:0,ro_names:''}, vLand_ARK:{count:0,ro_names:''}, vDoc_ARK:{count:0,ro_names:''}, 
    vLicence_AST:{count:0,ro_names:''}, vTransport_AST:{count:0,ro_names:''}, vScheme_AST:{count:0,ro_names:''}, vInvent_AST:{count:0,ro_names:''}, vGroro_AST:{count:1,ro_names:''}, vEmployee_AST:{count:0,ro_names:''}, vAir_AST:{count:5,ro_names:''}, vWater_AST:{count:0,ro_names:''}, vLand_AST:{count:3,ro_names:''}, vDoc_AST:{count:2,ro_names:''}, 
    vLicence_BEL:{count:0,ro_names:''}, vTransport_BEL:{count:0,ro_names:''}, vScheme_BEL:{count:0,ro_names:''}, vInvent_BEL:{count:0,ro_names:''}, vGroro_BEL:{count:0,ro_names:''}, vEmployee_BEL:{count:0,ro_names:''}, vAir_BEL:{count:0,ro_names:''}, vWater_BEL:{count:0,ro_names:''}, vLand_BEL:{count:0,ro_names:''}, vDoc_BEL:{count:0,ro_names:''}, 
    vLicence_BRY:{count:0,ro_names:''}, vTransport_BRY:{count:0,ro_names:''}, vScheme_BRY:{count:0,ro_names:''}, vInvent_BRY:{count:0,ro_names:''}, vGroro_BRY:{count:0,ro_names:''}, vEmployee_BRY:{count:0,ro_names:''}, vAir_BRY:{count:0,ro_names:''}, vWater_BRY:{count:0,ro_names:''}, vLand_BRY:{count:0,ro_names:''}, vDoc_BRY:{count:0,ro_names:''}, 
    vLicence_VLA:{count:0,ro_names:''}, vTransport_VLA:{count:0,ro_names:''}, vScheme_VLA:{count:0,ro_names:''}, vInvent_VLA:{count:0,ro_names:''}, vGroro_VLA:{count:0,ro_names:''}, vEmployee_VLA:{count:0,ro_names:''}, vAir_VLA:{count:0,ro_names:''}, vWater_VLA:{count:0,ro_names:''}, vLand_VLA:{count:0,ro_names:''}, vDoc_VLA:{count:0,ro_names:''}, 
    vLicence_VGG:{count:0,ro_names:''}, vTransport_VGG:{count:0,ro_names:''}, vScheme_VGG:{count:0,ro_names:''}, vInvent_VGG:{count:0,ro_names:''}, vGroro_VGG:{count:0,ro_names:''}, vEmployee_VGG:{count:0,ro_names:''}, vAir_VGG:{count:0,ro_names:''}, vWater_VGG:{count:0,ro_names:''}, vLand_VGG:{count:0,ro_names:''}, vDoc_VGG:{count:0,ro_names:''}, 
    vLicence_VLG:{count:0,ro_names:''}, vTransport_VLG:{count:0,ro_names:''}, vScheme_VLG:{count:0,ro_names:''}, vInvent_VLG:{count:0,ro_names:''}, vGroro_VLG:{count:0,ro_names:''}, vEmployee_VLG:{count:0,ro_names:''}, vAir_VLG:{count:0,ro_names:''}, vWater_VLG:{count:0,ro_names:''}, vLand_VLG:{count:0,ro_names:''}, vDoc_VLG:{count:0,ro_names:''}, 
    vLicence_VOR:{count:0,ro_names:''}, vTransport_VOR:{count:0,ro_names:''}, vScheme_VOR:{count:0,ro_names:''}, vInvent_VOR:{count:0,ro_names:''}, vGroro_VOR:{count:0,ro_names:''}, vEmployee_VOR:{count:0,ro_names:''}, vAir_VOR:{count:0,ro_names:''}, vWater_VOR:{count:0,ro_names:''}, vLand_VOR:{count:0,ro_names:''}, vDoc_VOR:{count:0,ro_names:''}, 
    vLicence_NIZ:{count:0,ro_names:''}, vTransport_NIZ:{count:0,ro_names:''}, vScheme_NIZ:{count:0,ro_names:''}, vInvent_NIZ:{count:0,ro_names:''}, vGroro_NIZ:{count:0,ro_names:''}, vEmployee_NIZ:{count:0,ro_names:''}, vAir_NIZ:{count:0,ro_names:''}, vWater_NIZ:{count:0,ro_names:''}, vLand_NIZ:{count:0,ro_names:''}, vDoc_NIZ:{count:0,ro_names:''}, 
    vLicence_IVA:{count:0,ro_names:''}, vTransport_IVA:{count:0,ro_names:''}, vScheme_IVA:{count:0,ro_names:''}, vInvent_IVA:{count:0,ro_names:''}, vGroro_IVA:{count:0,ro_names:''}, vEmployee_IVA:{count:0,ro_names:''}, vAir_IVA:{count:0,ro_names:''}, vWater_IVA:{count:0,ro_names:''}, vLand_IVA:{count:0,ro_names:''}, vDoc_IVA:{count:0,ro_names:''}, 
    vLicence_IRK:{count:0,ro_names:''}, vTransport_IRK:{count:0,ro_names:''}, vScheme_IRK:{count:0,ro_names:''}, vInvent_IRK:{count:0,ro_names:''}, vGroro_IRK:{count:0,ro_names:''}, vEmployee_IRK:{count:0,ro_names:''}, vAir_IRK:{count:2,ro_names:''}, vWater_IRK:{count:0,ro_names:''}, vLand_IRK:{count:0,ro_names:''}, vDoc_IRK:{count:5,ro_names:''}, 
    vLicence_IN:{count:0,ro_names:''}, vTransport_IN:{count:0,ro_names:''}, vScheme_IN:{count:0,ro_names:''}, vInvent_IN:{count:0,ro_names:''}, vGroro_IN:{count:0,ro_names:''}, vEmployee_IN:{count:0,ro_names:''}, vAir_IN:{count:0,ro_names:''}, vWater_IN:{count:0,ro_names:''}, vLand_IN:{count:0,ro_names:''}, vDoc_IN:{count:0,ro_names:''}, 
    vLicence_KGD:{count:0,ro_names:''}, vTransport_KGD:{count:0,ro_names:''}, vScheme_KGD:{count:0,ro_names:''}, vInvent_KGD:{count:0,ro_names:''}, vGroro_KGD:{count:0,ro_names:''}, vEmployee_KGD:{count:0,ro_names:''}, vAir_KGD:{count:0,ro_names:''}, vWater_KGD:{count:0,ro_names:''}, vLand_KGD:{count:0,ro_names:''}, vDoc_KGD:{count:0,ro_names:''}, 
    vLicence_TVE:{count:0,ro_names:''}, vTransport_TVE:{count:0,ro_names:''}, vScheme_TVE:{count:0,ro_names:''}, vInvent_TVE:{count:0,ro_names:''}, vGroro_TVE:{count:0,ro_names:''}, vEmployee_TVE:{count:0,ro_names:''}, vAir_TVE:{count:0,ro_names:''}, vWater_TVE:{count:0,ro_names:''}, vLand_TVE:{count:0,ro_names:''}, vDoc_TVE:{count:0,ro_names:''}, 
    vLicence_KLU:{count:0,ro_names:''}, vTransport_KLU:{count:0,ro_names:''}, vScheme_KLU:{count:0,ro_names:''}, vInvent_KLU:{count:0,ro_names:''}, vGroro_KLU:{count:0,ro_names:''}, vEmployee_KLU:{count:0,ro_names:''}, vAir_KLU:{count:0,ro_names:''}, vWater_KLU:{count:0,ro_names:''}, vLand_KLU:{count:0,ro_names:''}, vDoc_KLU:{count:4,ro_names:''}, 
    vLicence_KAM:{count:6,ro_names:''}, vTransport_KAM:{count:0,ro_names:''}, vScheme_KAM:{count:0,ro_names:''}, vInvent_KAM:{count:0,ro_names:''}, vGroro_KAM:{count:0,ro_names:''}, vEmployee_KAM:{count:0,ro_names:''}, vAir_KAM:{count:0,ro_names:''}, vWater_KAM:{count:0,ro_names:''}, vLand_KAM:{count:0,ro_names:''}, vDoc_KAM:{count:0,ro_names:''}, 
    vLicence_KEM:{count:0,ro_names:''}, vTransport_KEM:{count:0,ro_names:''}, vScheme_KEM:{count:0,ro_names:''}, vInvent_KEM:{count:3,ro_names:''}, vGroro_KEM:{count:0,ro_names:''}, vEmployee_KEM:{count:1,ro_names:''}, vAir_KEM:{count:2,ro_names:''}, vWater_KEM:{count:0,ro_names:''}, vLand_KEM:{count:1,ro_names:''}, vDoc_KEM:{count:11,ro_names:''}, 
    vLicence_KIR:{count:0,ro_names:''}, vTransport_KIR:{count:0,ro_names:''}, vScheme_KIR:{count:0,ro_names:''}, vInvent_KIR:{count:0,ro_names:''}, vGroro_KIR:{count:0,ro_names:''}, vEmployee_KIR:{count:0,ro_names:''}, vAir_KIR:{count:0,ro_names:''}, vWater_KIR:{count:0,ro_names:''}, vLand_KIR:{count:0,ro_names:''}, vDoc_KIR:{count:0,ro_names:''}, 
    vLicence_KOS:{count:0,ro_names:''}, vTransport_KOS:{count:0,ro_names:''}, vScheme_KOS:{count:0,ro_names:''}, vInvent_KOS:{count:0,ro_names:''}, vGroro_KOS:{count:0,ro_names:''}, vEmployee_KOS:{count:0,ro_names:''}, vAir_KOS:{count:1,ro_names:''}, vWater_KOS:{count:1,ro_names:''}, vLand_KOS:{count:1,ro_names:''}, vDoc_KOS:{count:2,ro_names:''}, 
    vLicence_CR:{count:0,ro_names:''}, vTransport_CR:{count:0,ro_names:''}, vScheme_CR:{count:0,ro_names:''}, vInvent_CR:{count:0,ro_names:''}, vGroro_CR:{count:0,ro_names:''}, vEmployee_CR:{count:0,ro_names:''}, vAir_CR:{count:0,ro_names:''}, vWater_CR:{count:0,ro_names:''}, vLand_CR:{count:0,ro_names:''}, vDoc_CR:{count:0,ro_names:''}, 
    vLicence_SAM:{count:0,ro_names:''}, vTransport_SAM:{count:0,ro_names:''}, vScheme_SAM:{count:0,ro_names:''}, vInvent_SAM:{count:0,ro_names:''}, vGroro_SAM:{count:0,ro_names:''}, vEmployee_SAM:{count:0,ro_names:''}, vAir_SAM:{count:0,ro_names:''}, vWater_SAM:{count:0,ro_names:''}, vLand_SAM:{count:0,ro_names:''}, vDoc_SAM:{count:2,ro_names:''}, 
    vLicence_KGN:{count:0,ro_names:''}, vTransport_KGN:{count:0,ro_names:''}, vScheme_KGN:{count:0,ro_names:''}, vInvent_KGN:{count:0,ro_names:''}, vGroro_KGN:{count:0,ro_names:''}, vEmployee_KGN:{count:0,ro_names:''}, vAir_KGN:{count:0,ro_names:''}, vWater_KGN:{count:0,ro_names:''}, vLand_KGN:{count:0,ro_names:''}, vDoc_KGN:{count:0,ro_names:''}, 
    vLicence_KRS:{count:0,ro_names:''}, vTransport_KRS:{count:0,ro_names:''}, vScheme_KRS:{count:0,ro_names:''}, vInvent_KRS:{count:0,ro_names:''}, vGroro_KRS:{count:0,ro_names:''}, vEmployee_KRS:{count:0,ro_names:''}, vAir_KRS:{count:0,ro_names:''}, vWater_KRS:{count:0,ro_names:''}, vLand_KRS:{count:0,ro_names:''}, vDoc_KRS:{count:0,ro_names:''}, 
    vLicence_LEN:{count:0,ro_names:''}, vTransport_LEN:{count:0,ro_names:''}, vScheme_LEN:{count:0,ro_names:''}, vInvent_LEN:{count:0,ro_names:''}, vGroro_LEN:{count:0,ro_names:''}, vEmployee_LEN:{count:0,ro_names:''}, vAir_LEN:{count:0,ro_names:''}, vWater_LEN:{count:0,ro_names:''}, vLand_LEN:{count:0,ro_names:''}, vDoc_LEN:{count:0,ro_names:''}, 
    vLicence_SPE:{count:0,ro_names:''}, vTransport_SPE:{count:0,ro_names:''}, vScheme_SPE:{count:0,ro_names:''}, vInvent_SPE:{count:0,ro_names:''}, vGroro_SPE:{count:0,ro_names:''}, vEmployee_SPE:{count:0,ro_names:''}, vAir_SPE:{count:0,ro_names:''}, vWater_SPE:{count:0,ro_names:''}, vLand_SPE:{count:0,ro_names:''}, vDoc_SPE:{count:0,ro_names:''}, 
    vLicence_LIP:{count:0,ro_names:''}, vTransport_LIP:{count:0,ro_names:''}, vScheme_LIP:{count:0,ro_names:''}, vInvent_LIP:{count:0,ro_names:''}, vGroro_LIP:{count:0,ro_names:''}, vEmployee_LIP:{count:0,ro_names:''}, vAir_LIP:{count:0,ro_names:''}, vWater_LIP:{count:0,ro_names:''}, vLand_LIP:{count:0,ro_names:''}, vDoc_LIP:{count:0,ro_names:''}, 
    vLicence_MAG:{count:1,ro_names:''}, vTransport_MAG:{count:0,ro_names:''}, vScheme_MAG:{count:0,ro_names:''}, vInvent_MAG:{count:0,ro_names:''}, vGroro_MAG:{count:2,ro_names:''}, vEmployee_MAG:{count:0,ro_names:''}, vAir_MAG:{count:3,ro_names:''}, vWater_MAG:{count:1,ro_names:''}, vLand_MAG:{count:0,ro_names:''}, vDoc_MAG:{count:1,ro_names:''}, 
    vLicence_MOW:{count:0,ro_names:''}, vTransport_MOW:{count:0,ro_names:''}, vScheme_MOW:{count:0,ro_names:''}, vInvent_MOW:{count:0,ro_names:''}, vGroro_MOW:{count:0,ro_names:''}, vEmployee_MOW:{count:0,ro_names:''}, vAir_MOW:{count:0,ro_names:''}, vWater_MOW:{count:0,ro_names:''}, vLand_MOW:{count:0,ro_names:''}, vDoc_MOW:{count:0,ro_names:''}, 
    vLicence_MOS:{count:0,ro_names:''}, vTransport_MOS:{count:0,ro_names:''}, vScheme_MOS:{count:0,ro_names:''}, vInvent_MOS:{count:0,ro_names:''}, vGroro_MOS:{count:0,ro_names:''}, vEmployee_MOS:{count:0,ro_names:''}, vAir_MOS:{count:0,ro_names:''}, vWater_MOS:{count:0,ro_names:''}, vLand_MOS:{count:0,ro_names:''}, vDoc_MOS:{count:2,ro_names:''}, 
    vLicence_MUR:{count:0,ro_names:''}, vTransport_MUR:{count:0,ro_names:''}, vScheme_MUR:{count:0,ro_names:''}, vInvent_MUR:{count:0,ro_names:''}, vGroro_MUR:{count:0,ro_names:''}, vEmployee_MUR:{count:0,ro_names:''}, vAir_MUR:{count:0,ro_names:''}, vWater_MUR:{count:0,ro_names:''}, vLand_MUR:{count:0,ro_names:''}, vDoc_MUR:{count:0,ro_names:''}, 
    vLicence_NGR:{count:0,ro_names:''}, vTransport_NGR:{count:0,ro_names:''}, vScheme_NGR:{count:0,ro_names:''}, vInvent_NGR:{count:0,ro_names:''}, vGroro_NGR:{count:0,ro_names:''}, vEmployee_NGR:{count:0,ro_names:''}, vAir_NGR:{count:0,ro_names:''}, vWater_NGR:{count:0,ro_names:''}, vLand_NGR:{count:0,ro_names:''}, vDoc_NGR:{count:0,ro_names:''}, 
    vLicence_NVS:{count:0,ro_names:''}, vTransport_NVS:{count:0,ro_names:''}, vScheme_NVS:{count:0,ro_names:''}, vInvent_NVS:{count:0,ro_names:''}, vGroro_NVS:{count:0,ro_names:''}, vEmployee_NVS:{count:0,ro_names:''}, vAir_NVS:{count:0,ro_names:''}, vWater_NVS:{count:0,ro_names:''}, vLand_NVS:{count:0,ro_names:''}, vDoc_NVS:{count:0,ro_names:''}, 
    vLicence_OMS:{count:0,ro_names:''}, vTransport_OMS:{count:0,ro_names:''}, vScheme_OMS:{count:0,ro_names:''}, vInvent_OMS:{count:0,ro_names:''}, vGroro_OMS:{count:0,ro_names:''}, vEmployee_OMS:{count:0,ro_names:''}, vAir_OMS:{count:0,ro_names:''}, vWater_OMS:{count:0,ro_names:''}, vLand_OMS:{count:0,ro_names:''}, vDoc_OMS:{count:0,ro_names:''}, 
    vLicence_ORE:{count:0,ro_names:''}, vTransport_ORE:{count:0,ro_names:''}, vScheme_ORE:{count:0,ro_names:''}, vInvent_ORE:{count:0,ro_names:''}, vGroro_ORE:{count:0,ro_names:''}, vEmployee_ORE:{count:0,ro_names:''}, vAir_ORE:{count:0,ro_names:''}, vWater_ORE:{count:0,ro_names:''}, vLand_ORE:{count:0,ro_names:''}, vDoc_ORE:{count:0,ro_names:''}, 
    vLicence_ORL:{count:0,ro_names:''}, vTransport_ORL:{count:0,ro_names:''}, vScheme_ORL:{count:0,ro_names:''}, vInvent_ORL:{count:0,ro_names:''}, vGroro_ORL:{count:0,ro_names:''}, vEmployee_ORL:{count:0,ro_names:''}, vAir_ORL:{count:0,ro_names:''}, vWater_ORL:{count:0,ro_names:''}, vLand_ORL:{count:0,ro_names:''}, vDoc_ORL:{count:0,ro_names:''}, 
    vLicence_PNZ:{count:0,ro_names:''}, vTransport_PNZ:{count:0,ro_names:''}, vScheme_PNZ:{count:0,ro_names:''}, vInvent_PNZ:{count:0,ro_names:''}, vGroro_PNZ:{count:0,ro_names:''}, vEmployee_PNZ:{count:0,ro_names:''}, vAir_PNZ:{count:0,ro_names:''}, vWater_PNZ:{count:0,ro_names:''}, vLand_PNZ:{count:0,ro_names:''}, vDoc_PNZ:{count:0,ro_names:''}, 
    vLicence_PER:{count:0,ro_names:''}, vTransport_PER:{count:0,ro_names:''}, vScheme_PER:{count:0,ro_names:''}, vInvent_PER:{count:0,ro_names:''}, vGroro_PER:{count:0,ro_names:''}, vEmployee_PER:{count:0,ro_names:''}, vAir_PER:{count:0,ro_names:''}, vWater_PER:{count:0,ro_names:''}, vLand_PER:{count:0,ro_names:''}, vDoc_PER:{count:2,ro_names:''}, 
    vLicence_PSK:{count:0,ro_names:''}, vTransport_PSK:{count:0,ro_names:''}, vScheme_PSK:{count:0,ro_names:''}, vInvent_PSK:{count:1,ro_names:''}, vGroro_PSK:{count:0,ro_names:''}, vEmployee_PSK:{count:0,ro_names:''}, vAir_PSK:{count:3,ro_names:''}, vWater_PSK:{count:0,ro_names:''}, vLand_PSK:{count:0,ro_names:''}, vDoc_PSK:{count:6,ro_names:''}, 
    vLicence_ROS:{count:0,ro_names:''}, vTransport_ROS:{count:0,ro_names:''}, vScheme_ROS:{count:0,ro_names:''}, vInvent_ROS:{count:0,ro_names:''}, vGroro_ROS:{count:0,ro_names:''}, vEmployee_ROS:{count:0,ro_names:''}, vAir_ROS:{count:0,ro_names:''}, vWater_ROS:{count:0,ro_names:''}, vLand_ROS:{count:0,ro_names:''}, vDoc_ROS:{count:0,ro_names:''}, 
    vLicence_RYA:{count:0,ro_names:''}, vTransport_RYA:{count:0,ro_names:''}, vScheme_RYA:{count:0,ro_names:''}, vInvent_RYA:{count:0,ro_names:''}, vGroro_RYA:{count:0,ro_names:''}, vEmployee_RYA:{count:0,ro_names:''}, vAir_RYA:{count:0,ro_names:''}, vWater_RYA:{count:0,ro_names:''}, vLand_RYA:{count:0,ro_names:''}, vDoc_RYA:{count:0,ro_names:''}, 
    vLicence_SAR:{count:0,ro_names:''}, vTransport_SAR:{count:0,ro_names:''}, vScheme_SAR:{count:0,ro_names:''}, vInvent_SAR:{count:0,ro_names:''}, vGroro_SAR:{count:0,ro_names:''}, vEmployee_SAR:{count:0,ro_names:''}, vAir_SAR:{count:0,ro_names:''}, vWater_SAR:{count:0,ro_names:''}, vLand_SAR:{count:0,ro_names:''}, vDoc_SAR:{count:0,ro_names:''}, 
    vLicence_SAK:{count:4,ro_names:''}, vTransport_SAK:{count:0,ro_names:''}, vScheme_SAK:{count:0,ro_names:''}, vInvent_SAK:{count:0,ro_names:''}, vGroro_SAK:{count:5,ro_names:''}, vEmployee_SAK:{count:0,ro_names:''}, vAir_SAK:{count:3,ro_names:''}, vWater_SAK:{count:0,ro_names:''}, vLand_SAK:{count:3,ro_names:''}, vDoc_SAK:{count:0,ro_names:''}, 
    vLicence_SVE:{count:0,ro_names:''}, vTransport_SVE:{count:0,ro_names:''}, vScheme_SVE:{count:0,ro_names:''}, vInvent_SVE:{count:0,ro_names:''}, vGroro_SVE:{count:0,ro_names:''}, vEmployee_SVE:{count:0,ro_names:''}, vAir_SVE:{count:0,ro_names:''}, vWater_SVE:{count:0,ro_names:''}, vLand_SVE:{count:0,ro_names:''}, vDoc_SVE:{count:0,ro_names:''}, 
    vLicence_SMO:{count:0,ro_names:''}, vTransport_SMO:{count:0,ro_names:''}, vScheme_SMO:{count:0,ro_names:''}, vInvent_SMO:{count:0,ro_names:''}, vGroro_SMO:{count:0,ro_names:''}, vEmployee_SMO:{count:0,ro_names:''}, vAir_SMO:{count:3,ro_names:''}, vWater_SMO:{count:0,ro_names:''}, vLand_SMO:{count:0,ro_names:''}, vDoc_SMO:{count:3,ro_names:''}, 
    vLicence_SEV:{count:0,ro_names:''}, vTransport_SEV:{count:0,ro_names:''}, vScheme_SEV:{count:0,ro_names:''}, vInvent_SEV:{count:0,ro_names:''}, vGroro_SEV:{count:0,ro_names:''}, vEmployee_SEV:{count:1,ro_names:''}, vAir_SEV:{count:1,ro_names:''}, vWater_SEV:{count:0,ro_names:''}, vLand_SEV:{count:0,ro_names:''}, vDoc_SEV:{count:2,ro_names:''}, 
    vLicence_TAM:{count:0,ro_names:''}, vTransport_TAM:{count:0,ro_names:''}, vScheme_TAM:{count:0,ro_names:''}, vInvent_TAM:{count:0,ro_names:''}, vGroro_TAM:{count:0,ro_names:''}, vEmployee_TAM:{count:0,ro_names:''}, vAir_TAM:{count:0,ro_names:''}, vWater_TAM:{count:0,ro_names:''}, vLand_TAM:{count:0,ro_names:''}, vDoc_TAM:{count:0,ro_names:''}, 
    vLicence_TOM:{count:0,ro_names:''}, vTransport_TOM:{count:0,ro_names:''}, vScheme_TOM:{count:0,ro_names:''}, vInvent_TOM:{count:0,ro_names:''}, vGroro_TOM:{count:0,ro_names:''}, vEmployee_TOM:{count:0,ro_names:''}, vAir_TOM:{count:0,ro_names:''}, vWater_TOM:{count:0,ro_names:''}, vLand_TOM:{count:0,ro_names:''}, vDoc_TOM:{count:0,ro_names:''}, 
    vLicence_TUL:{count:0,ro_names:''}, vTransport_TUL:{count:0,ro_names:''}, vScheme_TUL:{count:0,ro_names:''}, vInvent_TUL:{count:0,ro_names:''}, vGroro_TUL:{count:0,ro_names:''}, vEmployee_TUL:{count:0,ro_names:''}, vAir_TUL:{count:0,ro_names:''}, vWater_TUL:{count:0,ro_names:''}, vLand_TUL:{count:0,ro_names:''}, vDoc_TUL:{count:0,ro_names:''}, 
    vLicence_TYU:{count:0,ro_names:''}, vTransport_TYU:{count:0,ro_names:''}, vScheme_TYU:{count:0,ro_names:''}, vInvent_TYU:{count:0,ro_names:''}, vGroro_TYU:{count:0,ro_names:''}, vEmployee_TYU:{count:0,ro_names:''}, vAir_TYU:{count:0,ro_names:''}, vWater_TYU:{count:0,ro_names:''}, vLand_TYU:{count:0,ro_names:''}, vDoc_TYU:{count:19,ro_names:''}, 
    vLicence_ULY:{count:0,ro_names:''}, vTransport_ULY:{count:0,ro_names:''}, vScheme_ULY:{count:0,ro_names:''}, vInvent_ULY:{count:0,ro_names:''}, vGroro_ULY:{count:0,ro_names:''}, vEmployee_ULY:{count:0,ro_names:''}, vAir_ULY:{count:0,ro_names:''}, vWater_ULY:{count:0,ro_names:''}, vLand_ULY:{count:0,ro_names:''}, vDoc_ULY:{count:0,ro_names:''}, 
    vLicence_CHE:{count:0,ro_names:''}, vTransport_CHE:{count:0,ro_names:''}, vScheme_CHE:{count:0,ro_names:''}, vInvent_CHE:{count:0,ro_names:''}, vGroro_CHE:{count:0,ro_names:''}, vEmployee_CHE:{count:0,ro_names:''}, vAir_CHE:{count:0,ro_names:''}, vWater_CHE:{count:0,ro_names:''}, vLand_CHE:{count:0,ro_names:''}, vDoc_CHE:{count:0,ro_names:''}, 
    vLicence_ZAB:{count:0,ro_names:''}, vTransport_ZAB:{count:0,ro_names:''}, vScheme_ZAB:{count:0,ro_names:''}, vInvent_ZAB:{count:0,ro_names:''}, vGroro_ZAB:{count:0,ro_names:''}, vEmployee_ZAB:{count:0,ro_names:''}, vAir_ZAB:{count:0,ro_names:''}, vWater_ZAB:{count:0,ro_names:''}, vLand_ZAB:{count:0,ro_names:''}, vDoc_ZAB:{count:0,ro_names:''}, 
    vLicence_CHU:{count:18,ro_names:''}, vTransport_CHU:{count:21,ro_names:''}, vScheme_CHU:{count:0,ro_names:''}, vInvent_CHU:{count:0,ro_names:''}, vGroro_CHU:{count:24,ro_names:''}, vEmployee_CHU:{count:6,ro_names:''}, vAir_CHU:{count:0,ro_names:''}, vWater_CHU:{count:0,ro_names:''}, vLand_CHU:{count:0,ro_names:''}, vDoc_CHU:{count:3,ro_names:''}, 
    vLicence_YAR:{count:0,ro_names:''}, vTransport_YAR:{count:0,ro_names:''}, vScheme_YAR:{count:0,ro_names:''}, vInvent_YAR:{count:0,ro_names:''}, vGroro_YAR:{count:0,ro_names:''}, vEmployee_YAR:{count:0,ro_names:''}, vAir_YAR:{count:0,ro_names:''}, vWater_YAR:{count:0,ro_names:''}, vLand_YAR:{count:0,ro_names:''}, vDoc_YAR:{count:1,ro_names:''}, 
    vLicence_AD:{count:0,ro_names:''}, vTransport_AD:{count:0,ro_names:''}, vScheme_AD:{count:0,ro_names:''}, vInvent_AD:{count:0,ro_names:''}, vGroro_AD:{count:0,ro_names:''}, vEmployee_AD:{count:0,ro_names:''}, vAir_AD:{count:0,ro_names:''}, vWater_AD:{count:0,ro_names:''}, vLand_AD:{count:0,ro_names:''}, vDoc_AD:{count:0,ro_names:''}, 
    vLicence_BA:{count:0,ro_names:''}, vTransport_BA:{count:0,ro_names:''}, vScheme_BA:{count:0,ro_names:''}, vInvent_BA:{count:0,ro_names:''}, vGroro_BA:{count:0,ro_names:''}, vEmployee_BA:{count:0,ro_names:''}, vAir_BA:{count:0,ro_names:''}, vWater_BA:{count:0,ro_names:''}, vLand_BA:{count:0,ro_names:''}, vDoc_BA:{count:0,ro_names:''}, 
    vLicence_BU:{count:0,ro_names:''}, vTransport_BU:{count:0,ro_names:''}, vScheme_BU:{count:0,ro_names:''}, vInvent_BU:{count:0,ro_names:''}, vGroro_BU:{count:0,ro_names:''}, vEmployee_BU:{count:0,ro_names:''}, vAir_BU:{count:0,ro_names:''}, vWater_BU:{count:0,ro_names:''}, vLand_BU:{count:0,ro_names:''}, vDoc_BU:{count:0,ro_names:''}, 
    vLicence_DA:{count:5,ro_names:''}, vTransport_DA:{count:4,ro_names:''}, vScheme_DA:{count:0,ro_names:''}, vInvent_DA:{count:0,ro_names:''}, vGroro_DA:{count:4,ro_names:''}, vEmployee_DA:{count:0,ro_names:''}, vAir_DA:{count:0,ro_names:''}, vWater_DA:{count:0,ro_names:''}, vLand_DA:{count:0,ro_names:''}, vDoc_DA:{count:8,ro_names:''}, 
    vLicence_KB:{count:0,ro_names:''}, vTransport_KB:{count:0,ro_names:''}, vScheme_KB:{count:0,ro_names:''}, vInvent_KB:{count:0,ro_names:''}, vGroro_KB:{count:0,ro_names:''}, vEmployee_KB:{count:0,ro_names:''}, vAir_KB:{count:0,ro_names:''}, vWater_KB:{count:0,ro_names:''}, vLand_KB:{count:0,ro_names:''}, vDoc_KB:{count:0,ro_names:''}, 
    vLicence_AL:{count:1,ro_names:''}, vTransport_AL:{count:0,ro_names:''}, vScheme_AL:{count:0,ro_names:''}, vInvent_AL:{count:1,ro_names:''}, vGroro_AL:{count:0,ro_names:''}, vEmployee_AL:{count:0,ro_names:''}, vAir_AL:{count:0,ro_names:''}, vWater_AL:{count:0,ro_names:''}, vLand_AL:{count:0,ro_names:''}, vDoc_AL:{count:10,ro_names:''}, 
    vLicence_KL:{count:0,ro_names:''}, vTransport_KL:{count:0,ro_names:''}, vScheme_KL:{count:0,ro_names:''}, vInvent_KL:{count:0,ro_names:''}, vGroro_KL:{count:0,ro_names:''}, vEmployee_KL:{count:0,ro_names:''}, vAir_KL:{count:1,ro_names:''}, vWater_KL:{count:0,ro_names:''}, vLand_KL:{count:0,ro_names:''}, vDoc_KL:{count:5,ro_names:''}, 
    vLicence_KR:{count:0,ro_names:''}, vTransport_KR:{count:0,ro_names:''}, vScheme_KR:{count:0,ro_names:''}, vInvent_KR:{count:0,ro_names:''}, vGroro_KR:{count:0,ro_names:''}, vEmployee_KR:{count:0,ro_names:''}, vAir_KR:{count:3,ro_names:''}, vWater_KR:{count:0,ro_names:''}, vLand_KR:{count:0,ro_names:''}, vDoc_KR:{count:19,ro_names:''}, 
    vLicence_KO:{count:1,ro_names:''}, vTransport_KO:{count:0,ro_names:''}, vScheme_KO:{count:0,ro_names:''}, vInvent_KO:{count:0,ro_names:''}, vGroro_KO:{count:0,ro_names:''}, vEmployee_KO:{count:0,ro_names:''}, vAir_KO:{count:0,ro_names:''}, vWater_KO:{count:0,ro_names:''}, vLand_KO:{count:0,ro_names:''}, vDoc_KO:{count:4,ro_names:''}, 
    vLicence_ME:{count:0,ro_names:''}, vTransport_ME:{count:0,ro_names:''}, vScheme_ME:{count:0,ro_names:''}, vInvent_ME:{count:0,ro_names:''}, vGroro_ME:{count:0,ro_names:''}, vEmployee_ME:{count:0,ro_names:''}, vAir_ME:{count:0,ro_names:''}, vWater_ME:{count:0,ro_names:''}, vLand_ME:{count:0,ro_names:''}, vDoc_ME:{count:1,ro_names:''}, 
    vLicence_MO:{count:0,ro_names:''}, vTransport_MO:{count:0,ro_names:''}, vScheme_MO:{count:0,ro_names:''}, vInvent_MO:{count:0,ro_names:''}, vGroro_MO:{count:0,ro_names:''}, vEmployee_MO:{count:0,ro_names:''}, vAir_MO:{count:0,ro_names:''}, vWater_MO:{count:0,ro_names:''}, vLand_MO:{count:0,ro_names:''}, vDoc_MO:{count:5,ro_names:''}, 
    vLicence_SE:{count:0,ro_names:''}, vTransport_SE:{count:0,ro_names:''}, vScheme_SE:{count:0,ro_names:''}, vInvent_SE:{count:0,ro_names:''}, vGroro_SE:{count:0,ro_names:''}, vEmployee_SE:{count:0,ro_names:''}, vAir_SE:{count:0,ro_names:''}, vWater_SE:{count:0,ro_names:''}, vLand_SE:{count:0,ro_names:''}, vDoc_SE:{count:0,ro_names:''}, 
    vLicence_KC:{count:2,ro_names:''}, vTransport_KC:{count:0,ro_names:''}, vScheme_KC:{count:0,ro_names:''}, vInvent_KC:{count:0,ro_names:''}, vGroro_KC:{count:0,ro_names:''}, vEmployee_KC:{count:0,ro_names:''}, vAir_KC:{count:0,ro_names:''}, vWater_KC:{count:0,ro_names:''}, vLand_KC:{count:0,ro_names:''}, vDoc_KC:{count:2,ro_names:''}, 
    vLicence_TA:{count:0,ro_names:''}, vTransport_TA:{count:0,ro_names:''}, vScheme_TA:{count:0,ro_names:''}, vInvent_TA:{count:0,ro_names:''}, vGroro_TA:{count:0,ro_names:''}, vEmployee_TA:{count:0,ro_names:''}, vAir_TA:{count:0,ro_names:''}, vWater_TA:{count:0,ro_names:''}, vLand_TA:{count:0,ro_names:''}, vDoc_TA:{count:0,ro_names:''}, 
    vLicence_TY:{count:0,ro_names:''}, vTransport_TY:{count:1,ro_names:''}, vScheme_TY:{count:0,ro_names:''}, vInvent_TY:{count:0,ro_names:''}, vGroro_TY:{count:0,ro_names:''}, vEmployee_TY:{count:0,ro_names:''}, vAir_TY:{count:0,ro_names:''}, vWater_TY:{count:0,ro_names:''}, vLand_TY:{count:0,ro_names:''}, vDoc_TY:{count:6,ro_names:''}, 
    vLicence_UD:{count:0,ro_names:''}, vTransport_UD:{count:0,ro_names:''}, vScheme_UD:{count:0,ro_names:''}, vInvent_UD:{count:0,ro_names:''}, vGroro_UD:{count:0,ro_names:''}, vEmployee_UD:{count:0,ro_names:''}, vAir_UD:{count:0,ro_names:''}, vWater_UD:{count:0,ro_names:''}, vLand_UD:{count:0,ro_names:''}, vDoc_UD:{count:0,ro_names:''}, 
    vLicence_KK:{count:0,ro_names:''}, vTransport_KK:{count:0,ro_names:''}, vScheme_KK:{count:0,ro_names:''}, vInvent_KK:{count:0,ro_names:''}, vGroro_KK:{count:0,ro_names:''}, vEmployee_KK:{count:0,ro_names:''}, vAir_KK:{count:0,ro_names:''}, vWater_KK:{count:0,ro_names:''}, vLand_KK:{count:0,ro_names:''}, vDoc_KK:{count:0,ro_names:''}, 
    vLicence_CE:{count:0,ro_names:''}, vTransport_CE:{count:0,ro_names:''}, vScheme_CE:{count:0,ro_names:''}, vInvent_CE:{count:0,ro_names:''}, vGroro_CE:{count:0,ro_names:''}, vEmployee_CE:{count:0,ro_names:''}, vAir_CE:{count:0,ro_names:''}, vWater_CE:{count:0,ro_names:''}, vLand_CE:{count:0,ro_names:''}, vDoc_CE:{count:0,ro_names:''}, 
    vLicence_CU:{count:0,ro_names:''}, vTransport_CU:{count:0,ro_names:''}, vScheme_CU:{count:0,ro_names:''}, vInvent_CU:{count:0,ro_names:''}, vGroro_CU:{count:0,ro_names:''}, vEmployee_CU:{count:0,ro_names:''}, vAir_CU:{count:0,ro_names:''}, vWater_CU:{count:0,ro_names:''}, vLand_CU:{count:0,ro_names:''}, vDoc_CU:{count:0,ro_names:''}, 
    vLicence_SA:{count:0,ro_names:''}, vTransport_SA:{count:0,ro_names:''}, vScheme_SA:{count:0,ro_names:''}, vInvent_SA:{count:0,ro_names:''}, vGroro_SA:{count:0,ro_names:''}, vEmployee_SA:{count:0,ro_names:''}, vAir_SA:{count:0,ro_names:''}, vWater_SA:{count:0,ro_names:''}, vLand_SA:{count:0,ro_names:''}, vDoc_SA:{count:2,ro_names:''}, 
    vLicence_YEV:{count:0,ro_names:''}, vTransport_YEV:{count:0,ro_names:''}, vScheme_YEV:{count:0,ro_names:''}, vInvent_YEV:{count:0,ro_names:''}, vGroro_YEV:{count:0,ro_names:''}, vEmployee_YEV:{count:0,ro_names:''}, vAir_YEV:{count:0,ro_names:''}, vWater_YEV:{count:0,ro_names:''}, vLand_YEV:{count:0,ro_names:''}, vDoc_YEV:{count:0,ro_names:''}, 
    vLicence_NEN:{count:0,ro_names:''}, vTransport_NEN:{count:0,ro_names:''}, vScheme_NEN:{count:0,ro_names:''}, vInvent_NEN:{count:0,ro_names:''}, vGroro_NEN:{count:0,ro_names:''}, vEmployee_NEN:{count:0,ro_names:''}, vAir_NEN:{count:0,ro_names:''}, vWater_NEN:{count:0,ro_names:''}, vLand_NEN:{count:0,ro_names:''}, vDoc_NEN:{count:0,ro_names:''}, 
    vLicence_KHM:{count:0,ro_names:''}, vTransport_KHM:{count:0,ro_names:''}, vScheme_KHM:{count:0,ro_names:''}, vInvent_KHM:{count:0,ro_names:''}, vGroro_KHM:{count:0,ro_names:''}, vEmployee_KHM:{count:0,ro_names:''}, vAir_KHM:{count:0,ro_names:''}, vWater_KHM:{count:0,ro_names:''}, vLand_KHM:{count:0,ro_names:''}, vDoc_KHM:{count:0,ro_names:''}, 
    vLicence_YAN:{count:0,ro_names:''}, vTransport_YAN:{count:0,ro_names:''}, vScheme_YAN:{count:0,ro_names:''}, vInvent_YAN:{count:0,ro_names:''}, vGroro_YAN:{count:0,ro_names:''}, vEmployee_YAN:{count:0,ro_names:''}, vAir_YAN:{count:0,ro_names:''}, vWater_YAN:{count:0,ro_names:''}, vLand_YAN:{count:0,ro_names:''}, vDoc_YAN:{count:0,ro_names:''}
}
