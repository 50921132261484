export const DCT_CITIES = {
    city_nor: {
        slug: 'city_nor',
        name: 'Норильск',
        x: '495',
        y: '211',
        emission_volume_2017: '1729,3',
        emission_volume_2024: '432,32',
        emission_reduction: '1296,98',
        emission_reduction_percent: '75',
        pollution_level_2015: 'Очень высокий',
        pollution_level_2016: 'Очень высокий',
        pollution_level_2017: 'Очень высокий',
        substance_2015: 'диоксид серы - 1,9 млн тонн',
        substance_2016: 'диоксид серы - 1,8 млн тонн',
        substance_2017: 'диоксид серы - 1,68 млн тонн',
        emission_prom_total: '99',
        emission_trans_total: '1',
        emission_comm_total: '0',
        emission_energo_total: '0',
        plans_total: '4',
        money_fed_total: '0,21',
        money_cons_total: '0,019',
        money_other_total: '0,019',
     },
    city_nov: {
        slug: 'city_nov',
        name: 'Новокузнецк',
        x: '442',
        y: '448',
        emission_volume_2017: '340,9',
        emission_volume_2024: '260,57',
        emission_reduction: '80,33',
        emission_reduction_percent: '20,25',
        pollution_level_2015: 'Очень высокий',
        pollution_level_2016: 'Очень высокий',
        pollution_level_2017: 'Очень высокий',
        substance_2015: 'бензпирен - 15,5 ПДК',
        substance_2016: 'бензпирен - 28,8 ПДК',
        substance_2017: 'бензпирен - 28,4 ПДК',
        emission_prom_total: '92',
        emission_trans_total: '8',
        emission_comm_total: '0',
        emission_energo_total: '0',
        plans_total: '21',
        money_fed_total: '5,884',
        money_cons_total: '0,35',
        money_other_total: '0,35',
     },
    city_chel: {
        slug: 'city_chel',
        name: 'Челябинск',
        x: '243',
        y: '388',
        emission_volume_2017: '323,668',
        emission_volume_2024: '247,778',
        emission_reduction: '75,89',
        emission_reduction_percent: '23,44',
        pollution_level_2015: 'Высокий',
        pollution_level_2016: 'Высокий',
        pollution_level_2017: 'Высокий',
        substance_2015: 'бензпирен - 13,1 ПДК',
        substance_2016: 'оксид углерода - 10,4 ПДК ',
        substance_2017: '- - -',
        emission_prom_total: '46',
        emission_trans_total: '35',
        emission_comm_total: '19',
        emission_energo_total: '0',
        plans_total: '40',
        money_fed_total: '12,031',
        money_cons_total: '1,494',
        money_other_total: '1,494',
     },
    city_mag: {
        slug: 'city_mag',
        name: 'Магнитогорск',
        x: '264',
        y: '361',
        emission_volume_2017: '288',
        emission_volume_2024: '206,4',
        emission_reduction: '81,6',
        emission_reduction_percent: '28,3',
        pollution_level_2015: 'Высокий',
        pollution_level_2016: 'Очень высокий',
        pollution_level_2017: 'Очень высокий',
        substance_2015: 'бензпирен - 22,8 ПДК',
        substance_2016: 'бензпирен - 38,5 ПДК',
        substance_2017: 'бензпирен - 27,7 ПДК',
        emission_prom_total: '73',
        emission_trans_total: '7',
        emission_comm_total: '20',
        emission_energo_total: '0',
        plans_total: '16',
        money_fed_total: '1,37',
        money_cons_total: '0,421',
        money_other_total: '0,421',
     },
    city_kra: {
        slug: 'city_kra',
        name: 'Красноярск',
        x: '495',
        y: '370',
        emission_volume_2017: '190,7',
        emission_volume_2024: '148,12',
        emission_reduction: '42,58',
        emission_reduction_percent: '22,33',
        pollution_level_2015: 'Высокий',
        pollution_level_2016: 'Очень высокий',
        pollution_level_2017: 'Очень высокий',
        substance_2015: 'бензпирен - 18,5 ПДК',
        substance_2016: 'бензпирен - 40,6 ПДК',
        substance_2017: 'бензпирен - 20,1 ПДК',
        emission_prom_total: '51',
        emission_trans_total: '38',
        emission_comm_total: '11',
        emission_energo_total: '0',
        plans_total: '30',
        money_fed_total: '8,729',
        money_cons_total: '1,117',
        money_other_total: '1,117',
     },
    city_bra: {
        slug: 'city_bra',
        name: 'Братск',
        x: '560',
        y: '440',
        emission_volume_2017: '126,5',
        emission_volume_2024: '101,27',
        emission_reduction: '25,23',
        emission_reduction_percent: '19,95',
        pollution_level_2015: 'Очень высокий',
        pollution_level_2016: 'Очень высокий',
        pollution_level_2017: 'Очень высокий',
        substance_2015: 'бензпирен - 30,2 ПДК',
        substance_2016: 'бензпирен - 80,3 ПДК ',
        substance_2017: 'бензпирен - 50,2 ПДК ',
        emission_prom_total: '74',
        emission_trans_total: '12',
        emission_comm_total: '14',
        emission_energo_total: '0',
        plans_total: '26',
        money_fed_total: '5,91',
        money_cons_total: '1,641',
        money_other_total: '1,641',
     },
    city_lip: {
        slug: 'city_lip',
        name: 'Липецк',
        x: '108',
        y: '300',
        emission_volume_2017: '329,3',
        emission_volume_2024: '322,54',
        emission_reduction: '6,37',
        emission_reduction_percent: '1,93',
        pollution_level_2015: 'Низкий',
        pollution_level_2016: 'Низкий',
        pollution_level_2017: 'Повышенный',
        substance_2015: '- - -',
        substance_2016: '- - -',
        substance_2017: 'сероводород - 11,0 ПДК',
        emission_prom_total: '86',
        emission_trans_total: '13',
        emission_comm_total: '1',
        emission_energo_total: '0',
        plans_total: '10',
        money_fed_total: '2,67',
        money_cons_total: '0,53',
        money_other_total: '0,53',
     },
    city_cher: {
        slug: 'city_cher',
        name: 'Череповец',
        x: '170',
        y: '235',
        emission_volume_2017: '334,16',
        emission_volume_2024: '297,37',
        emission_reduction: '36,79',
        emission_reduction_percent: '11,01',
        pollution_level_2015: 'Повышенный',
        pollution_level_2016: 'Повышенный',
        pollution_level_2017: 'Повышенный',
        substance_2015: '- - -',
        substance_2016: '- - -',
        substance_2017: '- - -',
        emission_prom_total: '93',
        emission_trans_total: '5',
        emission_comm_total: '2',
        emission_energo_total: '0',
        plans_total: '40',
        money_fed_total: '0,19',
        money_cons_total: '0,15',
        money_other_total: '0,15',
     },
    city_nt: {
        slug: 'city_nt',
        name: 'Нижний Тагил',
        x: '280',
        y: '314',
        emission_volume_2017: '138,8',
        emission_volume_2024: '110,84',
        emission_reduction: '25,68',
        emission_reduction_percent: '18,5',
        pollution_level_2015: 'Высокий',
        pollution_level_2016: 'Высокий',
        pollution_level_2017: 'Высокий',
        substance_2015: 'бензпирен - 13,0 ПДК',
        substance_2016: '- - -',
        substance_2017: 'бензпирен - 13,6 ПДК',
        emission_prom_total: '100',
        emission_trans_total: '0',
        emission_comm_total: '0',
        emission_energo_total: '0',
        plans_total: '21',
        money_fed_total: '0,28',
        money_cons_total: '0',
        money_other_total: '0',
     },
    city_chit: {
        slug: 'city_chit',
        name: 'Чита',
        x: '664',
        y: '495',
        emission_volume_2017: '38,7',
        emission_volume_2024: '29,95',
        emission_reduction: '8,75',
        emission_reduction_percent: '22,61',
        pollution_level_2015: 'Очень высокий',
        pollution_level_2016: 'Очень высокий',
        pollution_level_2017: 'Очень высокий',
        substance_2015: 'бензпирен - 61,1 ПДК',
        substance_2016: 'бензпирен - 42,6 ПДК',
        substance_2017: 'бензпирен - 49,7 ПДК',
        emission_prom_total: '89',
        emission_trans_total: '9',
        emission_comm_total: '2',
        emission_energo_total: '0',
        plans_total: '20',
        money_fed_total: '6,987',
        money_cons_total: '0,165',
        money_other_total: '0,165',
     },
    city_oms: {
        slug: 'city_oms',
        name: 'Омск',
        x: '350',
        y: '400',
        emission_volume_2017: '250,8',
        emission_volume_2024: '194,6',
        emission_reduction: '56,2',
        emission_reduction_percent: '22,41',
        pollution_level_2015: 'Низкий',
        pollution_level_2016: 'Повышенный',
        pollution_level_2017: 'Низкий',
        substance_2015: '- - -',
        substance_2016: 'формальдегид - 11,6 ПДК',
        substance_2017: '- - -',
        emission_prom_total: '29',
        emission_trans_total: '35',
        emission_comm_total: '0',
        emission_energo_total: '37',
        plans_total: '51',
        money_fed_total: '6,795',
        money_cons_total: '0,0409',
        money_other_total: '0,0409',
     },
    city_med: {
        slug: 'city_med',
        name: 'Медногорск',
        x: '200',
        y: '390',
        emission_volume_2017: '9,55',
        emission_volume_2024: '8,86',
        emission_reduction: '0,032',
        emission_reduction_percent: '0,44',
        pollution_level_2015: 'Повышенный',
        pollution_level_2016: 'Повышенный',
        pollution_level_2017: 'Повышенный',
        substance_2015: '- - -',
        substance_2016: '- - -',
        substance_2017: '- - -',
        emission_prom_total: '73',
        emission_trans_total: '27',
        emission_comm_total: '0',
        emission_energo_total: '0',
        plans_total: '8',
        money_fed_total: '0,14',
        money_cons_total: '0,03',
        money_other_total: '0,03',
     },
};
