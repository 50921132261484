import { Component, NgZone } from '@angular/core';
import { StoreService } from 'src/app/store/store.service';
import { StateService } from 'src/app/store/state.service';
import { take, tap } from 'rxjs/operators';

@Component({
    selector: 'apm-readiness-page',
    templateUrl: './readiness-page.component.html'
})
export class ReadinessPage {
    constructor(
        private store: StoreService,
        public state: StateService,
        public zone: NgZone,
    ) {}

    ngAfterViewInit(): void {
        // this.zone.onStable.pipe(
        //     take(1), tap(()=> this.state.resetStateTo('readiness') )
        // ).subscribe();
        // this.zone.onStable.pipe(
        //     take(1),
        //     tap(()=>{
        //         this.state.hideAll();
        //         this.state.mainEntityKey = 'region';
        //         this.state.mainSectionKey = 'readiness';
        //         this.state.slices = [
        //             {
        //                 slug:'test',
        //                 name:'Тест 2',
        //                 cell:{
        //                     type:'readiness-stats',
        //                     data:{
        //                         value: 1234324
        //                     }
        //                 }
        //             },
        //             {
        //                 name:'Тест 3',
        //                 slug:'test2',
        //                 cell:{
        //                     type:'readiness-stats',
        //                     data:{
        //                         value: 3212341
        //                     }
        //                 }
        //             }
        //         ];
        //     })
        // ).subscribe();
    }
}
