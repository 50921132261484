export const FIELDS_ZONEsINFO = [
    { slug:'quantity', name:'Объем образуемых отходов', dimension:'' },
    { slug:'population', name:'Численность населения', dimension:'' },
    { slug:'tarif_min', name:'Тариф - минимум', dimension:'₽/куб.м' },
    { slug:'tarif_max', name:'Тариф - максимум', dimension:'₽/куб.м' },
    { slug:'norm_min', name:'Норматив для граждан - минимум', dimension:'куб.м/чел' },
    { slug:'norm_max', name:'Норматив для граждан - максимум', dimension:'куб.м/чел' },
    { slug:'per_person_min', name:'Плата гражда - минимум,', dimension:'₽/чел.мес' },
    { slug:'per_person_max', name:'Плата гражда - максимум', dimension:'₽/чел.мес' },
    { slug:'density_min', name:'Плотность отходов - минимум', dimension:'кг/куб.м' },
    { slug:'density_max', name:'Плотность отходов - максимум', dimension:'кг/куб.м' },
];
