import { Component, NgZone } from '@angular/core';
import { StoreService } from 'src/app/store/store.service';
import { StateService } from 'src/app/store/state.service';
import { take, tap } from 'rxjs/operators';

@Component({
    selector: 'apm-treatment-page',
    templateUrl: './treatment-page.component.html'
})
export class TreatmentPage {
    constructor(
        private store: StoreService,
        public state: StateService,
        public zone: NgZone,
    ) {}

    ngAfterViewInit(): void {
        // this.zone.onStable.pipe(
        //     take(1), tap(()=> this.state.resetStateTo('treatment') )
        // ).subscribe();
    }
}
