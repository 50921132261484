import { Component, NgZone, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { StoreService } from 'src/app/store/store.service';
import { StateService } from 'src/app/store/state.service';
import { Group, Layer, Path, CompoundPath, PathItem, Item } from 'paper';
import { PanzoomDirective } from 'src/app/ads/panzoom.directive';
import { PaperComponent } from '../../paper/paper.component';
import { RUSSIAPATHS } from '../../map/russia';
import { PATHs_RUSSIA } from 'src/app/store/paths_russia';
import { takeUntil, auditTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'apm-readiness-map',
    template: `
    <apm-paper panzoom class="map__wrap"
        [zoomDisabled]="true"
        (panStart)="onPanStart()"
    ></apm-paper>`,
    host: {class: 'b3-map'},
})
export class ReadinessMapComponent implements OnDestroy, AfterViewInit {
    @ViewChild(PanzoomDirective) panzoomElement: PanzoomDirective;
    @ViewChild(PaperComponent) paper: PaperComponent;

    private isClickKeeped = false;
    private mainGroup;
    private mainLayer;

    private _destroyed$ = new Subject();

    constructor(
        private zone: NgZone,
        private store: StoreService,
        public state: StateService,
    ) {}

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    onPanStart() {
        this.isClickKeeped = false;
        if ( this.state.currentRegion ) { this.state.hideRegion(); }
    }

    ngAfterViewInit() {
        this.zone.runOutsideAngular(() => {
            this.initMap();
        });
    }

    private initMap() {
        // основная группа, здесь лежат все элементы
        this.mainGroup = new Group;
        // объединяющая группа, содержит в себе другие группы
        this.panzoomElement.rootPaperGroup = this.mainGroup;

        this.mainLayer = new Layer();
        this.mainLayer.onFrame = this.panzoomElement.onFrameBinded;

        Object.keys(PATHs_RUSSIA).forEach(
            key => {
                const item = this.drawPath(RUSSIAPATHS[key], key);
                item.fillColor = this.state.getColorForRegion(key) || '#fff';
                item.onMouseDown = event => {
                    this.isClickKeeped = true;
                };
                item.onMouseUp = event => {
                    if ( !this.isClickKeeped ) { return; }
                    this.isClickKeeped = true;
                    console.log('[DEV] event', event.target['slug'], event, event.point);
                    // TODO исправить обработку масштаба и перенести отсюда
                    if (item && item.name) {
                        if ( this.state.currentRegion && this.state.currentRegion.slug === item.name ) {
                            this.state.hideRegion();
                        } else {
                            this.state.showRegion(this.store.regionsDct[item.name], event.point);
                        }
                        return;
                    }
                };
                item['slug'] = key;
                this.mainGroup.addChild(item);
            }
        );

        this.mainGroup.style = {
            strokeColor: '#333',
            strokeJoin: 'round',
            strokeWidth: 1 // дробное значение лучше не ставить, падает фпс
        };

        this.state.state$
            .pipe(
                auditTime(200),
                takeUntil(this._destroyed$)
            )
            .subscribe(() => {
                Object.keys(RUSSIAPATHS).forEach( regionCode => {
                    const item = this.mainGroup.children[regionCode];
                    if (!item) { return; }
                    item.fillColor = this.state.getColorForRegion(regionCode) || '#fff';
                });
            });
    }


    private drawPath( pathStr: string, name: string ): Path | CompoundPath {
        const path = PathItem.create(pathStr);
        path.name = name;
        path.closed = true;
        return path;
    }
}
