import { Component, ViewChild, ElementRef, NgZone, OnInit, OnDestroy } from '@angular/core';
import { Project, Point, Layer, Path, Group, Color, PathItem, CompoundPath, Item, View, PointText, TextItem } from 'paper';
import { Subject } from 'rxjs';
import { auditTime, takeUntil } from 'rxjs/operators';
import { RUSSIAPATHS, RUSSIAPATHS_NEW } from './russia';
import { StoreService } from '../../store/store.service';
import { StateService } from 'src/app/store/state.service';
import { AMEvents } from '../../am/events';
import { PanzoomDirective } from '../../ads/panzoom.directive';
import { AMDevice } from 'src/app/am/device';
import { PaperComponent, PaperEvent, PaperTapEvent } from '../paper/paper.component';

declare const Hammer: any;

@Component({
    selector: 'apm-map',
    template:`
        <apm-paper panzoom class="apm-map__canvas"
            (panStart)="onPanStart()"
            (pinchStart)="onPinchStart()"
        ></apm-paper>
    `,
    // encapsulation: ViewEncapsulation.None,
    host: {class: 'apm-map'}
})
export class MapComponent implements OnInit, OnDestroy {
    @ViewChild(PanzoomDirective) panzoomElement: PanzoomDirective;
    @ViewChild(PaperComponent) paper: PaperComponent;

    private mainLayer: Layer;
    private rootPaperGroup: Group;
    private mainGroup: Group;
    private overlapGroup: Group;
    private textGroup: Group;
    private centerScale: Point;

    private _destroyed$ = new Subject();

    constructor(
        private zone: NgZone,
        private store: StoreService,
        private state: StateService,
    ) {}

    ngOnInit(){}

    ngAfterViewInit() {
        console.log('[DEV] InitMapComponent', this.paper);
        this.zone.runOutsideAngular(() => {
            this.initMap();
        });
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    private isClickKeeped = false;

    private initMap() {
        // основная группа, здесь лежат все элементы
        console.log('[DEV] initMap');
        this.mainGroup = new Group;
        // перекрывающая группа, здесь лежит элемент под наведением
        this.overlapGroup = new Group;
        // объединяющая группа, содержит в себе другие группы
        this.rootPaperGroup = new Group;
        this.rootPaperGroup.addChild(this.mainGroup);
        this.rootPaperGroup.addChild(this.overlapGroup);
        this.panzoomElement.rootPaperGroup = this.rootPaperGroup;

        this.mainLayer = new Layer();
        this.mainLayer.onFrame = this.panzoomElement.onFrameBinded;

        Object.keys(RUSSIAPATHS_NEW).forEach(
            key => {
                const itemPath = this.drawPath(RUSSIAPATHS_NEW[key].path, key);

                const itemText = new PointText({
                    point: RUSSIAPATHS_NEW[key].textPoint,
                    content: RUSSIAPATHS_NEW[key].ruscode,
                    fillColor: '#2D4059',
                    // fontFamily: 'Roboto Condensed',
                    fontWeight: 'bold',
                    fontSize: 8,
                    justification: 'center',
                });

                const item = new Group([itemPath, itemText]);
                // item.fillColor = 'white'//this.state.getColorForRegion(key) || '#fff';
                item.name = itemPath.name;
                item.onMouseDown = event =>{
                    this.isClickKeeped = true;
                }
                item.onMouseUp = event =>{
                    if( !this.isClickKeeped ) return;
                    this.isClickKeeped = true;
                    console.log('[DEV] event', event.target['slug'], event, event.point);
                    // TODO исправить обработку масштаба и перенести отсюда
                    if (item && item.name) {
                        if ( this.state.currentRegion && this.state.currentRegion.slug === item.name ) {
                            this.state.hideRegion();
                            if( AMDevice.isTouchDevice ){
                                this.deactivateAllItemsOnMap();
                            }
                        } else {
                            const secondHalf = event.point.x > this.mainLayer.bounds.width / 2;
                            const direction = secondHalf ? 'right' : 'left';
                            this.state.showRegion(this.store.regionsDct[item.name], event.point, direction);
                            if( AMDevice.isTouchDevice ){
                                this.activateItemOnMap(item);
                            }
                        }
                        return;
                    }
                };
                item['slug'] = key;

                this.mainGroup.addChild(item);
                // console.log('[DEV][ELLI][item]', item);
            }
        );

        // this.mainGroup.style = {
        //     // fillColor: 'white',
        //     strokeColor: '#e6e6e6',
        //     strokeJoin: 'round',
        //     strokeWidth: 1 // дробное значение лучше не ставить, падает фпс
        // };

        // Здесь зуммируется карта/сота для занимания максимально доступной площади. Цифры подобраны.
        this.centerScale = new Point(-30, -65);
        this.mainGroup.scale(2.3, this.centerScale);
        // console.log('[DEV][ELLI][mainGroup]', this.mainGroup);

        this.state.state$
            .pipe(
                auditTime(200),
                takeUntil(this._destroyed$)
            )
            .subscribe(() => {
                Object.keys(RUSSIAPATHS_NEW).forEach( regionCode => {
                    const item = this.findItemInGroupsByName(regionCode);
                    if (!item) { return; }
                    item.firstChild.fillColor = this.state.getColorForRegion(regionCode) || '#fff';
                });
            });
    }

    // покадровая прорисовка карты

    private drawPath( pathStr: string, name: string ): Path | CompoundPath {
        const path = PathItem.create(pathStr);
        path.name = name;
        path.closed = true;

        path.style = {
            // fillColor: 'white',
            strokeColor: '#e6e6e6',
            strokeJoin: 'round',
            strokeWidth: 1 // дробное значение лучше не ставить, падает фпс
        };
        // чтобы выделить элемент переносим его в отдельную группу
        // а на группу с остальными элементами применяем opacity
        if (!AMDevice.isTouchDevice) {
            path.onMouseEnter = ev => this.activateItemOnMap(ev.target);
            path.onMouseLeave = ev => this.deactivateAllItemsOnMap();
        }
        return path;
    }

    public closeAll(){
        if ( this.state.currentRegion ) {
            this.state.hideRegion();
            if( AMDevice.isTouchDevice ){
                this.deactivateAllItemsOnMap();
            }
        }
    }

    // клик на карте
    private onPaperTap( event:PaperTapEvent ){
        // console.log('[DEV] onPaperTap', event);
        // const point = event.tapPoint || AMEvents.getOffsetPointByEventTarget(event.nativeEvent);
        // // @TODO пересчитывать позицию клика с учетом zoom
        // const tappedItem = this.findItemInGroupsByPoint(point);
        // if (tappedItem && tappedItem.name) {
        //     if ( this.state.currentRegion && this.state.currentRegion.slug === tappedItem.name ) {
        //         this.state.hideRegion();
        //         if( AMDevice.isTouchDevice ){
        //             this.deactivateAllItemsOnMap();
        //         }
        //     } else {
        //         const secondHalf = point.x > this.mainLayer.bounds.width / 2;
        //         const direction = secondHalf ? 'right' : 'left';
        //         this.state.showRegion(this.store.regionsDct[tappedItem.name], point, direction);
        //         if( AMDevice.isTouchDevice ){
        //             this.activateItemOnMap(tappedItem);
        //         }
        //     }
        //     return;
        // }
        // if ( this.state.currentRegion ) {
        //     this.state.hideRegion();
        //     if( AMDevice.isTouchDevice ){
        //         this.deactivateAllItemsOnMap();
        //     }
        // }
    }

    private onPanStart(){
        this.isClickKeeped = false;
        if( this.state.currentRegion ) this.state.hideRegion();
        if( AMDevice.isTouchDevice ) this.deactivateAllItemsOnMap();
    }

    private onPinchStart(){
        this.isClickKeeped = false;
        if( this.state.currentRegion ){
            this.state.hideRegion();
        }
        if (AMDevice.isTouchDevice) {
            this.deactivateAllItemsOnMap();
        }
    }

    private activateItemOnMap (item: Item): void {
        this.overlapGroup.children.forEach((overlapItem) => {
            this.mainGroup.addChild(overlapItem);
        });
        this.overlapGroup.addChild(item);
        this.mainGroup.opacity = 0.6;
    }

    private deactivateAllItemsOnMap (): void {
        this.mainGroup.opacity = 1;
        this.overlapGroup.children.forEach((item) => {
            this.mainGroup.addChild(item);
        });
    }

    private findItemInGroupsByPoint( point: Point ){
        const overlapItem = this.overlapGroup.children.find((item) => {
            return item.contains(point);
        });
        if (overlapItem) {
            return overlapItem;
        }
        const mainItem = this.mainGroup.children.find((item) => {
            return item.contains(point);
        });
        if (mainItem) {
            return mainItem;
        }
    }

    private findItemInGroupsByName(name: string): Item {
        let item;
        item = this.mainGroup.children[name];
        if (!item) {
            item = this.overlapGroup.children[name];
        }
        return item;
    }
}
