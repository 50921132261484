export const DATA_STATUSTKO_SUMMARY = {
    region_reformatko_status_ready_count:54,
    region_reformatko_status_warning_count:3,
    region_reformatko_status_danger_count:1,
    region_reformatko_status_extreme_count:2,
    region_reformatko_status_none_count:25,
    ts_readiness_status_ready_count:35,
    ts_readiness_status_warning_count:8,
    ts_readiness_status_danger_count:17,
    object_treatment_status_ready_count:6,
    object_treatment_status_warning_count:21,
    object_treatment_status_danger_count:33,
    object_placement_status_ready_count:20,
    object_placement_status_warning_count:28,
    object_placement_status_danger_count:12,
    vLicence_ready_count:51,
    vLicence_danger_count:9,
    vScheme_ready_count:60,
    vScheme_danger_count:0,
    vGroro_ready_count:55,
    vGroro_danger_count:5,
    vAir_ready_count:46,
    vAir_danger_count:14,
    vLand_ready_count:53,
    vLand_danger_count:7,
    vTransport_ready_count:57,
    vTransport_danger_count:3,
    vInvent_ready_count:57,
    vInvent_danger_count:3,
    vEmployee_ready_count:54,
    vEmployee_danger_count:6,
    vWaterready_count:57,
    vWaterdanger_count:3,
    vDoc_ready_count:33,
    vDoc_danger_count:27,
    norm_izhs_max_region:'Камчатский край',
    norm_mkd_max_region:'Камчатский край',
    norm_izhs_max_value:567.4,
    norm_mkd_max_value:567.4,
    norm_izhs_min_region:'Еврейская автономная область',
    norm_mkd_min_region:'Республика Коми',
    norm_izhs_min_value:15.8,
    norm_mkd_min_value:91.3,
    top_org_statustko:[
        {name:'МП ЖКХ Билибинского муниципального района',region:'Чукотский автономный округ',count:5},
        {name:'МП "Чаунское районное коммунальное хозяйство"',region:'Чукотский автономный округ',count:4},
        {name:'МУП ЖКХ "Иультинское"',region:'Чукотский автономный округ',count:4},
        {name:'ООО "ЭкоЦентр"',region:'Ростовская область',count:3},
        {name:'МП "Провиденское ЖКХ"',region:'Чукотский автономный округ',count:3}],
    violations_statustko:[
        {region:'Хабаровский край',note:'расторгнуто соглашение'},
        {region:'Еврейская автономная область',note:'расторгнуто соглашение'},
        {region:'Владимирская область',note:'РО не приступил к работе'},
        {region:'Приморский край',note:'РО не приступил к работе'},
        {region:'Алтайский край',note:'не предоставлены данные'},
        {region:'Красноярский край',note:'не предоставлены данные'},
        {region:'Архангельская область',note:'не предоставлены данные'},
        {region:'Белгородская область',note:'не предоставлены данные'},
        {region:'Брянская область',note:'не предоставлены данные'},
        {region:'Вологодская область',note:'не предоставлены данные'},
        {region:'Воронежская область',note:'не предоставлены данные'},
        {region:'Кировская область',note:'не предоставлены данные'},
        {region:'Курская область',note: '',},
        {region:'Ленинградская область',note:'не предоставлены данные'},
        {region:'Санкт-Петербург',note:'не предоставлены данные'},
        {region:'Липецкая область',note:'не предоставлены данные'},
        {region:'Новгородская область',note:'не предоставлены данные'},
        {region:'Новосибирская область',note:'не предоставлены данные'},
        {region:'Омская область',note:'не предоставлены данные'},
        {region:'Орловская область',note:'не предоставлены данные'},
        {region:'Рязанская область',note:'не предоставлены данные'},
        {region:'Саратовская область',note:'не предоставлены данные'},
        {region:'Свердловская область',note:'не предоставлены данные'},
        {region:'Тамбовская область',note:'не предоставлены данные'},
        {region:'Тульская область',note:'не предоставлены данные'},
        {region:'Ульяновская область',note:'не предоставлены данные'},
        {region:'Челябинская область',note:'не предоставлены данные'},
        {region:'Республика Татарстан',note:'не предоставлены данные'},
        {region:'Республика Хакасия',note:'не предоставлены данные'}
    ]
}
