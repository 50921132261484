export const FIELDS_ZONEsTABLE = [
    { slug:'invest', name:'Требуемые инвестиции'  },
    { slug:'rate_disposal', name:'Доля обезвреженных ТКО (%) план'  },
    { slug:'rate_treatment', name:'Доля обработанных ТКО (%) план'  },
    { slug:'rate_used', name:'Доля утилизированных ТКО (%) план'  },
    { slug:'quan_treatment', name:'Ввод в экслуатацию объектов обработки'  },
    { slug:'quan_placement', name:'Ввод в экслуатацию объектов размещения'  },
    { slug:'quan_disposal', name:'Ввод в экслуатацию объектов обезвреживания'  },
    { slug:'per_person_plan_min', name:'Плата граждан плановая, руб/чел в месяц (минимум)'  },
    { slug:'per_person_plan_max', name:'Плата граждан плановая, руб/чел в месяц (максимум)'  },
];
