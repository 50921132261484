import {
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnInit,
    AfterViewInit,
    NgZone,
} from '@angular/core';
import * as Highcharts from 'highcharts';
import { StateService } from 'src/app/store/state.service';
import { StoreService } from 'src/app/store/store.service';
import { Subscription } from 'rxjs';
import 'hammerjs';
import { tap } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';

const tapeDefaults = [
    'Региональные операторы',
    'Топ городов загрязнителей',
    'Топ категорий выбросов в атм. воздух',
    'Минимальная плата граждан',
    'Максимальная плата граждан',
    'Готовность региональных операторов',
    'Готовность функций региональных операторов',
];
const paneDefaults = [
    'Готовность региональных операторов',
    'Готовность региональных <br>операторов',
    'Топ категорий выбросов в атм. воздух',
    'Количество субъектов РФ по типам нарушений',
    'Топ городов загрязнителей',
    'Минимальная плата граждан ',
    'Максимальная плата граждан',
    'Региональные операторы',
    'Готовность субъектов'
];

const CARDS_ORDERED ={
    TAPE:{
        statustko:[
            'Готовность субъектов РФ',
            'Основные субъекты-нарушители',
        ],
        twotp:[
            'Готовность субъектов РФ',
            'Основные субъекты-нарушители',
        ],
        readiness:[
            'Готовность функций региональных операторов',
            'Готовность региональных операторов',
            'Минимальная плата граждан',
            'Максимальная плата граждан',
        ],
        treatment:[
            'Максимум',
            'Минимум',
            'Региональные операторы',
        ],
        freshair:[
            'Топ категорий выбросов в атм. воздух',
            'Топ городов загрязнителей',
            'Максимум',
            'Минимум',
        ],
    },
    PANE:{
        statustko:[
            'Статус Реформы ТКО',
            'Готовность субъектов РФ',
            'TOP-5 организаций',
            'Основные субъекты-нарушители',
            'Количество субъектов РФ по типам нарушений',
            'Максимальный норматив',
            'Минимальный норматив',
        ],
        twotp:[
            'Отчетность 2-ТП',
            'Общее количество отчетов',
            'Отчеты 2-ТП по статусу',
            'Отчеты 2-ТП по статусу / настоящее время',
            'Баланс отходов',
            'Кол-во отчетов 2-ТП Отходы',
            'Кол-во организаций, которые отправили отчет 2-ТП Отходы',
            'Кол-во отчетов 2-ТП Воздух',
            'Кол-во организаций, которые отправили отчет 2-ТП Воздух',
            'Объекты НВОС',
            'TOP-5 загрязняющих веществ по объему выбросов',
            'Общий объем выбросов ЗВ в атмосферу'
        ],
        readiness:[
            'Сводная информация',
            'Готовность региональных операторов',
            'Готовность региональных <br>операторов',
            'Готовность субъектов',
            'Региональные операторы',
            'Количество субъектов РФ по типам нарушений',
            'Минимальная плата граждан ',
            'Максимальная плата граждан',
        ],
        treatment:[
            'Максимум',
            'Минимум',
            'Сводная информация',
            'Региональные операторы',
            'Количество субъектов РФ по типам нарушений',
            'Максимальная плата граждан',
            'Минимальная плата граждан ',
        ],
        freshair:[
            'Сводная информация',
            'Максимум',
            'Минимум',
            'Топ категорий выбросов в атм. воздух',
            'Топ городов загрязнителей',
        ],
    }
}

@Component({
    selector: 'apm-dashboard-layout',
    templateUrl: './dashboard-layout.component.html',
    host: { class: 'b3-p-dashboard'},    
    styleUrls: ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent implements AfterViewInit {
    @HostBinding('class.b3-p-dashboard--opened')
    @Input() isOpened = true;

    public tapeTranslate = 0;

    public statuses = [
        'ready',
        'warning',
        'danger',
        'extreme',
        'none'
    ]

    get tapeOrdered () {
        switch (this.state.mainSectionKey) {
            case 'readiness': return [
                'Готовность региональных операторов',
                'Готовность функций региональных операторов',  
                'Минимальная плата граждан',
                'Максимальная плата граждан',
            ];
            case 'treatment': return [
                'Максимум',
                'Минимум',
                'Региональные операторы',
            ];
            case 'freshair': return [
                'Топ категорий выбросов в атм. воздух',
                'Топ городов загрязнителей',
                'Максимум',
                'Минимум',
            ];
        }
        return [];
    }

    get paneOrdered () {
        switch (this.state.mainSectionKey) {
            case 'readiness': return [
                'Готовность региональных операторов',
                'Готовность региональных <br>операторов',
                'Готовность субъектов',
                'Количество субъектов РФ по типам нарушений',
                'Региональные операторы',
                'Минимальная плата граждан ',
                'Максимальная плата граждан',
            ];
            case 'treatment': return [
                'Максимум',
                'Минимум',
                'Региональные операторы',
                'Количество субъектов РФ по типам нарушений',
                'Максимальная плата граждан',
                'Минимальная плата граждан ',
            ];
            case 'freshair': return [
                'Максимум',
                'Минимум',
                'Топ категорий выбросов в атм. воздух',
                'Топ городов загрязнителей',
                'Региональные операторы',
                'Готовность региональных <br>операторов',
                'Готовность региональных операторов',
            ];
        }
        return paneDefaults;
    }

    private _subscription:Subscription = new Subscription();

    constructor(
        private element: ElementRef,
        public state: StateService,
        public store:StoreService,
        private router: Router,
        private zone: NgZone,
    ){
    }

    ngAfterViewInit () {
        this.zone.runOutsideAngular(() => {
            this.initCharts();
        });
        // if( this._subscription ) this._subscription.unsubscribe();
        // this._subscription.add(
        //     this.router.events.subscribe((event: Event) => {
        //         if( event instanceof NavigationEnd )
        //             this.initCharts();
        //     })
        //     // this.state.state$.pipe(
        //     //     tap(()=> this.initCharts() )
        //     // ).subscribe()
        // );
    }

    ngOnDestroy(): void {
        if( this._subscription ) this._subscription.unsubscribe();
    }

    public getValue( key:string ): any {
        return this.store.getStoredValue(key);
    }

    public getSummaryNote(status:string):string {
        switch(status){
            case 'ready':  return 'ГОТОВЫ';
            case 'warning': return 'ЧАСТИЧНО ГОТОВЫ';
            case 'danger': return 'НЕ ГОТОВЫ';
            case 'extreme': return 'СОГЛАШЕНИЕ РАСТОРГНУТО';
            case 'none': return 'НЕ ПРЕДОСТАВЛЕНЫ ДАННЫЕ';
            default: return status;
        }
    }

    public get isMinMaxWorks(): boolean {
        return this.state.currentBundle
            && this.state.currentBundle.cell
            && !!~[
                'ktonn',
                'percent',
                'quantity',
                'via-dim',
            ].indexOf(this.state.currentBundle.cell.type);
    }

    public getOrderForTapeItem( itemName ): number {
        return CARDS_ORDERED.TAPE[this.state.mainSectionKey].indexOf(itemName) + 1;
    }

    public getOrderForPaneItem( itemName ): number {
        return CARDS_ORDERED.PANE[this.state.mainSectionKey].indexOf(itemName) + 1;
    }

    public tapeScrollLeft(tape): number {
        let tapeWidth = tape.offsetWidth;
        console.log('ELLI', tapeWidth, window.innerWidth);
        if( tapeWidth <= window.innerWidth ) return this.tapeTranslate;

        if( tapeWidth - Math.abs(this.tapeTranslate) <= window.innerWidth ) return this.tapeTranslate;
            
        this.tapeTranslate = this.tapeTranslate - 450;

        // if( this.tapeTranslate < window.innerWidth - tapeWidth ) this.tapeTranslate = window.innerWidth - tapeWidth;

        return this.tapeTranslate;
    }

    public tapeScrollRight(tape): number {
        this.tapeTranslate = this.tapeTranslate + 450;
        if( this.tapeTranslate > 0 ) this.tapeTranslate = 0;
        return this.tapeTranslate;
    }

    // public horizontalSwipe(tape): number {
    //     const width = 2227;
    //     // this.tapeTranslate = this.tapeTranslate + event.deltaX;

    //     // console.log('elli swipe', event, this.tapeTranslate, window.outerWidth );

    //     if ( event.type === 'swipeleft') {
    //         this.tapeTranslate = this.tapeTranslate - 450;
    //         // if ( this.tapeTranslate < width - window.outerWidth ) { this.tapeTranslate = - width + window.outerWidth - 200; }
    //     }
    //     if ( event.type === 'swiperight') {
    //         this.tapeTranslate = this.tapeTranslate + 450;
    //         // if ( this.tapeTranslate > width - window.outerWidth ) { this.tapeTranslate = 0; }
    //     }
    //     return this.tapeTranslate;
    // }

    private initCharts () {
        console.log('[DEV] INIT CHARTS');

        this.initOptionalChart('readychart', {
            chart: {
                backgroundColor: 'transparent',
                plotBackgroundColor: null,
                plotBorderWidth: 0,
                plotShadow: false
            },
            title: {
                text: '',
            },
            tooltip: {
                pointFormat: '{series.name} <b>{point.y}</b>'
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: false
                    },
                    startAngle: -90,
                    endAngle: 90,
                    center: ['50%', '100%'],
                    size: '220%'
                }
            },
            colors: ['#28BD8B', '#E7CF00', '#EA5A5A'],
            series: [{
                type: 'pie',
                name: '',
                innerSize: '50%',
                data: [
                    ['Готовы', 50],
                    ['Частично готовы', 24],
                    ['Не готовы', 11]
                ]
            }]
        });

        this.initOptionalChart('readychart1', {
            chart: {
                backgroundColor: 'transparent',
                plotBackgroundColor: null,
                plotBorderWidth: 0,
                plotShadow: false
            },
            title: {
                text: '',
            },
            tooltip: {
                pointFormat: '{series.name} <b>{point.y}</b>'
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: false
                    },
                    startAngle: -90,
                    endAngle: 90,
                    center: ['50%', '100%'],
                    size: '220%'
                }
            },
            colors: ['#28BD8B', '#E7CF00', '#EA5A5A'],
            series: [{
                type: 'pie',
                name: '',
                innerSize: '50%',
                data: [
                    ['Готовы', 50],
                    ['Частично готовы', 24],
                    ['Не готовы', 11]
                ]
            }]
        });

        /*Готовность субъектов На настоящее время*/

        this.initOptionalChart('redysubjects',  {
            chart: {
                type: 'pie',
                backgroundColor: 'transparent',
                options3d: {
                    enabled: true,
                    alpha: 45,
                    beta: 0
                }
            },
            title: {
                text: ''
            },
            tooltip: {
                pointFormat: '{series.name} <b>{point.y}</b>'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    depth: 35,
                    size: '110%',
                    dataLabels: {
                        enabled: false,
                        format: '{point.name}'
                    }
                }
            },
            colors: ['#28BD8B', '#E7CF00', '#EA5A5A'],
            series: [{
                type: 'pie',
                name: '',
                data: [
                    ['Готовы', 69],
                    ['Частично готовы', 4],
                    ['Не готовы', 12]
                ]
            }]
        });


        /*Топ категорий выбросов в атм. воздух На настоящее время, в рамках 12 городов “Чистый воздух”*/

        this.initOptionalChart('typecategory',  {
            chart: {
                //@ts-ignore
                type: 'bar',
                backgroundColor: 'transparent'
            },
            yAxis: {
                title: {
                    text: null,
                    style: {
                        color: 'white'
                    }
                },
                labels: {
                    style: {
                        color: 'white'
                    }
                }

            },
            xAxis: {
                labels: {
                    style: {
                        color: 'white'
                    }
                }
            },
            legend: {
                symbolWidth: 0
            },
            title: {
                text: ''
            },
            tooltip: {
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat: '{point.name}: <b>{point.y} тыс.тонн</b><br/>'
            },

            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        format: '{point.y} тыс.тонн'
                    }
                }
            },
            colors: ['#28BD8B', '#E7CF00', '#EA5A5A'],
            series: [{
                showInLegend: false,
                type: 'bar',
                name: '',
                colorByPoint: true,
                data: [
                    ['Промышленность', 3427],
                    ['Транспорт', 410],
                    ['Коммунальный сектор', 168]
                ]
            }]
        });


        /*Региональные операторы На настоящее время*/

        this.initOptionalChart( 'regionalchart', {
            chart: { type: 'bar', backgroundColor: 'transparent' },
            title: { text: null },
            subtitle: { text: null },
            xAxis: {
                categories: ['Всего', 'Выбрано', 'Прекращено'],
                title: { text: null },
                labels: {
                    style: {
                        color: 'white'
                    }
                },
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Количество, шт',
                    align: 'high',
                    style: {
                        color: 'white', 
                    }
                },
                labels: { enabled: false }
            },
            tooltip: { valueSuffix: ' шт' },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            legend: { enabled: false, },
            credits: { enabled: false },
            colors: ['#28BD8B', '#E7CF00', '#EA5A5A'],
            series: [{
                type: 'bar',
                showInLegend: false,
                name: '',
                colorByPoint: true,
                data: [
                    ['Региональных операторов всего', 224],
                    ['Региональных операторов выбрано', 187],
                    ['Соглашение с РО расторгнуто', 9]
                ]
            }]
        });
    }

    private initOptionalChart( containerKey, chartOptions:any ){
        let container_pane = this.element.nativeElement.querySelector(`.${containerKey}`);
        let container_tape = this.element.nativeElement.querySelector(`.${containerKey}_tape`);
        if( container_pane ) Highcharts.chart(container_pane, chartOptions);
        if( container_tape ) Highcharts.chart(container_tape, chartOptions);
    }
}
