import { Component } from "@angular/core";
import { CellBaseComponent, CellBaseComponentConfig } from '../cell-base.component';
import { StateService } from 'src/app/store/state.service';

@Component({
    ...CellBaseComponentConfig,
    selector: 'apm-cell_via-dim',
    templateUrl: 'via-dim-cell.component.html',
    host:{class:'b3-p-slice__stats-cell'}
})
export class ViaDimCellComponent extends CellBaseComponent{
    constructor( protected state:StateService ){ super(); }

    public getCurrentValue(){
        if( this.data && this.data.data && this.data.data.value ){
            let value = this.data.data.value;
            return (value instanceof Array) ? value[this.state.currentPeriod] : value;
        }
        return undefined;
    }
}
