import { Component, Input, HostBinding } from '@angular/core';
import { Region } from 'src/app/store/types';
import { StateService } from '../../store/state.service';

@Component({
    selector: 'apm-region-card',
    templateUrl: './region-card.component.html',
    host:{class:'tooltip__p'},
})
export class RegionCardComponent {
    constructor(
        public state:StateService,
    ){}
    ngOnInit(){}
}
