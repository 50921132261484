import { Injectable } from "@angular/core";
import { YEARs, MONTHs, SLICEsGROUPs, STOREDCT, STOREDCT_ZONEs, SLICEs_TREATMENT, SLICEs_READINESS, SLICEs_FRESHAIR, SLICEs_STATUSTKO } from './store';
import { Region, RZone, Field, Bundle, City } from './types';
import { REGIONs } from './regions';
import { ZONEs } from './zones';
import { AMStore } from './am';
import { FIELDS_ZONEsINFO } from './fields_zones_info';
import { FIELDS_ZONEsTABLE } from './fields_zones_table';
import { DCT_COUNTERS } from './dct_counters';
import { DATA_CITY } from '../pages/freshair/data_city';
import { DCT_CITIES } from './dct_cities';

//@ts-ignore
if( window ) window.STORE = STOREDCT;

const YEARS = [
    2015,
    2016,
    2017,
    2018,
    2019,
    2020,
    2021,
    2022,
    2023,
    2024,
    2025,
]

@Injectable()
export class StoreService{
    public dataBundles: any[];
    public dataBundlesDct: {[key:string]:any} = {};
    public dataBundlesStore:{[key:string]: Bundle[]} = {};
    public dataBundlesGroups: any[];
    public regionsDct:{[code:string]:Region}; //= REGIONsDATA;
    public citiesDct:{[code:string]:City};
    public periodDct = {};

    public years = YEARS;
    public getCityYearPollutionStatusInfo( city:City, year ){
        if( city )
            return city[`pollution_level_${year}`] + '<br/>' + city[`substance_${year}`];
        else
            return '';
    }
    public getCityYearPollutionStatus( city:City, sliceKey, year ){
        let value = city[`${sliceKey}_${year}`];
        switch( value ){
            case 'Очень высокий': return 'danger';
            case 'Высокий': return 'danger';
            case 'Повышенный': return 'warning';
            case 'Низкий': return 'ready';
            default: return '';
        }
    }

    public dct_period:string[];
    constructor(){
        this.definePeriods();
        // this.defineBundles();
        this.defineBundlesGrouped();
        this.defineRegions();
        this.defineCities();
        console.log('[DEV] REGIONS', this.regionsDct);
    }

    public getRegionForZone( zone:RZone ): Region {
        return this.regionsDct[zone.region];
    }

    // public getStoredObject( sliceCode:string, regionCode:string, propCode:number = 0 ){

    // }

    public getStoredValue_region( sliceCode:string, regionCode:string, period:number = 0 ){
        let dataKey = `${sliceCode}_${regionCode}`;
        // console.log('[DEV] getStoredValue', dataKey);
        // if( !STOREDCT.hasOwnProperty(dataKey) ) console.log('[DEV] (!)null', dataKey);
        // if( !STOREDCT.hasOwnProperty(dataKey) ) dataKey = `${sliceCode}_all`;
        return STOREDCT[dataKey] instanceof Array
            ? STOREDCT[dataKey][period]
            : STOREDCT[dataKey];
    }

    public getStoredValue(sliceCode: string, period:number = 0){
        let dataKey = `${sliceCode}`;
        return STOREDCT[dataKey] instanceof Array
            ? STOREDCT[dataKey][period]
            : STOREDCT[dataKey];
    }

    public getStoredValue_zone( zoneId:number, sliceCode:string, year:number ): any {
        let dataKey = `${sliceCode}_ZONE${zoneId}_${year}`;
        if( STOREDCT_ZONEs[dataKey] ){
            if( sliceCode.indexOf('rate_') === 0 )
                return STOREDCT_ZONEs[dataKey] < 0.01
                    ? STOREDCT_ZONEs[dataKey]*100
                    : Math.round(STOREDCT_ZONEs[dataKey]*100);
            else return STOREDCT_ZONEs[dataKey];
        }else return 0;
    }

    public get zoneTableFields(): Field[] { return FIELDS_ZONEsTABLE; }
    public get zoneInfoFields(): Field[] { return FIELDS_ZONEsINFO; }

    private definePeriods(){
        this.dct_period = [];
        YEARs.forEach(
            year => MONTHs.forEach(
                month =>{
                    let periodHtml = `
                        <div class="b3-txt b3-txt_bold">${year}</div>
                        <div class="b3-txt b3-txt_light">${month}</div>`;
                    this.dct_period.push(periodHtml);
                    this.periodDct[this.dct_period.length-1] = {
                        periodHtml,
                        name: `${month} ${year}`,
                    }
                }
            )
        )
    }

    public defineBundles( bundleKey:string ){
        let bundlesDct = {};
        if( this.dataBundlesStore.hasOwnProperty(bundleKey) )
            this.dataBundles = this.dataBundlesStore[bundleKey];
        else{
            switch( bundleKey ){
                case 'statustko': bundlesDct = SLICEs_STATUSTKO; break;
                case 'treatment': bundlesDct = SLICEs_TREATMENT; break;
                case 'readiness': bundlesDct = SLICEs_READINESS; break;
                case 'freshair': bundlesDct = SLICEs_FRESHAIR; break;
            }
            let bundles = [];
            for( let slug in bundlesDct){
                let bundle = { slug, ...bundlesDct[slug] };
                bundles.push(bundle);
                bundlesDct[slug] = bundle;
                this.dataBundlesDct[slug] = bundle;
            }
            this.dataBundlesStore[bundleKey] = this.dataBundles = bundles;
        }
    }

    private defineBundlesGrouped(){
        this.dataBundlesGroups = [];
        // for( let groupSlug in SLICEsGROUPs )
        //     this.dataBundlesGroups.push({
        //         slug: groupSlug,
        //         name: SLICEsGROUPs[groupSlug],
        //         list: this.dataBundles.filter(item=> item.groupSlug === groupSlug )
        //     })

    }

    private defineRegions(){
        this.regionsDct = REGIONs.reduce((acc, REGION)=>{
            let region = {...REGION,
                avaUrl: `/assets/ava/ava_${REGION.slug.toLowerCase()}.png`,
                zones: REGION.zones.map(
                    zoneId => ZONEs.find(
                        zone => zone.id === zoneId
            ))};
            [ 'quantity', 'density_min', 'density_max', 'norm_min', 'norm_max',
            ].forEach( key => region[key] = AMStore.parseFloat(REGION[key]) )

            acc[REGION.slug] = region;
            return acc;
        }, {})
    }

    private defineCities(){
        this.citiesDct = DCT_CITIES;
    }

}
