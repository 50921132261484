import { Component, NgZone } from '@angular/core';
import { StoreService } from 'src/app/store/store.service';
import { StateService } from 'src/app/store/state.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { switchMap, tap, filter, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
    selector: 'apm-common-page',
    templateUrl: './common-page.component.html'
})
export class CommonPage {
    private _pageSubscription:Subscription = new Subscription();
    public subViewKey;

    constructor(
        private store: StoreService,
        public state: StateService,
        public zone: NgZone,
        private route: ActivatedRoute,
        private router: Router,
    ){}
    
    ngOnInit() {
        this._pageSubscription.add(
            this.route.paramMap.pipe(
                tap(p=> console.log('[DEV] C p', this.route.routeConfig, p)),
                map(paramsMap => ({
                    section: paramsMap.get('section'),
                    viewKey: paramsMap.get('sub'),
                })),
                filter(o => !!o.section),
                tap(o =>{
                    console.log('[DEV] Common Page at', o);
                    this.state.resetStateTo(o.section);
                    this.subViewKey = o.viewKey;
                })
            ).subscribe()
        )
        this.router.routerState.root.params
            .subscribe((data) => {
                console.log('[DEV] C data', data)
            });
    }

    ngOnDestroy(): void {
        this._pageSubscription.unsubscribe();
    }

    ngAfterViewInit(): void {
    }
}
