import { Component, Input, HostBinding } from '@angular/core';
import { Region } from 'src/app/store/types';
import { StateService } from '../../../store/state.service';
import { StoreService } from '../../../store/store.service';

@Component({
    selector: 'apm-popupcard-layout',
    templateUrl: './popupcard-layout.component.html',
})
export class PopupcardLayoutComponent {
    @Input() type:string;
    @Input() tab:string;

    public currentSubTab = 1;

    public statuses = [
        {status: 'ready', note: 'РО выбран'},
        {status: 'warning', note: 'РО выбран, но не приступил к работе'},
        {status: 'danger', note: 'РО в процессе выбора'},
        {status: 'extreme', note: 'Соглашение с РО расторгнуто'}
    ]

    constructor(
        public state:StateService,
        public store:StoreService,
    ){}
    ngOnInit(){}

    public getSummaryNote(status:string):string {
        switch(status){
            case 'ready':  return 'ГОТОВ';
            case 'warning': return 'ЧАСТИЧНО ГОТОВ';
            case 'danger': return 'НЕ ГОТОВ';
            case 'extreme': return 'ОСТАНОВЛЕН';
            case 'none': return 'НЕ ПРЕДСТАВЛЕНО';
            default: return status;
        }
    }

    public isZeroStatus( criteriaKey ): boolean {
        return this.statuses.every( status => this.store.getStoredValue_region(criteriaKey+'_'+status, this.state.currentRegion.slug) == 0 )
    }

    public getValue( key:string ): any {
        return this.store.getStoredValue_region(key, this.state.currentRegion.slug);
    }
}
