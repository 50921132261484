import { NgModule, Injectable, NgZone } from '@angular/core';
import { Routes, RouterModule, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ReadinessPage } from './pages/readiness/readiness-page.component';
import { TreatmentPage } from './pages/treatment/treatment-page.component';
import { MapLayoutComponent } from './components/layout/map/map-layout.component';
import { ReadinessMapComponent } from './components/readiness/map/readiness-map.component';
import { ReadinessTableComponent } from './components/readiness/table/readiness-table.component';
import { Observable } from 'rxjs';
import { StateService } from './store/state.service';
import { tap, take, map, switchMap } from 'rxjs/operators';
import { FreshairPageComponent } from './pages/freshair/freshair-page.component';
import { CommonPage } from './pages/common/common-page.component';
import { NothingPage } from './pages/nothing/nothing-page.component';
import { CommonMapSection } from './pages/common/sections/map/common-map-section.component';
import { StatustkoTableComponent } from './components/statustko-table/statustko-table.component';
import { CommonTableComponent } from './components/common-table/common-table.component';

@Injectable()
export class StateResolver implements Resolve<boolean> {
    constructor(
        private state: StateService,
        private zone: NgZone,
    ) {}

    public resolve( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<boolean> {
        const id: number = parseInt(route.params['contextId'], 10);
        console.log('[DEV] Resolve', route.url[0].path, route);

        return this.state
            .hideAll$()
            .pipe(
                map(() => {
                    switch ( route.url[0].path ) {
                        case 'statustko':
                            this.state.mainEntityKey = 'region';
                            this.state.mainSectionKey = 'statustko';
                            break;

                        case 'statustko':
                            this.state.mainEntityKey = 'region';
                            this.state.mainSectionKey = 'twotp';
                            break;

                        case 'readiness':
                            this.state.mainEntityKey = 'region';
                            this.state.mainSectionKey = 'readiness';
                            break;

                        case 'treatment':
                            this.state.mainEntityKey = 'region';
                            this.state.mainSectionKey = 'treatment';
                            break;
                    }
                    return true;
                })
            );
    }
}

const resolve = {
    isReady: StateResolver
};
const routes: Routes = [
    {
        path: 'readiness', component: ReadinessPage,
        children: [
            { path: 'map', component: ReadinessMapComponent },
            { path: 'table', component: ReadinessTableComponent },
            { path: '**', redirectTo: 'map' },
        ]
    },
    {
        path: 'readiness/table', component: ReadinessTableComponent,
    },
    // {
    //     path: 'statustko/table', component: StatustkoTableComponent,
    // },
    {
        path: 'at/:section', component: CommonPage,
        children: [
            { path: 'table', component: CommonTableComponent },
        ]
    },
    { path: 'at/:section/:sub', component: CommonPage, },
    { path: 'at/:section/**', redirectTo: 'at/:section/map', },
    { path: 'at/**', redirectTo: 'at/readiness/map', },
    {
        path: 'freshair', component: FreshairPageComponent,
    },
    { path: 'menu', component: NothingPage },
    { path: '**', redirectTo: 'menu' },
    { path: '', redirectTo: 'menu', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
