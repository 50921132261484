import { Component, OnDestroy, NgZone, AfterViewInit, ViewChild, ElementRef, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { auditTime, takeUntil, take, tap } from 'rxjs/operators';
import { Group, Layer, Path, CompoundPath, PathItem, Point, Shape } from 'paper';
import * as Highcharts from 'highcharts';
import Variablepie from 'highcharts/modules/variable-pie';
import { RUSSIAPATHS } from './russia';
import { StoreService } from 'src/app/store/store.service';
import { StateService } from 'src/app/store/state.service';
import { PaperComponent } from 'src/app/components/paper/paper.component';
import { DCT_CITIES } from '../../store/dct_cities';

Variablepie(Highcharts);

@Component({
    selector: 'apm-freshair-page',
    templateUrl: './freshair-page.component.html'
})
export class FreshairPageComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(PaperComponent) paper: PaperComponent;

    private rootPaperGroup: Group;
    private regionsGroup: Group;
    private circlesGroup: Group;

    private isClickKeeped = false;
    private totalchart: Highcharts.Chart;

    private _destroyed$ = new Subject();

    constructor(
        private zone: NgZone,
        private element: ElementRef,
        public state: StateService,
        public store: StoreService,
    ) {}

    ngOnInit(): void {
        this.state.resetStateTo('freshair');
        this.state.adjustSectionTo('map');
    }

    ngAfterViewInit() {
        console.log('[DEV] InitMapComponent', this.paper);
        this.zone.runOutsideAngular(() => {
            this.initMap();
            this.initChart();
        });
        // this.zone.onStable.pipe(
        //     take(1), tap(() => this.state.resetStateTo('freshair') )
        // ).subscribe();
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    private initMap() {
        // основная группа, здесь лежат все элементы
        console.log('[DEV] init');
        this.regionsGroup = new Group();
        this.circlesGroup = new Group();

        this.rootPaperGroup = new Group;
        this.rootPaperGroup.addChild(this.regionsGroup);
        this.rootPaperGroup.addChild(this.circlesGroup);

        Object.keys(RUSSIAPATHS).forEach(key => {
            const item = this.drawRegion(RUSSIAPATHS[key], key);
            this.regionsGroup.addChild(item);
        });

        let circleIndex = 0;
        const cities = Object.keys(DCT_CITIES);
        cities
            .map(key => {
                const color = this.getColor(circleIndex++, cities.length);
                const item = this.drawCircle(DCT_CITIES[key], key, color);
                const emission_volume = this.state.getStoredValueForCity(DCT_CITIES[key].slug);
                const diameter = this.drawCircleGetDiameter(emission_volume);
                item.onMouseDown = event => {
                    this.isClickKeeped = true;
                };
                item.onMouseUp = event => {
                    if ( !this.isClickKeeped ) { return; }
                    this.isClickKeeped = true;
                    console.log('[DEV] ~touched', event.target['slug'], event, event.point);
                    // TODO исправить обработку масштаба и перенести отсюда
                    if (item && item.name) {
                        if ( this.state.currentCity && this.state.currentCity.slug === item.name ) {
                            this.state.hideAll();
                        } else {
                            this.state.showPopup({ city: DCT_CITIES[item.name], point: event.point});
                        }
                        return;
                    }
                };
                item['slug'] = key;
                return [item, diameter];
            })
            // кто больше - тот сверху
            .sort((a: [Group, number], b: [Group, number]) => a[1] - b[1])
            .map((v) => v[0])
            .forEach((item: Group) => {
                this.circlesGroup.addChild(item);
            });

        this.regionsGroup.style = {
            fillColor: 'rgb(161,185,209)',
            strokeColor: '#fff',
            strokeJoin: 'round',
            strokeWidth: 1 // дробное значение лучше не ставить, падает фпс
        };

        // this.rootPaperGroup.scale(0.8);
        this.rootPaperGroup.translate(new Point(340, 0));

        this.state.state$
            .pipe(
                auditTime(200),
                takeUntil(this._destroyed$)
            )
            .subscribe(() => {
                this.recalcCircles();
                this.changeCircles();
                this.drawChart();
            });
    }

    private drawCircle(data, name, color): Group {
        const group = new Group();
        group.name = name;
        group.data.data = data;
        group.data.color = color;

        const inner = Shape.Circle({
            name: 'inner',
            center: [data.x, data.y],
            radius: 3
        });
        inner.fillColor = `rgba(${color.join(',')})`;

        const outer = Shape.Circle({
            name: 'outer',
            center: [data.x, data.y],
            radius: 20
        });
        outer.fillColor = `rgba(${color.join(',')}, 0.5)`;
        outer.strokeColor = `rgba(${color.join(',')})`;
        outer.strokeWidth = 1;

        group.addChildren([ inner, outer ]);

        return group;
    }

    private recalcCircles() {
        Object.keys(DCT_CITIES).forEach(key => {
            const item = this.circlesGroup.children[key] as Group;
            if (!item) { return; }
            const data = DCT_CITIES[key];
            const emission_volume = this.state.getStoredValueForCity(data.slug);
            const diameter = this.drawCircleGetDiameter(emission_volume);
            // item.data.slug = data.slug;
            item.data.emission_volume = emission_volume;
            item.data.diameter = diameter;
        });
    }

    private changeCircles() {
        Object.keys(DCT_CITIES).forEach(key => {
            const item = this.circlesGroup.children[key] as Group;
            if (!item) { return; }
            item.children['outer'].radius = item.data.diameter / 2;
        });
    }

    private drawCircleGetDiameter(emission_volume): number {
        return 13 * emission_volume ** (1 / 3);
    }

    private drawRegion( pathStr: string, name: string ): Path | CompoundPath {
        const path = PathItem.create(pathStr);
        path.name = name;
        path.closed = true;
        return path;
    }

    private getColor(index, total): number[] {
        const colors = [
            [164, 60, 142],
            [84, 156, 212]
        ];
        if (index <= 0) { return colors[0]; }
        if (index >= total - 1) { return colors[1]; }
        return [
            Math.round(colors[0][0] + ((colors[1][0] - colors[0][0]) / total) * index),
            Math.round(colors[0][1] + ((colors[1][1] - colors[0][1]) / total) * index),
            Math.round(colors[0][2] + ((colors[1][2] - colors[0][2]) / total) * index)
        ];
    }

    private drawChart() {
        const reversedItems = [].concat(this.circlesGroup.children).reverse();

        this.totalchart.series[0].update({
            data: reversedItems.map((item, i) => {
                return {
                    name: item.data.data.name,
                    y: item.data.emission_volume,
                    z: this.store.getStoredValue_region('pollution_level', item.data.data.slug, this.state.currentPeriod)
                };
            })
        } as any);
    }

    private initChart() {
        // const reversedItems = [].concat(this.circlesGroup.children).reverse();

        this.totalchart = Highcharts.chart(this.element.nativeElement.querySelector('#totalchart'), {
            chart: {
                type: 'variablepie',
                backgroundColor: 'transparent',
                height: 300,
                width: 400,
                animation: false
            },
            title: {
                align: 'center',
                floating: true,
                verticalAlign: 'middle',
                text: '8/12<br> с высоким<br>уровнем<br> загрязнения',
                x: 12,
                y: -15,
                style: {
                    fontSize: '10px'
                }
            },
            // colors: reversedItems.map((item) => `rgba(${item.data.color.join(',')})`),
            colors: [
                '#A24E95', '#A873A6', '#A67BAD', '#A081AF',
                '#A28DB9', '#A097C2', '#9D9DC5', '#9BA6CF',
                '#9AAED6', '#98B4DD', '#98B4DE', '#A4C5F7'
            ],
            tooltip: {
                headerFormat: '',
                pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
                    'Выбросы (тыс.тонн): <b>{point.y}</b><br/>'
            },
            series: [{
                type: 'variablepie',
                minPointSize: 10,
                innerSize: '55%',
                zMin: 0,
                data: []
                // reversedItems.map((item, i) => ({
                //     name: item.data.data.name,
                //     y: item.data.emission_volume,
                //     z: i <= 5 ? 100 :
                //     i <= 7 ? 80  :
                //     i <= 9 ? 60  : 40
                // }))
            }]
        });
    }
}
