import { Component } from "@angular/core";
import { StoreService } from '../../store/store.service';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { StateService } from '../../store/state.service';

@Component({
    selector: 'apm-data-selector',
    templateUrl: './data-selector.component.html',
    host:{class:'apm-data-selector'}
})
export class DataSelectorComponent{
    public dataControl = new FormControl('', [Validators.required]);
    public selectFormControl = new FormControl('', Validators.required);

    public myControl = new FormControl();
    stateForm: FormGroup = this.fb.group({
        stateGroup: '',
    });
    constructor(
        private fb: FormBuilder,
        public store:StoreService,
        public state:StateService,
    ){

    }

    stateGroupOptions: Observable<any[]>;

    ngOnInit(){
        this.dataControl.valueChanges.subscribe( value=>{
            this.state.currentSlice = value;
            this.state.push();
        })
    }
}
