import { Directive, ViewContainerRef } from '@angular/core';

export interface IHostComponent{
    data: any;
    isSub: boolean;
    isParent: boolean;
    isChild: boolean;
}

@Directive({ selector: '[apm-host]' })
export class HostDirective {
    constructor( public viewContainerRef: ViewContainerRef ){ }
}
