import { Component, NgZone, Input } from "@angular/core";
import { Router } from '@angular/router';
import { StateService } from '../../../store/state.service';

@Component({
    selector: 'apm-head-layout',
    templateUrl: './head-layout.component.html',
    host:{class:'b3_v2-ws-header b3_v2-top-bar'}
})
export class HeadLayoutComponent{
    @Input() sectionName:string = ''
    constructor(
        private zone: NgZone,
        private router: Router,
        public state:StateService,
    ){
        
    }

    public navigate( commands: any[] ): void {
        this.zone.run(() => this.router.navigate(commands)).then();
    }
}
