import { NgModule } from "@angular/core";
import { HostDirective } from './host.directive';

@NgModule({
    declarations:[
        HostDirective
    ],
    exports:[
        HostDirective
    ]
})
export class AuxillaryElementsModule{}
