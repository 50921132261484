import { Component, Input, ViewChild, ComponentFactoryResolver, ElementRef, TemplateRef, ViewContainerRef, Directive } from "@angular/core";
import { IHostComponent } from '../../ae/host.directive';
import { ReadinessStatsCellComponent } from './readiness-stat/readiness-stats-cell.component';
import { ICell } from './cell-base.component';
import { ViaDimCellComponent } from './via-dim/via-dim-cell.component';
import { QuantityCellComponent } from './quantity/quantity-cell.component';
import { PercentCellComponent } from './percent/percent-cell.component';
import { KtonnCellComponent } from './ktonn/ktonn-cell.component';
import { NumberCellComponent } from './number/number-cell.component';

@Directive({
    selector: '[apmCell]',
})
export class CellDirective{
    private _cell:ICell;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private element: ElementRef,
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef
    ){ }

    // @Input() apmCellIsParent:boolean;
    // @Input() apmCellIsChild:boolean;
    @Input()
    set apmCell( cell ){
        // console.log('[DEV] set apmCell', cell && cell.value, cell);
        this._cell = cell;
        if( cell ){
            let component = {
                'readiness-stats': ReadinessStatsCellComponent,
                'quantity': QuantityCellComponent,
                'percent': PercentCellComponent,
                'via-dim': ViaDimCellComponent,
                'ktonn': KtonnCellComponent,
                'number': NumberCellComponent,
            }[cell.type];
            let componentFactory;
            if( component ) componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
            if( componentFactory ){
                this.viewContainerRef.clear();
    
                let componentRef = this.viewContainerRef.createComponent(componentFactory);
                (<IHostComponent>componentRef.instance).data = cell;
                // (<IHostComponent>componentRef.instance).isParent = (cell.items && cell.items.length > 0) || this.apmCellIsParent;
                // (<IHostComponent>componentRef.instance).isChild = this.apmCellIsChild;
            }
        }
            
    }

    ngOnInit(){
    }
}
