import {
    Component,
    ViewEncapsulation,
    NgZone,
    ChangeDetectorRef,
    ElementRef,
    OnDestroy
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { StoreService } from './store/store.service';
import { StateService } from './store/state.service';
import { Subscription } from 'rxjs';
import { MatBottomSheet } from '@angular/material';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {class: 'apm-app'}
})
export class AppComponent implements OnDestroy {
    title = 'asu-promo-map';
    private zoneSubscr: Subscription;

    constructor(
        private router: Router,
        private store: StoreService,
        public state: StateService,
        private zone: NgZone,
        private bottomSheet: MatBottomSheet,
        private cdr: ChangeDetectorRef,
        private eRef: ElementRef,
    ) {
        (<any>window || {}).store = this.store;
        (<any>window || {}).state = this.state;

        // this.router.events.subscribe((event) => {
        //     if (event instanceof NavigationEnd) {
        //         let section = 'treatment';
        //         try {
        //             const path = this.router.routerState.snapshot.root.children[0].url[0].path;
        //             if (this.router.config.some((c) => c.path === path)) {
        //                 section = path;
        //             }
        //         } catch (e) {}
        //         this.state.resetStateTo(section);
        //     }
        // });
    }

    // @HostListener('document:tap', ['$event'])
    // @HostListener('document:click', ['$event'])
    // clickout(event) {
    //     this.state.hideZone();
    //     this.state.hideRegion();
    //     this.state.push();
    //     if(this.eRef.nativeElement.contains(event.target)) {
    //         console.log("clicked inside");
    //     } else {
    //         console.log("clicked outside");
    //     }
    // }


    openBottomSheet( region: any, point: {x, y} ): void {


        // let bs = this.bottomSheet.open(RegionInfoComponent);
        // console.log('[DEV] bs', bs);
        // bs.afterOpened().subscribe(()=>{
        //     console.log('[DEV] afterOpened region', region);
        //     bs.instance.region = region;
        // })
        // this.bottomSheet.dismiss();
        // bs.dismiss();
    }

    ngOnDestroy(): void {
        if ( this.zoneSubscr ) { this.zoneSubscr.unsubscribe(); }
    }
}
