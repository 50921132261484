import { Component, Input, ViewChild, ComponentFactoryResolver } from "@angular/core";
import { HostDirective, IHostComponent } from '../../ae/host.directive';
import { ReadinessStatsCellComponent } from './readiness-stat/readiness-stats-cell.component';
import { QuantityCellComponent } from './quantity/quantity-cell.component';
import { PercentCellComponent } from './percent/percent-cell.component';
import { ViaDimCellComponent } from './via-dim/via-dim-cell.component';
import { KtonnCellComponent } from './ktonn/ktonn-cell.component';

@Component({
    selector: 'apm-cell',
    template: '<ng-container apm-host></ng-container>',
    host:{
        class:'b3-cell'
    }
})
export class CellHostComponent{
    @Input() kind:string;
    @Input() data:any;
    @ViewChild(HostDirective) asuHost: HostDirective;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
    ){ }

    ngOnInit(){
        let componentFactory;
        let component = {
            'readiness-stats': ReadinessStatsCellComponent,
            'quantity': QuantityCellComponent,
            'percent': PercentCellComponent,
            'via-dim': ViaDimCellComponent,
            'ktonn': KtonnCellComponent,
            // 'mass-value': ClusterBandComponent,
        }[this.kind];
        if( component ) componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        if( componentFactory ){
            let viewContainerRef = this.asuHost.viewContainerRef;
            viewContainerRef.clear();

            let componentRef = viewContainerRef.createComponent(componentFactory);
            (<IHostComponent>componentRef.instance).data = this.data;
        }
    }
}
