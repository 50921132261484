import { Point } from 'paper';

export const AMEvents = {
    getOffsetPointByEventTarget( event ){
        if( event.srcEvent ) event = event.srcEvent;
        console.log('[DEV] event offset', event.offsetX, event.offsetY);
        
        if( event.offsetX && event.offsetY )
            return new Point(event.offsetX, event.offsetY);

        const rect = event.target.getBoundingClientRect();
        console.log('[DEV] rect', rect);
        return new Point(
            event.clientX - rect.left,
            event.clientY - rect.top
        );
    }
}
