import { Component, ViewEncapsulation } from "@angular/core";
import {coerceNumberProperty} from '@angular/cdk/coercion';
import { StoreService } from '../../store/store.service';
import { StateService } from '../../store/state.service';

@Component({
    selector: 'apm-timeline',
    templateUrl: './timeline.component.html',
    host:{class:'apm-timeline'},
    encapsulation: ViewEncapsulation.None,
})
export class TimelineComponent{
    public disabled = false;
    public autoTicks = false;
    public invert = false;
    public max = 71;
    public min = 0;
    public showTicks = true;
    public step = 1;
    public thumbLabel = false;
    public value = 0;
    public vertical = false;
  
    get tickInterval(): number | 'auto' {
      return this.showTicks ? (this.autoTicks ? 'auto' : this._tickInterval) : 0;
    }
    set tickInterval(value) {
      this._tickInterval = coerceNumberProperty(value);
    }
    private _tickInterval = 1

    constructor(
        public store:StoreService,
        public state:StateService,
    ){}
}
