import {
    Directive,
    Input,
    ElementRef,
    Renderer2,
    OnInit,
    NgZone,
    OnDestroy
} from '@angular/core';

interface ITooltipOptions {
    text?: string;
    position?: string; // above-right, above-left, above-center, below-right, below-left, below-center, left, right
    maxWidth?: number;
    minWidth?: number;
    width?: number;
    direction?: string; // rtl, ltr
    multiline?: boolean;
}

@Directive({
    selector: '[appTooltip]',
})
export class TooltipDirective implements OnInit, OnDestroy {

    @Input() public tooltipOptions: ITooltipOptions;

    private tooltip: any;
    private tooltipW: number;
    private tooltipH: number;
    private tooltipMover: (event) => void;

    private handleMouseMoveBinded = this.handleMouseMove.bind(this);

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private zone: NgZone
    ) {}

    ngOnInit() {
        if ( !this.tooltipOptions || !this.tooltipOptions.text ) { return; }

        this.renderer.addClass(this.elementRef.nativeElement, 'b3-tooltip-anchor');

        // вешаем обработчик вне зоны ангуляра чтобы не засорять change detection
        this.zone.runOutsideAngular(() => {
            this.elementRef.nativeElement.addEventListener('mousemove', this.handleMouseMoveBinded);
        });
    }

    ngOnDestroy() {
        this.elementRef.nativeElement.removeEventListener('mousemove', this.handleMouseMoveBinded);
    }

    private handleMouseMove( event ) {
        if (!this.tooltip) { this.initTooltip(this.tooltipOptions); }
        this.tooltip.innerHTML = `<span>${this.tooltipOptions.text}</span>`;
        this.tooltipMover(event);
        this.renderer.setStyle(this.tooltip, 'display', 'block');
    }

    private initTooltip( options: ITooltipOptions ): void {
        this.tooltip = this.renderer.createElement('span') as HTMLElement;
        this.tooltip.innerHTML = `<span>${options.text}</span>`;
        this.renderer.appendChild(this.elementRef.nativeElement, this.tooltip);
        this.renderer.addClass(this.tooltip, 'b3-tooltip');
        this.renderer.addClass(this.tooltip, 'b3-txt-trans-none');

        this.tooltipW = this.tooltip.clientWidth;
        this.tooltipH = this.tooltip.clientHeight;

        this.renderer.setStyle(this.tooltip, 'display', 'none');

        if ( options && options.multiline ) {
            this.renderer.addClass(this.tooltip, 'b3-tooltip_multicol');
        }

        if ( options && options.maxWidth ) {
            this.renderer.setStyle(this.tooltip, 'max-width', `${options.maxWidth}px`);
        }

        if ( options && options.minWidth ) {
            this.renderer.setStyle(this.tooltip, 'min-width', `${options.minWidth}px`);
        }


        if ( options && options.width ) {
            this.renderer.setStyle(this.tooltip, 'width', `${options.width}px`);
        }

        if ( options && options.direction ) {
            this.renderer.setStyle(this.tooltip, 'direction', options.direction);
        }

        if ( options && options.position ) {
            this.initMoverWithPosition(options.position);
        } else {
            this.initMoverWithoutPosition();
        }
    }

    private initMoverWithPosition(position: string) {
        this.renderer.addClass(this.tooltip, `b3-tooltip_${position}`);
        this.tooltipMover = () => {};
    }

    private initMoverWithoutPosition() {
        this.renderer.setStyle(this.tooltip, 'position', 'fixed');
        this.renderer.setStyle(this.tooltip, 'right', 'auto');
        this.renderer.setStyle(this.tooltip, 'bottom', 'auto');
        this.renderer.setStyle(this.tooltip, 'transform', 'translateX(0)');

        const rect = this.elementRef.nativeElement.getBoundingClientRect();
        const anchorX = rect.left;
        const anchorY = rect.top;
        const anchorW = this.elementRef.nativeElement.clientWidth;
        const anchorH = this.elementRef.nativeElement.clientHeight;

        let isLeftLimit = false;
        let isRightLimit = false;
        let isTopLimit = false;
        let isBottomLimit = false;

        // левый край
        if ( anchorX < this.tooltipW ) {
            this.renderer.addClass(this.tooltip, 'b3-tooltip_right');
            isLeftLimit = true;
        }
        // правый край
        if ( anchorX + anchorW + this.tooltipW >= document.body.clientWidth ) {
            this.renderer.addClass(this.tooltip, 'b3-tooltip_left');
            isRightLimit = true;
        }
        // верхний край
        if ( anchorY < this.tooltipH ) {
            this.renderer.addClass(this.tooltip, 'b3-tooltip_below');
            isTopLimit = true;
        }
        // нижний край
        if ( anchorY + anchorH + this.tooltipH >= document.body.clientHeight ) {
            this.renderer.addClass(this.tooltip, 'b3-tooltip_above');
            isBottomLimit = true;
        }

        const limits = [isLeftLimit, isRightLimit, isTopLimit, isBottomLimit].join(', ');
        if ( limits === 'false, false, false, false' ) {
            this.renderer.addClass(this.tooltip, 'b3-tooltip_above-right');
        }

        const getPosition = this.getPositionFactory(limits);

        this.tooltipMover = (event) => {
            const [tooltipX, tooltipY] = getPosition(event);
            this.renderer.setStyle(this.tooltip, 'left', `${tooltipX}px`);
            this.renderer.setStyle(this.tooltip, 'top', `${tooltipY}px`);
        };
    }

    private getPositionFactory(limits: string): (event) => [number, number] {
        switch ( limits ) {
            // левый край
            case 'true, false, false, false': {
                return (event) => [
                    event.clientX + 20,
                    event.clientY + 2
                ];
            }
            // правый край
            case 'false, true, false, false': {
                return (event) => [
                    event.clientX - this.tooltipW - 10,
                    event.clientY + 2
                ];
            }
            // верхний край
            case 'false, false, true, false': {
                return (event) => [
                    event.clientY + this.tooltipH + 20,
                    event.clientX - (this.tooltipW / 2)
                ];
            }
            // нижний край
            case 'false, false, false, true': {
                return (event) => [
                    event.clientY - this.tooltipH - 10,
                    event.clientX - (this.tooltipW / 2)
                ];
            }
            // левый верхний угол
            case 'true, false, true, false': {
                return (event) => [
                    event.clientX + 10,
                    event.clientY + this.tooltipH + 20
                ];
            }
            // левый нижний угол
            case 'true, false, false, true': {
                return (event) => [
                    event.clientX + 10,
                    event.clientY - this.tooltipH
                ];
            }
            // правый верхний угол
            case 'false, true, true, false': {
                return (event) => [
                    event.clientX - this.tooltipW - 10,
                    event.clientY + this.tooltipH + 20
                ];
            }
            // правый нижний угол
            case 'false, true, false, true': {
                return (event) => [
                    event.clientX - this.tooltipW + 13,
                    event.clientY - this.tooltipH - 5
                ];
            }

            default: {
                return (event) => [
                    event.clientX + 2,
                    event.clientY - 25
                ];
            }
        }
    }
}
