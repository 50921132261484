import { Component, Input } from "@angular/core";
import { StateService } from 'src/app/store/state.service';
import { StoreService } from 'src/app/store/store.service';

@Component({
    selector: 'apm-sidecard-layout',
    templateUrl: './sidecard-layout.component.html',
    host:{class:'b3-card b3-side-card b3-p-side-card',}
})
export class SidecardLayoutComponent{
    @Input() type:string;
    @Input() tab:string;

    // @Input() rzone:RZone;
    public years = [
        2019,
        2020,
        2021,
        2022,
        2023,
        2024,
    ]

    public statuses = [
        {status: 'ready', note: 'РО выбран'},
        {status: 'warning', note: 'РО выбран, но не приступил к работе'},
        {status: 'danger', note: 'РО в процессе выбора'},
        {status: 'extreme', note: 'Соглашение с РО расторгнуто'}
    ]

    constructor(
        public state: StateService,
        public store: StoreService,
    ){

    }

    public getSummaryNote(status:string):string {
        switch(status){
            case 'ready':  return 'ГОТОВ';
            case 'warning': return 'ЧАСТИЧНО ГОТОВ';
            case 'danger': return 'НЕ ГОТОВ';
            case 'extreme': return 'ОСТАНОВЛЕН';
            case 'none': return 'НЕ ПРЕДОСТАВЛЕНО';
            default: return status;
        }
    }

    public getViolationsNote(status:string):string {
        switch(status){
            case 'ready':  return 'НЕТ';
            case 'warning': return 'МАЛО';
            case 'danger': return 'ЕСТЬ';
            default: return status;
        }
    }

    public getValue( key:string ): any {
        return this.store.getStoredValue_region(key, this.state.currentRegion.slug);
    }

    public isZeroStatus( criteriaKey ): boolean {
        return this.statuses.every( status => this.store.getStoredValue_region(criteriaKey+'_'+status, this.state.currentRegion.slug) == 0 )
        
    }

    public getFormattedAmount( key ){
        let amount = this.getValue(key);
        if( !amount ) return '-';

        let res = '', str = `${amount}`;
        for( let i=1; i<str.length+1; i++ )
            res = (i%3?'':' ') + str[str.length-i] + res;

        return res;
    }
}
