export const DCT_PLANS = {
    1: {
        id: 1,
        city_slug: 'city_nor',
        name: 'Промышленные предприятия',
        count: '1',
        money_fed: '0',
        money_cons: '0',
        money_other: '123,19',
        actions: 'ПАО «ГМК «Норильский никель»: реализация комплексного плана мероприятий на металлургических предприятиях Заполярного филиала ПАО «ГМК «Норильский никель»',
        result: '1296,98',
        percent: '0,75',
    }, 




    2: { id:2, 
        city_slug: 'city_nor', name: 'Градостроительство', count: '1', money_fed: '0', money_cons: '0,019', money_other: '0', actions: 'Улучшение условий рассеивания вредных примесей в жилых кварталах путем создания «зеленого пояса» г. Норильска', result: '0', percent: '0',  }, 
    3: { id:3, city_slug: 'city_nor', name: 'Мониторинг', count: '2', money_fed: '0,21', money_cons: '0', money_other: '0', actions: 'Создание и техническое оснащение испытательной лаборатории филиала ФГБУ «ЦЛАТИ»; Модернизация государственной наблюдательной сети за загрязнением атмосферного воздуха Росгидромета, ФГБУ «Среднесибирского УГМС»', result: '0', percent: '0',  }, 
    4: { id:4, city_slug: 'city_nor', name: 'Итого', count: '4', money_fed: '0,21', money_cons: '0,019', money_other: '123,19', actions: '', result: '1296,98', percent: '',  }, 
    5: { id:5, city_slug: 'city_nov', name: 'Транспорт', count: '3', money_fed: '0', money_cons: '0,09', money_other: '0', actions: 'Обновление транспортного состава; Перевод на газомоторное топливо; Разработка и реализация программ: переход потребления природного газа, как моторного топлива', result: '4', percent: '0,0117',  }, 
    6: { id:6, city_slug: 'city_nov', name: 'Промышленные предприятия', count: '12', money_fed: '0', money_cons: '0', money_other: '11,12', actions: 'АО «РУСАЛ Новокузнецкий алюминиевый завод»; АО «ЕВРАЗ Объединенный ЗСМК»; АО «Кузнецкие ферросплавы»; АО «Кузнецкая ТЭЦ»', result: '63,83', percent: '0,1873',  }, 
    7: { id:7, city_slug: 'city_nov', name: 'Теплоэнергетика', count: '3', money_fed: '5,28', money_cons: '0,26', money_other: '0', actions: 'Газификация частного сектора; Строительство котельной (газовое топливо); Реконструкция газоочистного оборудования', result: '12,5', percent: '0,0355',  }, 
    8: { id:8, city_slug: 'city_nov', name: 'Градостроительство', count: '1', money_fed: '0,4', money_cons: '0', money_other: '0', actions: 'Внедрение НДТ', result: '0,5', percent: '0,0014',  }, 
    9: { id:9, city_slug: 'city_nov', name: 'Мониторинг', count: '2', money_fed: '0,204', money_cons: '0', money_other: '0', actions: 'Модернизация государственной наблюдательной сети за загрязнением атмосферного воздуха Росгидромета; Техническое перевооружение испытательных лабораторий филиала ФГБУ "ЦЛАТИ"', result: '0', percent: '0',  }, 
    10: { id:10, city_slug: 'city_nov', name: 'Итого', count: '21', money_fed: '5,884', money_cons: '0,35', money_other: '11,12', actions: '', result: '80,83', percent: '',  }, 
    11: { id:11, city_slug: 'city_chel', name: 'Транспорт', count: '7', money_fed: '11,734', money_cons: '0,228', money_other: '0', actions: 'Обновление дорожной инфраструктуры; Перевод на экологические виды топлива', result: '3,4', percent: '0,0105',  }, 
    12: { id:12, city_slug: 'city_chel', name: 'Промышленные предприятия', count: '25', money_fed: '0', money_cons: '0', money_other: '21,734', actions: 'ПАО «Мечел»; ООО «Мечел-Кокс»; АО «Челябинский электрометаллургический комбинат»; ПАО «Челябинский цинковый завод»; ПАО «Челябинский трубопрокатный завод»; филиал ООО «Завод ТЕХНО»; ЗАО «Завод Минплита»; ПАО «Челябинский кузнечно-прессовый завод»', result: '8,085', percent: '0,025',  }, 
    13: { id:13, city_slug: 'city_chel', name: 'Теплоэнергетика', count: '2', money_fed: '0', money_cons: '0,35', money_other: '0,31', actions: 'ПАО «Фортум»; Газификация частного сектора', result: '1,5', percent: '0,0046',  }, 
    14: { id:14, city_slug: 'city_chel', name: 'Коммунальный сектор', count: '1', money_fed: '0', money_cons: '0,78', money_other: '0', actions: 'Рекультивация городской свалки', result: '62,9', percent: '0',  }, 
    15: { id:15, city_slug: 'city_chel', name: 'Мониторинг', count: '5', money_fed: '0,297', money_cons: '0,136', money_other: '0,005', actions: 'Модернизация государственной наблюдательной сети за загрязнением атмосферного воздуха Росгидромета; Техническое перевооружение испытательных лабораторий филиала ФГБУ «ЦЛАТИ»; Установка 2 экологических измерительных комплексов для мониторинга загрязняющих веществ в атмосферном воздухе на границе СЗЗ ПАО «Мечел»; Разработка проекта территориальной системы наблюдения за состоянием окружающей среды', result: '0', percent: '0',  }, 
    16: { id:16, city_slug: 'city_chel', name: 'Итого', count: '40', money_fed: '12,031', money_cons: '1,494', money_other: '22,049', actions: '', result: '75,885', percent: '',  }, 
    17: { id:17, city_slug: 'city_mag', name: 'Промышленные предприятия', count: '10', money_fed: '0', money_cons: '0', money_other: '21,1', actions: 'ПАО «Магнитогорский металлургический комбинат»; ООО «Шлаксервис»; ОАО «ММК-Метиз»; ООО «Огнеупор»', result: '22,78', percent: '0,0793',  }, 
    18: { id:18, city_slug: 'city_mag', name: 'Коммунальный сектор', count: '1', money_fed: '0', money_cons: '0,24', money_other: '0', actions: 'Рекультивация городской свалки', result: '57,8', percent: '0',  }, 
    19: { id:19, city_slug: 'city_mag', name: 'Теплоэнергетика', count: '1', money_fed: '0,76', money_cons: '0,18', money_other: '0', actions: 'Газификация жилых домов', result: '1', percent: '0,0035',  }, 
    20: { id:20, city_slug: 'city_mag', name: 'Мониторинг', count: '4', money_fed: '0,61', money_cons: '0,001', money_other: '0,03', actions: 'Разработка проекта территориальной системы наблюдения за состоянием окружающей среды; Модернизация государственной наблюдательной сети за загрязнением атмосферного воздуха Росгидромета; Техническое перевооружение испытательных лабораторий филиала ФГБУ «ЦЛАТИ»; Установка двух стационарных постов наблюдений  предприятий за качеством атмосферного воздуха на территории города Магнитогорска', result: '0', percent: '0',  }, 
    21: { id:21, city_slug: 'city_mag', name: 'Итого', count: '16', money_fed: '1,37', money_cons: '0,421', money_other: '21,13', actions: '', result: '81,58', percent: '',  }, 
    22: { id:22, city_slug: 'city_kra', name: 'Транспорт', count: '6', money_fed: '5', money_cons: '0,44', money_other: '14,11', actions: 'Обновление подвижного состава общественного транспорта; Развитие уличной дорожной сети и автоматизированной системы управления дорожным движением; Строительство железнодорожного кольца и пересадочных узлов; Принятие правового акта ограничивающего въезд грузовых транспортных средств', result: '12,1', percent: '0,0635',  }, 
    23: { id:23, city_slug: 'city_kra', name: 'Промышленные предприятия', count: '7', money_fed: '0', money_cons: '0', money_other: '1,69', actions: 'АО «РУСАЛ Красноярск»; ООО «Красноярский цемент»', result: '9,52', percent: '0,0499',  }, 
    24: { id:24, city_slug: 'city_kra', name: 'Теплоэнергетика', count: '7', money_fed: '3,37', money_cons: '0,28', money_other: '42,9', actions: 'Принятие правового акта ограничивающего использование бурого и каменного угля без очистки; ООО «Сибирская генерирующая компания» (АО «Красноярская ТЭЦ-1», филиал «Красноярская ТЭЦ-2»,филиал «Красноярская ТЭЦ-3»); Переселение граждан из аварийного жилищного фонда с печным отоплением и газификация жилых домов, частных домовладений, зданий различного назначения', result: '20,96', percent: '0,1099',  }, 
    25: { id:25, city_slug: 'city_kra', name: 'Градостроительство', count: '4', money_fed: '0', money_cons: '0,38', money_other: '0', actions: 'Улучшение условий рассеивания вредных примесей в жилых кварталах, озеленение и обустройство общественных пространств г. Красноярска и создание лесопаркового «зеленого пояса» вокруг г. Красноярска', result: '0', percent: '0',  }, 
    26: { id:26, city_slug: 'city_kra', name: 'Мониторинг', count: '6', money_fed: '0,359', money_cons: '0,017', money_other: '0,026', actions: 'Модернизация государственной наблюдательной сети за загрязнением атмосферного воздуха Росгидромета; Техническое перевооружение испытательных лабораторий филиала ФГБУ "ЦЛАТИ"', result: '0', percent: '0',  }, 
    27: { id:27, city_slug: 'city_kra', name: 'Итого', count: '30', money_fed: '8,729', money_cons: '1,117', money_other: '58,726', actions: '', result: '42,58', percent: '',  }, 
    28: { id:28, city_slug: 'city_bra', name: 'Транспорт', count: '4', money_fed: '0', money_cons: '1,28', money_other: '0,93', actions: 'Обновление подвижного состава общественного транспорта; Комплексное развитие транспортной инфраструктуры  и  переход потребления  природного газа как моторного топлива', result: '1,74', percent: '0,0138',  }, 
    29: { id:29, city_slug: 'city_bra', name: 'Промышленные предприятия', count: '9', money_fed: '0', money_cons: '0', money_other: '14,8', actions: 'ПАО «РУСАЛ Братск»; АО «Группа «Илим» в г. Братск; ООО «Братский завод ферросплавов»', result: '16,57', percent: '0,131',  }, 
    30: { id:30, city_slug: 'city_bra', name: 'Теплоэнергетика', count: '6', money_fed: '5,49', money_cons: '0,33', money_other: '2,79', actions: 'ПАО «Иркутскэнерго»; Газификация города', result: '6,61', percent: '0,0523',  }, 
    31: { id:31, city_slug: 'city_bra', name: 'Градостроительство', count: '2', money_fed: '0,26', money_cons: '0,011', money_other: '0,003', actions: 'Создание условий для самоочищения атмосферного воздуха; Строительство лесопитомника', result: '0,31', percent: '0,0025',  }, 
    32: { id:32, city_slug: 'city_bra', name: 'Мониторинг', count: '5', money_fed: '0,16', money_cons: '0,02', money_other: '0,08', actions: 'Модернизация государственной наблюдательной сети за загрязнением атмосферного воздуха Росгидромета; Оснащение ФГБУ «ЦЛАТИ» по Енисейскому региону» передвижной экологической  лабораторией ', result: '0', percent: '0',  }, 
    33: { id:33, city_slug: 'city_bra', name: 'Итого', count: '26', money_fed: '5,91', money_cons: '1,641', money_other: '18,603', actions: '', result: '25,23', percent: '',  }, 
    34: { id:34, city_slug: 'city_lip', name: 'Транспорт', count: '3', money_fed: '2,46', money_cons: '0,33', money_other: '10,75', actions: 'Создание инфраструктуры для эксплуатации газомоторного транспорта и разработка программ, направленных на расширение потребления природного газа; Обновление подвижного состава общественного транспорта', result: '0,16', percent: '0,0005',  }, 
    35: { id:35, city_slug: 'city_lip', name: 'Промышленные предприятия', count: '4', money_fed: '0', money_cons: '0,2', money_other: '6,31', actions: 'ПАО «Новолипецкий металлургический комбинат»; МУП «Липецкая станция аэрации»; АО «Липецкцемент»; ПАО «Квадра – Генерирующая компания»', result: '6,2', percent: '0,0188',  }, 
    36: { id:36, city_slug: 'city_lip', name: 'Градостроительство', count: '1', money_fed: '0', money_cons: '0', money_other: '0', actions: 'Рекультивация городской свалки', result: '0', percent: '0',  }, 
    37: { id:37, city_slug: 'city_lip', name: 'Мониторинг', count: '2', money_fed: '0,21', money_cons: '0', money_other: '0', actions: 'Модернизация государственной наблюдательной сети за загрязнением атмосферного воздуха Росгидромета; Техническое перевооружение испытательных лабораторий филиала ФГБУ "ЦЛАТИ"', result: '0', percent: '0',  }, 
    38: { id:38, city_slug: 'city_lip', name: 'Итого', count: '10', money_fed: '2,67', money_cons: '0,53', money_other: '17,06', actions: '', result: '6,36', percent: '',  }, 
    39: { id:39, city_slug: 'city_cher', name: 'Транспорт', count: '8', money_fed: '0', money_cons: '0,15', money_other: '0', actions: 'Комплексное развитие транспортной инфраструктуры  и  переход потребления  природного газа как моторного топлива; Обновление подвижного состава общественного транспорта', result: '2,2', percent: '0,0066',  }, 
    40: { id:40, city_slug: 'city_cher', name: 'Промышленные предприятия', count: '27', money_fed: '0', money_cons: '0', money_other: '14,146', actions: 'ПАО «Северсталь»; АО «Череповецкий фанерно-мебельный комбинат»; АО «Аппатит»; ОАО «Северсталь-Метиз»; МУП «Водоканал»', result: '34,59', percent: '0,1035',  }, 
    41: { id:41, city_slug: 'city_cher', name: 'Мониторинг', count: '5', money_fed: '0,19', money_cons: '0', money_other: '0', actions: 'Модернизация государственной наблюдательной сети за загрязнением атмосферного воздуха Росгидромета; Техническое перевооружение испытательных лабораторий филиала ФГБУ «ЦЛАТИ»; Проведение сводных расчетов загрязнения атмосферного воздуха', result: '0', percent: '0',  }, 
    42: { id:42, city_slug: 'city_cher', name: 'Итого', count: '40', money_fed: '0,19', money_cons: '0,15', money_other: '14,146', actions: '', result: '36,79', percent: '',  }, 
    43: { id:43, city_slug: 'city_nt', name: 'Транспорт', count: '2', money_fed: '0', money_cons: '0', money_other: '0', actions: 'Перевод автомобильного транспорта на газомоторное топливо', result: '0', percent: '0',  }, 
    44: { id:44, city_slug: 'city_nt', name: 'Мониторинг', count: '0', money_fed: '0,17', money_cons: '0', money_other: '0', actions: 'Модернизация государственной наблюдательной сети за загрязнением атмосферного воздуха Росгидромета; Техническое перевооружение испытательных лабораторий филиала ФГБУ "ЦЛАТИ"', result: '0', percent: '0',  }, 
    45: { id:45, city_slug: 'city_nt', name: 'Промышленные предприятия', count: '15', money_fed: '0', money_cons: '0', money_other: '2,31', actions: 'АО «ЕВРАЗ НТМК»; ОАО «Высокогорский ГОК»; ОАО «НПК Уралвагонзавод»', result: '25,48', percent: '0,1836',  }, 
    46: { id:46, city_slug: 'city_nt', name: 'Теплоэнергетика', count: '4', money_fed: '0,11', money_cons: '0', money_other: '0', actions: 'Реконструкция угольных котельных', result: '0,2', percent: '0,0014',  }, 
    47: { id:47, city_slug: 'city_nt', name: 'Итого', count: '21', money_fed: '0,28', money_cons: '0', money_other: '2,31', actions: '', result: '25,68', percent: '',  }, 
    48: { id:48, city_slug: 'city_chit', name: 'Транспорт', count: '3', money_fed: '2,958', money_cons: '0,085', money_other: '0', actions: 'Обновление подвижного состава общественного транспорта; Строительство троллейбусных линий «Троллейбусное депо – КСК» ; Строительство троллейбусных линий «Троллейбусное депо – Каштак»', result: '1,46', percent: '0,0378',  }, 
    49: { id:49, city_slug: 'city_chit', name: 'Теплоэнергетика', count: '14', money_fed: '3,98', money_cons: '0,08', money_other: '0,01', actions: 'Реконструкция и модернизация Читинской теплоэлектро-централи; Модернизация и закрытие котельных с переводом на централизованное теплоснабжение; Замена пылегазо-очистного оборудования на 7-ми котельных Забайкальской дирекции   по тепловодо-снабжению - структурного подразделения ; Ужесточение требований по снижению выбросов загрязняющих веществ стационарными источниками', result: '7,83', percent: '0,1884',  }, 
    50: { id:50, city_slug: 'city_chit', name: 'Мониторинг', count: '3', money_fed: '0,049', money_cons: '0', money_other: '0', actions: 'Установка в Черновском районе города, в пос. КСК автоматической станции контроля загрязнения атмосферного воздуха; Приобретение передвижной лаборатории для Читинского отдела ЦЛАТИ по Восточно-Сибирскому региону; Разработка сводных расчетов допустимого негативного воздействия на окружающую среду', result: '0', percent: '0',  }, 
    51: { id:51, city_slug: 'city_chit', name: 'Итого', count: '20', money_fed: '6,987', money_cons: '0,165', money_other: '0,01', actions: '', result: '9,29', percent: '',  }, 
    52: { id:52, city_slug: 'city_oms', name: 'Транспорт', count: '6', money_fed: '0', money_cons: '0,0029', money_other: '3,56', actions: 'Развитие уличной дорожной сети; Обновление подвижного состава общественного транспорта', result: '22,56', percent: '0,09',  }, 
    53: { id:53, city_slug: 'city_oms', name: 'Промышленные предприятия', count: '26', money_fed: '0,45', money_cons: '0', money_other: '105,5', actions: 'ПАО «Газромнефть-ОНПЗ»; ООО «Омсктехуглерод»; ПАО «Омскшина»; ООО «Полиом»; ПАО «Омский каучук»; ПАО «Сатурн»; АО «ТРАНСМАШ» ; АО «Омск-РТС» ; АО «Первая грузовая компания» ', result: '11,15', percent: '0,0445',  }, 
    54: { id:54, city_slug: 'city_oms', name: 'Теплоэнергетика', count: '15', money_fed: '6,06', money_cons: '0,004', money_other: '0,207', actions: 'ТГК-11', result: '22,5', percent: '0,0897',  }, 
    55: { id:55, city_slug: 'city_oms', name: 'Градостроительство', count: '1', money_fed: '0,075', money_cons: '0,012', money_other: '0', actions: 'Озеленение города Омска', result: '0', percent: '0',  }, 
    56: { id:56, city_slug: 'city_oms', name: 'Мониторинг', count: '3', money_fed: '0,21', money_cons: '0,022', money_other: '0', actions: 'Модернизация государственной наблюдательной сети за загрязнением атмосферного воздуха Росгидромета; Техническое перевооружение испытательных лабораторий филиала ФГБУ "ЦЛАТИ"', result: '0', percent: '0',  }, 
    57: { id:57, city_slug: 'city_oms', name: 'Итого', count: '51', money_fed: '6,795', money_cons: '0,0409', money_other: '109,267', actions: '', result: '56,21', percent: '',  }, 
    58: { id:58, city_slug: 'city_med', name: 'Промышленные предприятия', count: '4', money_fed: '0', money_cons: '0', money_other: '0,571', actions: 'Ы', result: '0,032', percent: '0,0044',  }, 
    59: { id:59, city_slug: 'city_med', name: 'Мониторинг', count: '3', money_fed: '0,14', money_cons: '0', money_other: '0', actions: 'Модернизация государственной сети мониторинга загрязнения атмосферного воздуха в г. Медногоске; Развитие в г. Медногорске системы оперативного реагирования на факты высокого и аварийного загрязнения в целях информационного обеспечения органов исполнительной власти региона и населения; Дооснащение стационарной лаборатории ЦЛАТИ и приобретение передвижной лаборатории наблюдений за загрязнением атмосферы ', result: '0', percent: '0',  }, 
    60: { id:60, city_slug: 'city_med', name: 'Транспорт', count: '1', money_fed: '0', money_cons: '0,03', money_other: '0', actions: 'Разработка и реализация программ, направленных на расширение потребления природного газа как моторного топлива; Разввите улично-дорожной сети', result: '0', percent: '0',  }, 
    61: { id:61, city_slug: 'city_med', name: 'Итого', count: '8', money_fed: '0,14', money_cons: '0,03', money_other: '0,571', actions: '', result: '0,032', percent: '',  }, 
}
