import { Component, NgZone } from '@angular/core';
import { StoreService } from 'src/app/store/store.service';
import { StateService } from 'src/app/store/state.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';

@Component({
    selector: 'apm-nothing-page',
    templateUrl: './nothing-page.component.html'
})
export class NothingPage {
    constructor(
        private store: StoreService,
        public state: StateService,
        public zone: NgZone,
        private route: ActivatedRoute,
        private router: Router,
    ){}
}
