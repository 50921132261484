import { Component } from "@angular/core";
import { StateService } from '../../store/state.service';
import { StoreService } from '../../store/store.service';
import { Bundle } from '../../store/types';

@Component({
    selector: 'apm-nav-form',
    templateUrl: './nav-form.component.html',
    host:{
        class:'navigation-wrap__p',
    },
})
export class NavFormComponent{
    private isDragging:boolean = false;
    constructor(
        public state:StateService,
        public store:StoreService,
    ){}

    public onTimelineClick( event:any ){
        let index = Math.round((event.offsetX) / 7) - 1;
        if( index > -1 && index < 72 && this.state.currentPeriod !== index )
            this.state.currentPeriod = index;
    }

    public getBundleTotal( bundle:Bundle ): number {
        return bundle.total instanceof Array ? bundle.total[this.state.currentPeriod] : bundle.total;
    }

    public startDrag(event){
        this.isDragging = true;
        this.onTimelineClick(event);
    }
    public doDrag( event ){
        if( this.isDragging ){
            this.onTimelineClick(event);
        }
    }
    public stopDrag(){
        this.isDragging = false;
    }
}
