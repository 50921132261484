import { Component } from "@angular/core";

@Component({
    selector: 'apm-map-layout',
    templateUrl: './map-layout.component.html',
    // host:{class:'implementing'}
})
export class MapLayoutComponent{
    constructor(){
        
    }
}
