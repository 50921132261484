import { Component, HostBinding, Input } from "@angular/core";
import { StateService } from '../../../store/state.service';

@Component({
    selector: 'apm-menu-layout',
    templateUrl: './menu-layout.component.html',
    host:{class:'b3-p-menu'}, //
})
export class MenuLayoutComponent{
    @HostBinding('class.b3-p-menu--closed')
    @Input() isClosed:boolean;

    constructor(
        public state:StateService
    ){

    }

    public navigate(){}
}
