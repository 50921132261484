export const DCT_CRITERIA = {
    region_summary_status: {slug:'region_summary_status', parent:'', namePlural:'Готовность суъектов РФ', nameSingle:'Готовность суъекта РФ', type:'readiness-stats', ready:69, warning:4, danger:12, min:0, max:0, value:'0', }, 
    tsoo_existance_status: {slug:'tsoo_existance_status', parent:'region_summary_status', namePlural:'Наличие утвержденной территориальной схемы', nameSingle:'Наличие утвержденной территориальной схемы', type:'readiness-stats', ready:83, warning:1, danger:1, min:0, max:0, value:'0', }, 
    ro_existance_status: {slug:'ro_existance_status', parent:'region_summary_status', namePlural:'Количество субъектов РФ, в которых имеются РО', nameSingle:'Наличие РО и охват ими территории субъекта', type:'readiness-stats', ready:75, warning:3, danger:7, min:0, max:0, value:'0', }, 
    ro_start_status: {slug:'ro_start_status', parent:'region_summary_status', namePlural:'Фактическое осуществление деятельности РО', nameSingle:'Фактическое осуществление деятельности РО', type:'readiness-stats', ready:71, warning:3, danger:11, min:0, max:0, value:'0', }, 
    ro_summary_status: {slug:'ro_summary_status', parent:'', namePlural:'Готовность региональных операторов', nameSingle:'Готовность региональных операторов', type:'readiness-stats', ready:44, warning:24, danger:17, min:0, max:0, value:'0', }, 
    transport_status: {slug:'transport_status', parent:'ro_summary_status', namePlural:'Наличие ресурсов для транспортирования', nameSingle:'Наличие ресурсов для транспортирования', type:'readiness-stats', ready:65, warning:8, danger:12, min:0, max:0, value:'0', }, 
    treatment_status: {slug:'treatment_status', parent:'ro_summary_status', namePlural:'Наличие ресурсов для обработки', nameSingle:'Наличие ресурсов для обработки', type:'readiness-stats', ready:43, warning:7, danger:35, min:0, max:0, value:'0', }, 
    utilization_status: {slug:'utilization_status', parent:'ro_summary_status', namePlural:'Наличие ресурсов для утилизации', nameSingle:'Наличие ресурсов для утилизации', type:'readiness-stats', ready:8, warning:7, danger:70, min:0, max:0, value:'0', }, 
    disposal_status: {slug:'disposal_status', parent:'ro_summary_status', namePlural:'Наличие ресурсов для обезвреживания', nameSingle:'Наличие ресурсов для обезвреживания', type:'readiness-stats', ready:9, warning:3, danger:71, min:0, max:0, value:'0', }, 
    placement_status: {slug:'placement_status', parent:'ro_summary_status', namePlural:'Наличие ресурсов для захоронения', nameSingle:'Наличие ресурсов для захоронения', type:'readiness-stats', ready:49, warning:22, danger:14, min:0, max:0, value:'0', }, 
    violations_status: {slug:'violations_status', parent:'', namePlural:'Наличие нарушений в субъектах РФ', nameSingle:'Наличие нарушений в субъекте РФ', type:'readiness-stats', ready:73, warning:9, danger:3, min:0, max:0, value:'0', }, 
    city_dump_status: {slug:'city_dump_status', parent:'', namePlural:'Ликвидация свалок в черте города', nameSingle:'Ликвидация свалок в черте города', type:'readiness-stats', ready:24, warning:21, danger:40, min:0, max:0, value:'0', }, 
}
