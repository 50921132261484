export const REGIONs = [
    { slug:'ALT', name:'Алтайский край', position:'Заместитель Председателя Правительства Алтайского края', fio:'Лукьянов Александр Николаевич', phone:'8 (3852) 36-36-87 ', email:'', quantity:'0', density_min:'0', density_max:'0', norm_min:'1,45', norm_max:'1,45', zones: [1,2,3,4,5,6,7,] }, 
    { slug:'AMU', name:'Амурская область', position:'Заместитель председателя Законодательного Собрания Амурской области', fio:'Дьяконов Константин Викторович', phone:'8 (4162) 22-38-00', email:' zsamur@zsamur.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'0,05', norm_max:'0,11', zones: [8,9,10,11,12,] }, 
    { slug:'ARK', name:'Архангельская область', position:'Заместитель председателя Правительства Архангельской области', fio:'Фоменко Евгений Владимирович', phone:'8 (8182) 28-81-01', email:'', quantity:'575,56313', density_min:'183,52', density_max:'183,52', norm_min:'1,42', norm_max:'2,53', zones: [13,] }, 
    { slug:'AST', name:'Астраханская область', position:'Исполняющий обязанности вице-губернатора — председателя Правительства Астраханской области', fio:'Расул Джанбекович Султанов', phone:'8 (851) 222-15-74', email:'RSultanov@astrobl.ru', quantity:'500,246066', density_min:'153,06', density_max:'153,06', norm_min:'0,7', norm_max:'1,06', zones: [14,15,] }, 
    { slug:'BEL', name:'Белгородская область', position:'Заместитель Губернатора Белгородской области', fio:'Полежаев Константин Алексеевич', phone:'(4722) 32-42-47', email:'admin@belregion.ru ', quantity:'0', density_min:'0', density_max:'0', norm_min:'2,2', norm_max:'2,4', zones: [16,] }, 
    { slug:'BRY', name:'Брянская область', position:'Заместитель губернатора Брянской области', fio:'Мокренко Юрий Васильевич', phone:'(4832) 74-26-60', email:'gubernator@bryanskobl.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'2,03', norm_max:'2,03', zones: [17,18,] }, 
    { slug:'VLA', name:'Владимирская область', position:'Врио заместителя Губернатора Владимирской области', fio:'Байер Александр Александрович', phone:'(4922) 32-50-61', email:'post@avo.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'2,39', norm_max:'2,41', zones: [19,20,21,] }, 
    { slug:'VGG', name:'Волгоградская область', position:'', fio:'', phone:'', email:'', quantity:'1273,394426', density_min:'170,2', density_max:'170,2', norm_min:'2,18', norm_max:'2,47', zones: [22,] }, 
    { slug:'VLG', name:'Вологодская область', position:'Заместитель Губернатора Вологодской области', fio:'Виктор Владимирович Рябишин', phone:'(8172) 23-00-09 ', email:'Pr_Ryabishin@gov35.ru ', quantity:'496,429931', density_min:'130', density_max:'130', norm_min:'1,48', norm_max:'2,66', zones: [23,24,] }, 
    { slug:'VOR', name:'Воронежская область', position:'Заместитель председателя правительства Воронежской области', fio:'Логвинов Виктор Иванович', phone:'8 (473) 212-74-05 ', email:'ogv@govvrn.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'3,1', norm_max:'3,36', zones: [25,26,27,28,29,30,31,32,] }, 
    { slug:'YEV', name:'Еврейская АО', position:'Первый заместитель председателя правительства области', fio:'Сироткин Максим Геннадьевич', phone:'8 (42622) 4-07-30', email:'gov@eao.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'0,2', norm_max:'0,52', zones: [33,] }, 
    { slug:'ZAB', name:'Забайкальский край', position:'Заместитель председателя Правительства Забайкальского края – руководитель Администрации Губернатора Забайкальского края', fio:'Нехаев Сергей Викторович', phone:'7 (3022) 35-38-48 ', email:'adm12@adm.e-zab.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'0,9', norm_max:'0,9', zones: [34,35,] }, 
    { slug:'IVA', name:'Ивановская область', position:'Заместитель Председателя Правительства Ивановской области', fio:'Зобнин Сергей Витальевич', phone:'8 (4932) 32-59-49 ', email:'kazakov_mg@ivanovoobl.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'2,16', norm_max:'2,16', zones: [36,] }, 
    { slug:'IRK', name:'Иркутская область', position:'Первый заместитель Губернатора Иркутской области – Председатель Правительства Иркутской области', fio:'Болотов Руслан Николаевич', phone:'8 (3952) 25-65-75', email:'mail@govirk.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'2,1', norm_max:'2,1', zones: [37,38,] }, 
    { slug:'KGD', name:'Калининградская область', position:'', fio:'', phone:'', email:'', quantity:'444,504231', density_min:'125,93', density_max:'125,93', norm_min:'1,39', norm_max:'1,66', zones: [42,] }, 
    { slug:'KLU', name:'Калужская область', position:'Заместитель Губернатора Калужской области', fio:'Никитенко Алексей Викторович', phone:'7 (4842) 53-10-14 ', email:'admgub@adm.kaluga.ru ', quantity:'599,897218', density_min:'141,6', density_max:'141,6', norm_min:'1,66', norm_max:'2,59', zones: [43,] }, 
    { slug:'KAM', name:'Камчатский край', position:'Заместитель Председателя Правительства Камчатского края', fio:'Суббота Марина Анатольевна', phone:'7 (4152) 42-48-75', email:'41region@kamgov.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'2,5', norm_max:'2,86', zones: [44,] },
    { slug:'KEM', name:'Кемеровская область', position:'Заместитель Губернатора Кемеровской области', fio:'Панов Андрей Анатольевич', phone:'7 (3842) 75-85-50', email:'', quantity:'829,437314', density_min:'119', density_max:'119', norm_min:'2,08', norm_max:'2,08', zones: [46,47,] },
    { slug:'KIR', name:'Кировская область', position:'Заместитель Председателя Правительства Кировской области', fio:'Кадыров Владислав Валерьевич', phone:'7 (8332) 64-33-12', email:'', quantity:'518,334899', density_min:'189', density_max:'189', norm_min:'1,55', norm_max:'1,85', zones: [48,] }, 
    { slug:'KOS', name:'Костромская область', position:'Заместитель губернатора области', fio:'Гальцев Денис Владимирович', phone:'8 (4942) 31-33-03 ', email:'prs@adm44.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'2,18', norm_max:'2,63', zones: [49,50,51,] }, 
    { slug:'KDA', name:'Краснодарский край', position:'Заместитель главы администрации (губернатора) Краснодарского края', fio:'Болдин Сергей Владимирович', phone:'8 (861) 214-22-42', email:'', quantity:'0', density_min:'0', density_max:'0', norm_min:'0,84', norm_max:'1,32', zones: [52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,] },
    { slug:'KYA', name:'Красноярский край', position:'', fio:'', phone:'', email:'', quantity:'0', density_min:'0', density_max:'0', norm_min:'1,25', norm_max:'1,25', zones: [71,72,73,74,75,76,77,78,79,80,81,] }, 
    { slug:'KGN', name:'Курганская область', position:'', fio:'', phone:'', email:'', quantity:'0', density_min:'0', density_max:'0', norm_min:'1,7', norm_max:'1,9', zones: [82,] }, 
    { slug:'KRS', name:'Курская область', position:'Заместитель Губернатора Курской области', fio:'Смирнов Алексей Борисович', phone:'8 (4712) 55-68-29 ', email:'zam.priem@rkursk.ru', quantity:'575,634083', density_min:'175,4', density_max:'175,4', norm_min:'1,14', norm_max:'2,33', zones: [83,84,] }, 
    { slug:'LEN', name:'Ленинградская область', position:'', fio:'', phone:'', email:'', quantity:'711,453054', density_min:'109', density_max:'109', norm_min:'2,53', norm_max:'2,86', zones: [85,] }, 
    { slug:'LIP', name:'Липецкая область', position:'Заместитель главы администрации Липецкой области', fio:'Тагинцев Николай Федорович', phone:'8 (4742) 22-84-23 ', email:'apk@admlr.lipetsk.ru ', quantity:'526,468189', density_min:'116,17', density_max:'116,17', norm_min:'2,2', norm_max:'3,7', zones: [86,87,88,89,] }, 
    { slug:'MAG', name:'Магаданская область', position:'Заместитель Председателя Правительства Магаданской области', fio:'Косов Дмитрий Евгеньевич', phone:'8 (4132) 620415 ', email:'government@49gov.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'3', norm_max:'3,2', zones: [90,91,] }, 
    { slug:'MOW', name:'Москва', position:'Заместитель Мэра Москвы', fio:'Бирюков Петр Павлович', phone:'8 (495) 777-77-77', email:'', quantity:'0', density_min:'0', density_max:'0', norm_min:'0', norm_max:'0', zones: [92,] }, 
    { slug:'MOS', name:'Московская область', position:'Заместитель Председателя Правительства Московской области - министр экологии и природопользования Московской области', fio:'Куракин Дмитрий Александрович', phone:'7 498 602-21-21', email:'minecology@mosreg.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'2,05', norm_max:'2,05', zones: [93,94,95,96,97,98,99,] }, 
    { slug:'MUR', name:'Мурманская область', position:'Первый заместитель Губернатора Мурманской области', fio:'Тюкавин Алексей Михайлович', phone:'7 (8152) 486-204', email:'priemnaya-tyukavina@gov-murman.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'1,8', norm_max:'1,8', zones: [100,] }, 
    { slug:'NEN', name:'Ненецкий АО', position:'Заместитель губернатора Ненецкого автономного округа', fio:'Боенко Сергей Ефимович', phone:'8(1853)2-17-28 ', email:'sboenko@ogvnao.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'1,6', norm_max:'2,66', zones: [101,] }, 
    { slug:'NIZ', name:'Нижегородская область', position:'Заместитель Губернатора, заместитель Председателя Правительства Нижегородской области', fio:'Харин Андрей Николаевич', phone:'7 (831) 439-17-29 ', email:'official@anh.kreml.nnov.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'0,1', norm_max:'2,33', zones: [102,103,104,105,106,107,108,109,110,] }, 
    { slug:'NGR', name:'Новгородская область', position:'Заместитель Председателя Правительства Новгородской области', fio:'Гусев Тимофей Борисович', phone:'8 (8162) 77-36-12 ', email:'tb_gusev@novreg.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'2,07', norm_max:'2,39', zones: [111,112,113,114,] }, 
    { slug:'NVS', name:'Новосибирская область', position:'Заместитель Губернатора Новосибирской области', fio:'Дубовицкий Александр Васильевич', phone:'8(383) 238 6100', email:'', quantity:'0', density_min:'0', density_max:'0', norm_min:'2,38', norm_max:'2,38', zones: [115,] }, 
    { slug:'OMS', name:'Омская область', position:'', fio:'', phone:'', email:'', quantity:'0', density_min:'0', density_max:'0', norm_min:'1,83', norm_max:'2,19', zones: [116,] }, 
    { slug:'ORE', name:'Оренбургская область', position:'Вице-губернатор – заместитель председателя Правительства Оренбургской области по финансово-экономической политике', fio:'Левинсон Наталья Лазаревна', phone:'8 (3532) 78-60-43', email:'office07@mail.orb.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'0', norm_max:'0', zones: [117,] }, 
    { slug:'ORL', name:'Орловская область', position:'Заместитель Председателя Правительства Орловской области', fio:'Злобин Николай Васильевич', phone:'7 (486-2) 47-53-00', email:'post@adm.orel.ru ', quantity:'453,798696', density_min:'134,05', density_max:'134,05', norm_min:'2', norm_max:'2,5', zones: [118,] }, 
    { slug:'PNZ', name:'Пензенская область', position:'Заместитель Председателя Правительства Пензенской области', fio:'Семенов Дмитрий Васильевич', phone:'7 (8412) 59-54-35', email:'', quantity:'609,431282', density_min:'126,44', density_max:'126,44', norm_min:'2,02', norm_max:'2,52', zones: [119,120,121,122,] }, 
    { slug:'PER', name:'Пермский край', position:'', fio:'', phone:'', email:'', quantity:'0', density_min:'0', density_max:'0', norm_min:'0,19', norm_max:'0,2', zones: [123,] }, 
    { slug:'PRI', name:'Приморский край', position:'Врио вице-губернатора Приморского края Костенко Александр Иванович', fio:'Кокшаров Роман Александрович', phone:'8 (423) 220-91-74  ', email:'Kostenko_AI@primorsky.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'0,9', norm_max:'1,6', zones: [124,] }, 
    { slug:'PSK', name:'Псковская область', position:'', fio:'', phone:'', email:'', quantity:'0', density_min:'0', density_max:'0', norm_min:'1', norm_max:'1,44', zones: [125,126,] }, 
    { slug:'AD', name:'Республика Адыгея', position:'Премьер-министр Республики Адыгея', fio:'Наролин Александр Владимирович', phone:'8 (8772) 57-00-22 ', email:'kanc@adm.adygheya.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'1,79', norm_max:'2,11', zones: [127,] }, 
    { slug:'AL', name:'Республика Алтай', position:'Заместитель Председателя Правительства Республики Алтай', fio:'Пильтин Григорий Николаевич', phone:'8 (388-22) 2-56-76 ', email:'root@apra.gorny.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'1,43', norm_max:'1,64', zones: [128,129,130,] }, 
    { slug:'BA', name:'Республика Башкортостан', position:'И.о. заместителя Премьер-министра Правительства Республики Башкортостан', fio:'Фазрахманов Ильшат Ильдусович', phone:'7 (347) 280-83-08', email:'priemnaya437@bashkortostan.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'1,92', norm_max:'1,95', zones: [131,132,133,134,135,] }, 
    { slug:'BU', name:'Республика Бурятия', position:'', fio:'', phone:'', email:'', quantity:'0', density_min:'0', density_max:'0', norm_min:'1,99', norm_max:'2,27', zones: [136,137,138,] }, 
    { slug:'DA', name:'Республика Дагестан', position:'', fio:'', phone:'', email:'', quantity:'0', density_min:'0', density_max:'0', norm_min:'1,5', norm_max:'1,5', zones: [139,140,141,142,143,144,] }, 
    { slug:'IN', name:'Республика Ингушетия', position:'', fio:'', phone:'', email:'', quantity:'0', density_min:'0', density_max:'0', norm_min:'2,2', norm_max:'3', zones: [145,] }, 
    { slug:'KB', name:'Республика Кабардино-Балкария', position:'', fio:'', phone:'', email:'', quantity:'0', density_min:'0', density_max:'0', norm_min:'2,99', norm_max:'3,17', zones: [39,40,41,] }, 
    { slug:'KL', name:'Республика Калмыкия', position:'Заместитель Председателя Правительства Республики Калмыкия', fio:'Бадинов Геннадий Константинович', phone:'(84722) 4-06-47', email:'        gov2@rk08.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'1,4', norm_max:'2,4', zones: [146,] }, 
    { slug:'KC', name:'Республика Карачаево-Черкесия', position:'Первый Заместитель председателя Правительства КЧР', fio:'Байчоров Эльдар Пазлиевич ', phone:'(8782) 26-66-71', email:'pressinfo@bk.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'2,52', norm_max:'3,25', zones: [45,] }, 
    { slug:'KR', name:'Республика Карелия', position:'Заместитель Премьер-министра Правительства Республики Карелия', fio:'Родионов Дмитрий Андреевич', phone:'(814-2) 79-93-09', email:'government@karelia.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'1,89', norm_max:'2,09', zones: [147,] }, 
    { slug:'KO', name:'Республика Коми', position:'', fio:'', phone:'', email:'', quantity:'0', density_min:'0', density_max:'0', norm_min:'1,9', norm_max:'1,9', zones: [148,] }, 
    { slug:'CR', name:'Республика Крым', position:'Заместитель Председателя Совета министров Республики Крым', fio:'Гоцанюк Юрий Михайлович', phone:'(3652) 51-01-05', email:'sovmin@rk.gov.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'2,6', norm_max:'2,6', zones: [149,150,] }, 
    { slug:'ME', name:'Республика Марий Эл', position:'Первый заместитель Председателя Правительства Республики Марий Эл', fio:'Сметанин Сергей Алексеевич', phone:'(8362) 64-16-03', email:'smetanin@gov.mari.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'1,85', norm_max:'1,92', zones: [151,152,] }, 
    { slug:'MO', name:'Республика Мордовия', position:'', fio:'', phone:'', email:'', quantity:'0', density_min:'0', density_max:'0', norm_min:'2,03', norm_max:'2,2', zones: [153,] }, 
    { slug:'SA', name:'Республика Саха (Якутия)', position:'Заместитель Председателя Правительства Республики Саха (Якутия)', fio:'Белозеров Денис Георгиевич', phone:'8 (4112) 508-445', email:'adm@adm.sakha.gov.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'1,78', norm_max:'1,86', zones: [154,155,156,157,] }, 
    { slug:'SE', name:'Республика Северная Осетия', position:'', fio:'', phone:'', email:'', quantity:'0', density_min:'0', density_max:'0', norm_min:'1,5', norm_max:'1,8', zones: [158,159,] }, 
    { slug:'TA', name:'Республика Татарстан', position:'', fio:'', phone:'', email:'', quantity:'1612,863322', density_min:'129,6185', density_max:'129,6185', norm_min:'2,13', norm_max:'2,69', zones: [160,161,] }, 
    { slug:'TY', name:'Республика Тыва', position:'Первый заместитель Председателя Правительства Республики Тыва - министр природных ресурсов и экологии Республики Тыва', fio:'Хопуя Шолбан Ховалыгович', phone:'(39422) 2-26-51', email:'Hopuysh@rtyva.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'1,4', norm_max:'1,9', zones: [162,] }, 
    { slug:'UD', name:'Республика Удмуртия', position:'', fio:'', phone:'', email:'gov@udmurt.ru', quantity:'492,949818', density_min:'123,27', density_max:'123,27', norm_min:'1,68', norm_max:'9,72', zones: [163,] }, 
    { slug:'KK', name:'Республика Хакасия', position:'', fio:'', phone:'', email:'', quantity:'0', density_min:'0', density_max:'0', norm_min:'1,85', norm_max:'1,85', zones: [164,165,166,167,168,] }, 
    { slug:'CE', name:'Республика Чечня', position:'', fio:'', phone:'', email:'', quantity:'0', density_min:'0', density_max:'0', norm_min:'1,84', norm_max:'1,86', zones: [169,] }, 
    { 
        slug:'CU',
        name:'Республика Чувашия',
        position:'Председатель Кабинета Министров Чувашской Республики',
        fio:'Моторин Иван Борисович',
        phone:'8 (8352) 62-01-71',
        email:'km@cap.ru',
        quantity:'0',
        density_min:'0',
        density_max:'0',
        norm_min:'2,02',
        norm_max:'2,02',
        zones: [170,]
    }, 
    { slug:'ROS', name:'Ростовская область', position:'Первый заместитель Губернатора Ростовской области', fio:'Гончаров Виктор Георгиевич', phone:'8 (863) 262-75-12 ', email:'', quantity:'0', density_min:'0', density_max:'0', norm_min:'1,86', norm_max:'3,08', zones: [171,172,173,174,175,176,177,178,] }, 
    { slug:'RYA', name:'Рязанская область', position:'Заместитель Председателя Правительства Рязанской области', fio:'Артемов Виталий Евгеньевич', phone:'(4912) 29-04-20', email:'postmaster@adm1.ryazan.su', quantity:'0', density_min:'0', density_max:'0', norm_min:'2,28', norm_max:'2,31', zones: [179,] }, 
    { slug:'SAM', name:'Самарская область', position:'Заместитель председателя Правительства Самарской области', fio:'Карпушкин Александр Викторович', phone:'	8 (846) 332-17-67', email:'info@samregion.ru', quantity:'1794,52236', density_min:'150', density_max:'150', norm_min:'1,62', norm_max:'1,62', zones: [182,] },
    { slug:'SPE', name:'Санкт-Петербург', position:'Вице-губернатор Санкт-Петербурга', fio:'Кириллов Владимир Владимирович', phone:'8 (812) 576-6685 ', email:'adm@gov.spb.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'2,06', norm_max:'3,96', zones: [180,181,] }, 
    { slug:'SAR', name:'Саратовская область', position:'Заместитель Председателя Правительства Саратовской области', fio:'Стрельников Алексей Владимирович', phone:'8 (8452) 21-02-02', email:'', quantity:'0', density_min:'0', density_max:'0', norm_min:'1,3', norm_max:'2,4', zones: [183,184,] }, 
    { slug:'SAK', name:'Сахалинская область', position:'И.о. председателя Правительства Сахалинской области', fio:'Белик Алексей Васильевич', phone:'(4242) 670-172', email:'pso@sakhalin.gov.ru ', quantity:'0', density_min:'0', density_max:'0', norm_min:'2,1', norm_max:'2,1', zones: [185,] }, 
    { slug:'SVE', name:'Свердловская область', position:'', fio:'', phone:'', email:'', quantity:'0', density_min:'0', density_max:'0', norm_min:'2,03', norm_max:'2,28', zones: [186,187,188,] }, 
    { slug:'SEV', name:'Севастополь', position:'И.о. заместителя Губернатора – Председателя Правительства Севастополя', fio:'Шишкин Андрей Николаевич', phone:'8(3692)54-55-28 ', email:'pravitelstvo@sevastopol.gov.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'2,5', norm_max:'2,7', zones: [189,] }, 
    { slug:'SMO', name:'Смоленская область', position:'Заместитель Губернатора Смоленской области', fio:'Пучков Юрий Николаевич', phone:'8 (4812)  29-20-32', email:' puchkov_yn@admin-smolensk.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'1,82', norm_max:'1,82', zones: [190,] }, 
    { slug:'STA', name:'Ставропольский край', position:'', fio:'', phone:'', email:'', quantity:'0', density_min:'0', density_max:'0', norm_min:'2,5', norm_max:'2,8', zones: [191,192,193,194,] }, 
    { slug:'TAM', name:'Тамбовская область', position:'Заместитель главы администрации Тамбовской области', fio:'Чулков Глеб Игоревич', phone:'7 (4752) 79-20-19', email:'post@post.tambov.gov.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'2,08', norm_max:'2,74', zones: [195,] }, 
    { slug:'TVE', name:'Тверская область', position:'Заместитель Председателя Правительства Тверской области', fio:'Наумов Андрей Викторович', phone:'7(4822) 73-31-73', email:'region@tverreg.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'1,82', norm_max:'2,19', zones: [196,] }, 
    { slug:'TOM', name:'Томская область', position:'Заместитель Губернатора Томской области', fio:'Кнорр Андрей Филиппович', phone:'8 (3822) 510-430', email:'pr-kaf@tomsk.gov.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'0,89', norm_max:'2,96', zones: [197,198,199,200,201,202,203,204,] }, 
    { slug:'TUL', name:'Тульская область', position:'Заместитель Губернатора Тульской области', fio:'Егоров Сергей Николаевич', phone:'8 (4872) 24-98-24', email:'info@tularegion.ru', quantity:'1027,78393', density_min:'164,47', density_max:'164,47', norm_min:'2,52', norm_max:'3,36', zones: [205,206,] }, 
    { slug:'TYU', name:'Тюменская область', position:'Заместитель Губернатора Тюменской области', fio:'Теплоухова Лариса Зельмухановна', phone:'(3452) 42-71-34', email:'kancelaria@72to.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'2,57', norm_max:'2,74', zones: [207,] }, 
    { slug:'ULY', name:'Ульяновская область', position:'', fio:'', phone:'', email:'', quantity:'0', density_min:'0', density_max:'0', norm_min:'2,3', norm_max:'2,33', zones: [208,209,210,211,212,] }, 
    { slug:'KHA', name:'Хабаровский край', position:'', fio:'', phone:'', email:'', quantity:'0', density_min:'0', density_max:'0', norm_min:'2,36', norm_max:'3,97', zones: [213,] }, 
    { slug:'KHM', name:'ХМАО - Югра', position:'Заместитель Губернатора Хант-Мансийского автономного округа-Югры', fio:'Забозлаев Алексей Геннадьевич', phone:'8 (3467)39-20-46', email:'gov@admhmao.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'1,83', norm_max:'3,29', zones: [214,215,] }, 
    { slug:'CHE', name:'Челябинская область', position:'Заместитель Губернатора Челябинской области', fio:'Тефтелев Евгений Николаевич', phone:'(351) 263-33-10 ', email:'pr08@gov74.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'1,61', norm_max:'2,09', zones: [216,217,218,219,220,] }, 
    { slug:'CHU', name:'Чукотский АО', position:'Заместитель Губернатора, заместитель председателя Правительства Чукотского автономного округа, начальник Департамента сельскохозяйственной политики и природопользования Чукотского автономного округа', fio:'Алексеенко Валентина Федоровна', phone:'(42722) 6-35-31 ', email:'', quantity:'0', density_min:'0', density_max:'0', norm_min:'0,9', norm_max:'1,2', zones: [221,222,223,224,225,226,227,228,229,] }, 
    { slug:'YAN', name:'Ямало-Ненецкий АО', position:'Первый заместитель Губернатора Ямало-Ненецкого автономного округа', fio:'Ситников Алексей Викторович', phone:'8 (34922) 2-27-10', email:'avsitnikov@yanao.ru', quantity:'0', density_min:'0', density_max:'0', norm_min:'2,41', norm_max:'3,03', zones: [230,] }, 
    { slug:'YAR', name:'Ярославская область', position:'Заместитель Председателя Правительства Ярославской области', fio:'Колесов Роман Андреевич', phone:'(4852) 40-18-46', email:'', quantity:'651,821781', density_min:'126,44', density_max:'126,44', norm_min:'2,08', norm_max:'2,46', zones: [231,] }
]
