import { Component, ElementRef, ViewChild, EventEmitter, Output, NgZone } from '@angular/core';
import { Project, Point } from 'paper';
declare const Hammer: any;

export class PaperEvent<T = any>{
    constructor( public paper:PaperComponent, public nativeEvent:T, public tapPoint? ){}
}

export class PaperTapEvent<T = any> extends PaperEvent<T>{
    constructor( public tapPoint:Point, public paper:PaperComponent, public nativeEvent:T ){ super(paper, nativeEvent); }
}

@Component({
    selector:'apm-paper',
    template:'<canvas #canvasElement></canvas>'
})
export class PaperComponent{
    @ViewChild('canvasElement') canvasElement: ElementRef;
    @Output() paperTap = new EventEmitter<PaperEvent>();
    
    public project;
    public projectView;
    public element;

    constructor(
        private elemRef: ElementRef,
        private zone: NgZone,
    ){}

    ngOnInit(){
        // el.width = original_size.width * scale;
        // el.height = original_size.height * scale;
        this.element = this.canvasElement.nativeElement;
        this.project = new Project(this.element);
        this.projectView = this.project.view;
        console.log('[DEV] InitPaper');
        
        // this.zone.runOutsideAngular(() => {
        //     this.initHandlers();
        // });
    }

    // private initHandlers() {
    //     let hammer = new Hammer(this.canvasElement.nativeElement);
    //     // var singleTap = new Hammer.Tap({ event: 'singletap' });
    //     // var doubleTap = new Hammer.Tap({event: 'doubletap', taps: 2 });
    //     // hammer.get('pinch').set({ enable: true });
    //     // hammer.on('tap', event => this.paperTap.emit(new PaperEvent(this, event)))
    //     // hammer.on('singletap', event => this.paperTap.emit(new PaperEvent(this, event)))
        
    //     this.canvasElement.nativeElement.addEventListener('click', event => this.paperTap.emit(new PaperEvent(this, event)) );
    // }
}
