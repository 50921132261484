import { Component, OnInit } from '@angular/core';
import { StateService } from 'src/app/store/state.service';
import { StoreService } from 'src/app/store/store.service';
import { REGIONs } from 'src/app/store/regions';
import { VIOLATIONS_DCT } from 'src/app/store/violations_dct';

@Component({
    selector: 'apm-readiness-table',
    templateUrl: './readiness-table.component.html',
    styleUrls: ['./readiness-table.component.scss']
})
export class ReadinessTableComponent implements OnInit {
    public regions;

    private violationsSctValues = Object.values(VIOLATIONS_DCT);

    private sortKey = '';
    private sortDir = 0;

    private sortIndexes = {
        danger: 0,
        warning: 1,
        ready: 2
    };

    private _shownV;

    constructor (
        public store: StoreService,
        public state: StateService,
    ) {
        this.calcRows();
    }

    ngOnInit() {
        this.state.adjustSectionTo('table');
    }

    public showAnnotation( v ){
        this._shownV = v;
    }
    public hideAnnotation(){
        this._shownV = null;
    }
    public isAnnotationShown( v ){
        return this._shownV === v;
    }

    sort(status) {
        if (this.sortKey === status) {
            this.sortDir = this.sortDir === 0 ? 1 : 0;
        } else {
            this.sortKey = status;
            this.sortDir = 0;
        }
        this.calcRows();
    }

    typography(text: string): string {
        // ставим неразрывный пробел после 1- и 2-символьных слов
        return text.replace(/(^|\s)([\wа-я]{1,2}) /ig, '$1$2\u00A0');
    }

    private calcRows() {
        let sorter;
        if (!this.sortKey) {
            sorter = (a, b) => {
                return a.name.localeCompare(b.name);
            };
        } else {
            sorter = (a, b) => {
                if( typeof a.status[this.sortKey] === 'number' ) return a.status[this.sortKey] - b.status[this.sortKey]
                const ai = this.sortIndexes[a.status[this.sortKey]];
                const bi = this.sortIndexes[b.status[this.sortKey]];
                return ai - bi;
            };
        }

        this.regions = REGIONs
            .map((region) => this.calcRow(region))
            .sort(sorter);

        if (this.sortDir) {
            this.regions.reverse();
        }
    }

    private calcRow(region) {
        const vialotions = this.violationsSctValues
            .map((vt) => {
                const vd = this.store.getStoredValue_region(vt.slug, region.slug);
                let icon;
                switch (vt.slug) {
                    case 'vTransport': icon = 'asuicon asuicon_suspend-1'; break;
                    case 'vLicence': icon = 'asuicon asuicon_license2'; break;
                    case 'vScheme': icon = 'asuicon asuicon_page-break2'; break;
                    case 'vInvent': icon = 'asuicon asuicon_crop'; break;
                    case 'vGroro': icon = 'asuicon asuicon_contract-refused-1'; break;
                    case 'vEmployee': icon = 'asuicon asuicon_user-waiiting'; break;
                    case 'vAir': icon = 'asuicon asuicon_cloud-alert'; break;
                    case 'vWater': icon = 'asuicon asuicon_drop-crossed'; break;
                    case 'vLand': icon = 'asuicon asuicon_road-sign'; break;
                    case 'vDoc': icon = 'asuicon asuicon_clipboard-alert'; break;
                }
                return { ...vt, ...vd, icon };
            })
            .filter(data => data.count);

        const result = {
            name: region.name,
            summaryStatus: 'ready',
            status: {
                tsoo_existance_status: this.store.getStoredValue_region('tsoo_existance_status', region.slug),
                ro_existance_status: this.store.getStoredValue_region('ro_existance_status', region.slug),
                ro_start_status: this.store.getStoredValue_region('ro_start_status', region.slug),
                transport_status: this.store.getStoredValue_region('transport_status', region.slug),
                treatment_status: this.store.getStoredValue_region('treatment_status', region.slug),
                utilization_status: this.store.getStoredValue_region('utilization_status', region.slug),
                disposal_status: this.store.getStoredValue_region('disposal_status', region.slug),
                placement_status: this.store.getStoredValue_region('placement_status', region.slug),
                city_dump_terminated: this.store.getStoredValue_region('city_dump_terminated', region.slug),
                city_dump_count: this.store.getStoredValue_region('city_dump_count', region.slug),
            },
            vialotions
        };

        const values = Object.values(result.status);
        if (values.some((v) => v === 'danger')) {
            result.summaryStatus = 'danger';
        }
        if (values.some((v) => v === 'warning')) {
            result.summaryStatus = 'warning';
        }
        return result;
    }
}
