import { Injectable, NgZone } from '@angular/core';
import { take, map, tap, switchMap } from 'rxjs/operators';
import { StoreService } from './store.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { Region, RZone, City, Bundle, Organization, Plan, INav } from './types';
import { REGIONs } from './regions';
import { Color } from 'paper';
import { AMDevice } from '../am/device';

const TOP_LENGTH = 3;
const CLRS = {
    'none': '#A3AFB7', //'C4C4C4'
    'ready': '#28bd8b',
    'warning': '#ffae36',
    'danger': '#ea5a5a',
    'extreme': '#2F80ED',
};

@Injectable()
export class StateService {
    public get isSidecardShown(): boolean {
        switch ( this.sideEntityKey ) {
            case 'region': return !!this.currentRegion;
            case 'zone': return !!this.currentZone;
            case 'city': return !!this.currentCity;
            case 'plan': return !!this.currentPlan;
            case 'org': return !!this.currentOrg;
            default: return false;
        }
    }
    public get isPopupcardShown(): boolean {
        switch ( this.mainEntityKey ) {
            case 'region': return !!this.currentRegion;
            case 'city': return !!this.currentCity;
            default: return false;
        }
    }

    public get currentPeriod(): number { return this._currentPeriod; }

    constructor(
        private zone: NgZone,
        private store: StoreService,
    ) {
        // this.currentSlice = 'test';// this.store.dataBundles[0].slug;
        // this.modifyStore();
        // this.push();
    }

    public set currentPeriod( value: number ) {
        this._currentPeriod = value;
        // this.modifyStore();
        this.push();
    }

    public get currentPeriodHtml() { return this.store.dct_period[this.currentPeriod]; }
    public get currentPeriodName() { return this.store.periodDct[this.currentPeriod].name; }

    public get currentSliceName() {      return this.currentSlice && this.store.dataBundlesDct[this.currentSlice] ? this.store.dataBundlesDct[this.currentSlice].name : null; }
    public get currentSliceMin() {       return this.currentSlice && this.store.dataBundlesDct[this.currentSlice] ? this.store.dataBundlesDct[this.currentSlice].min : 0; }
    public get currentSliceMax() {       return this.currentSlice && this.store.dataBundlesDct[this.currentSlice] ? this.store.dataBundlesDct[this.currentSlice].max : 999; }
    public get currentTotalDimension() { return this.currentSlice && this.store.dataBundlesDct[this.currentSlice] ? this.store.dataBundlesDct[this.currentSlice].dimension : 0; }
    public get currentTotalValue() {
        const total = this.currentSlice && this.store.dataBundlesDct[this.currentSlice] ? this.store.dataBundlesDct[this.currentSlice].total : 0;
        return total instanceof Array ? total[this.currentPeriod] : total;
    }

    public get storedValueForCurrentRegion(): any {
        if ( this.currentSlice && this.currentRegion ) {
            return this.store.getStoredValue_region( this.currentSlice, this.currentRegion.slug, this.currentPeriod );
        } else { return '...'; }
    }

    public slices = [];

    public currentSlice: string;
    public currentBundle: Bundle;

    public readiness = {
        currentSlice: null,
        total_ready: 0,
        total_warning: 0,
        total_danger: 0,
    };
    public freshair = {
        currentSlice: null,
        currentPeriod: 0,
    };

    public isDashboardWide = false;
    public hasDashboard = false;
    public isMenuShown = false;
    public isSlicesListShown = false;
    public isTimelineShown = false;
    public isTooltipOld = true;

    public mainEntityKey: string = null;
    public mainSectionKey: string = null;
    public mainTitle: string = 'Сводная информация';
    public sideEntityKey: string = null;
    public sideSectionKey: string = null;
    public isPlaying = false;
    public _currentPeriod = 0;
    public currentRegion: Region;
    public currentCity: any;
    public currentPlan: any;
    public currentOrg: Organization;
    public currentZone: RZone;
    public currentX = '0';
    public currentY = '0';
    public currentSide = 'left';

    public top_regions_max: Region[];
    public top_regions_min: Region[];

    private orderDct_region = {};

    public state$: BehaviorSubject<void> = new BehaviorSubject(null);

    private _sideCardsPanelsState: {[panelKey: string]: {isOpenned: boolean}} = {
        'info': {
            isOpenned: true,
        },
        'table': {
            isOpenned: true,
        },
        'org_info': {
            isOpenned: true,
        },
        'polution_info': {
            isOpenned: true,
        },
    };

    private timerId;

    private navStencils:INav[] = [{
        key: 'map',
        makeLink: section =>`/at/${section}/map`,
        iconClass: 'icon icon_map2',
    }, {
        key: 'table',
        makeLink: section =>`/at/${section}/table`,
        iconClass: 'asuicon asuicon_excell-1',
    }]

    public navList:INav[] = [];

    private sectionsOptions = {
        statustko: {
            mainEntityKey: 'region',
            bundleKey: 'statustko',
            title: 'Статус реформы ТКО',
            isCommonRoute: true,
            sub:{
                map:{
                    hasDashboard: true,
                    hasTimeline: false,
                },
                table: {}
            },
        },
        twotp: {
            mainEntityKey: 'region',
            bundleKey: 'twotp',
            title: 'Отчетность 2-ТП',
            isCommonRoute: true,
            sub:{
                map:{
                    hasDashboard: true,
                    hasTimeline: false,
                },
                table: {}
            },
        },
        readiness: {
            mainEntityKey: 'region',
            bundleKey: 'readiness',
            title: 'Ситуация по готовности к переходу',
            isCommonRoute: true,
            sub:{
                map:{
                    hasDashboard: true,
                    hasTimeline: false,
                },
                table: {}
            },
        },
        treatment: {
            mainEntityKey: 'region',
            bundleKey: 'treatment',
            hasOldTooltip: true,
            title: 'Ситуация по обращению с отходами',
            isCommonRoute: true,
            sub:{
                map:{
                    hasDashboard: true,
                    hasTimeline: true,
                },
            },
        },
        freshair: {
            mainEntityKey: 'city',
            bundleKey: 'freshair',
            title: 'Чистый воздух',
            isCommonRoute: false,
            navList: [],
            sub:{
                map:{
                    hasDashboard: true,
                    hasTimeline: true,
                },
            },
        },
    };

    public hasSectionSubWithKey( key:string ): boolean {
        return this.sectionsOptions[this.mainSectionKey]
            && this.sectionsOptions[this.mainSectionKey].sub
            && this.sectionsOptions[this.mainSectionKey].sub[key];
    }

    public getSectionNav():INav[]{
        let o = this.sectionsOptions[this.mainSectionKey];
        if( o.isCommonRoute )
            return this.navStencils
                .filter( item => this.hasSectionSubWithKey(item.key) )
                .map( item =>{ item.link = item.makeLink(this.mainSectionKey); return item; });
        else return o.navList;
    }

    public openSidecard( o: {
        type: string,
        region?: Region,
        city?: City,
        zone?: RZone,
        plan?: Plan,
        org?: Organization,
        sectionKey?: string
    } ) {
        this.sideEntityKey = o.type;
        this.sideSectionKey = o.sectionKey || this.mainSectionKey;
        switch ( o.type ) {
            case 'region':
                this.currentRegion = o.region || this.currentRegion;
                break;
            case 'zone':
                this.currentZone = o.zone;
                break;
            case 'city':
                this.currentCity = o.city;
                break;
            case 'plan':
                this.currentPlan = o.plan;
                break;
            case 'org':
                this.currentOrg = o.org;
                break;
            default: return false;
        }

    }

    public closeSidecard() {
        this.sideEntityKey = null;
    }
    public push(){
        this.modifyStore();
        this.state$.next();
    }

    public getStoredValueForRegion( region ): any {
        switch( this.mainEntityKey ){
            case 'region':
                if( this.currentSlice && region )
                    return this.store.getStoredValue_region( this.currentSlice, (typeof region === 'string' ? region : region.slug), this.currentPeriod );
                else return 0;
            case 'city':
                if( this.currentSlice && region )
                    return this.store.getStoredValue_region( this.currentSlice, (typeof region === 'string' ? region : region.slug), this.currentPeriod );
                else return 0;
            default: return 0;
        }
    }

    public getBundleDimensionLabel( bundle: Bundle = this.currentBundle ) {
        switch ( bundle.type ) {
            case 'ktonn': return 'тыс.т';
            case 'percent': return '%';
            case 'quantity': return 'шт';
            default: return '';
        }
    }
    public getStoredValueForCity( city: City ): any {
        if ( this.currentSlice && city ) {
            return this.store.getStoredValue_region( this.currentSlice, (typeof city === 'string' ? city : city.slug), this.currentPeriod );
        } else { return 0; }
    }

    public getForeColorForRegion( region ): any {
        const value = this.getStoredValueForRegion(region);
        const min = this.currentBundle.min || this.currentSliceMin || 0;
        const max = this.currentBundle.max || this.currentSliceMin || 0;
        if( max ){
            const ratio = 1 - (Math.min(value, max) - min) / (max - min);
            return ratio > 0.5 ? 'black' : 'white';
        }else return 'black';
    }

    public getColorForRegion( region ): any {
        if( !this.currentBundle ) return 'white';
        const min = this.currentBundle.min || this.currentSliceMin || 0;
        const max = this.currentBundle.max || this.currentSliceMin || 0;
        let value;
        switch( this.currentBundle.type ){
            case 'quantitative':
                value = this.getStoredValueForRegion(region) || 0;
                break;
            default:
                value = this.getStoredValueForRegion(region);
                break;
        }
        switch ( this.currentBundle.type ) {
            case 'readiness-stats':
                // if({
                //     MOW:true,
                //     SPE:true,
                // }[ region ]) return CLRS.extreme;
                // else
                return CLRS[value] || 'red';
            default:
                // console.log('getColorForRegion', region, this.currentBundle, max, min, value, '->', 1 - (Math.min(value, max) - min) / (max - min));
                if( max ){
                    const ratio = 1 - (Math.min(value, max) - min) / (max - min);
                    let r = (50 + (202 - 50) * ratio) / 255;
                    let g = (98 + (219 - 98) * ratio) / 255;
                    let b = (153 + (237 - 153) * ratio) / 255;
                    if( r < 0 || r > 1 ) r = 1;
                    if( g < 0 || g > 1 ) g = 1;
                    if( b < 0 || b > 1 ) b = 1;
                    return ratio === 1
                        ? 'white'
                        : new Color(r, g, b).toCSS(false);
                }else return 'white';
        }
    }

    public selectBundle( bundle ) {
        this.currentSlice = bundle.slug;
        // this.modifyStore();
        this.push();
    }

    public showCity = () => {

    }

    public showRegion = ( region, point, deirection?: 'left'|'right' ) => {
        this.showPopup({ region, point });
        // let x = point.x;
        // let y = point.y;
        // let side = x > 400 ? 'right' : 'left';
        // if( y < 100 ) y = 100;
        // this.zone.run(() => {
        //     this.currentZone = null;
        //     this.currentRegion = region;
        //     this.currentY = `${y+63}px`;
        //     this.currentX = side === 'right' ? `${x-410}px` : `${x+10}px`;
        //     this.currentSide = side;
        // });
        // console.log('[DEV] position delta', {dx: point.x - x, dy: point.y - y})
        // return {dx: point.x - x, dy: point.y - y};
        // this.cdr.detectChanges();
    }

    public showPopup(o: { point?, region?, zone?, city? }) {
        console.log('[DEV] show popup', o);
        const x = o.point.x;
        let y = o.point.y;
        const side = x > 400 ? 'right' : 'left';
        if ( y < 100 ) { y = 100; }
        this.zone.run(() => {
            this.currentZone = o.zone;
            if ( o.city ) {
                this.currentPlan = null;
                this.currentOrg = null;
            }
            this.currentCity = o.city;
            this.currentRegion = o.region;
            this.currentY = `${y + 63}px`;
            this.currentX = side === 'right' ? `${x - 410}px` : `${x + 10}px`;
            this.currentSide = side;
        });
        // console.log('[DEV] position delta', {dx: o.point.x - x, dy: po.oint.y - y})
        return {dx: o.point.x - x, dy: o.point.y - y};
    }

    public hideRegion() {
        if ( this.currentRegion ) {
            this.zone.run(() => {
                this.currentRegion = null;
                // this.push();
            });
        }
    }

    public showZone = ( zone ) => {
        console.log('[DEV] zone', zone);
        this.zone.run(() => {
            this.currentZone = zone;
        });
    }

    public hideZone() {
        if ( this.currentZone ) {
            this.zone.run(() => {
                this.currentZone = null;
                // this.push();
            });
        }
    }

    private nullifyTemps() {
        this.isMenuShown = false;
        this.isDashboardWide = false;
        this.isSlicesListShown = false;
        this.sideEntityKey = null;
        this.currentZone = null;
        this.currentRegion = null;
        this.currentCity = null;
    }

    public hideAll() {
        console.log('[DEV] hideAll');
        this.zone.run(() => this.nullifyTemps() );
    }

    public adjustSectionTo( viewKey:string ){
        console.log('[DEV] adjustSectionTo', viewKey);
        
        this.zone.onStable.pipe(
            take(1),
            tap(()=> this.zone.run(() => {
                if( this.mainSectionKey
                    && this.sectionsOptions[this.mainSectionKey]
                    && this.sectionsOptions[this.mainSectionKey].sub
                    && this.sectionsOptions[this.mainSectionKey].sub[viewKey]
                ){
                    this.hasDashboard = this.sectionsOptions[this.mainSectionKey].sub[viewKey].hasDashboard;
                    this.isTimelineShown = this.sectionsOptions[this.mainSectionKey].sub[viewKey].hasTimeline;
                }else{
                    this.hasDashboard = false;
                    this.isTimelineShown = false;
                }
            }))
        ).subscribe();
    }

    public resetStateTo( sectionKey ) {
        const o = this.sectionsOptions[sectionKey];
        console.log('[DEV] resetStateTo', sectionKey, o);
        this.zone.run(() => {
            this.nullifyTemps();
            this.mainSectionKey = sectionKey;
            this.mainEntityKey = o.mainEntityKey;
            this.isTimelineShown = false;
            this.mainTitle = o.title;
            this.isTooltipOld = o.hasOldTooltip;
            this.store.defineBundles(o.bundleKey);
            this.slices = this.store.dataBundles;
            this.setCurrentSlice(this.slices ? this.slices[0] : null);
            this.navList = this.getSectionNav();
            console.log('[DEV] initNav', this.mainSectionKey, this.navList);
        });
    }

    public hideAll$(): Observable<boolean> {
        console.log('[DEV] hideAll$');
        this.hideAll();
        return this.zone.onStable.pipe(
            take(1),
            map(() => {
                console.log('[DEV] sideEntityKey = null');
                return true;
            }),
        );
    }

    public toggleDashboard() {
        this.zone.run(() => this.isDashboardWide = !this.isDashboardWide );
    }
    public toggleMenu() {
        this.zone.run(() => this.isMenuShown = !this.isMenuShown );
    }
    public toggleSlicesList() {
        this.zone.run(() => this.isSlicesListShown = !this.isSlicesListShown );
    }

    public setCurrentSlice( slice: Bundle ) {
        if ( (typeof slice === 'string') && this.slices ) {
            slice = this.slices.find(bundle => bundle.slug === slice) || slice;
            // console.log('slice', slice, this.slices);
        }
        this.currentBundle = slice;
        this.currentSlice = slice ? slice.slug : null;
        this.push();
    }

    public togglePanel( panelKey: string ): void {
        this._sideCardsPanelsState[panelKey].isOpenned = !this._sideCardsPanelsState[panelKey].isOpenned;
    }

    public isPanelOpenned(panelKey: string ): boolean {
        return this._sideCardsPanelsState[panelKey].isOpenned;
    }

    public togglePlay() {
        this.isPlaying ? this.stopPlay() : this.startPlay();
    }

    public startPlay() {
        if ( this.currentPeriod >= 71 ) { this.currentPeriod = 0; }
        this.isPlaying = true;
        this.tick();
    }

    public stopPlay() {
        this.isPlaying = false;
        this.removeTimer();
    }

    public safeTick() {
        this.removeTimer();
        if ( this.zone.isStable ) { this.tick(); } else {
            this.zone.onStable.pipe( take(1) ).subscribe(() => this.tick() );
        }
    }

    public incrementPeriod() {
        if ( this.currentPeriod >= 71 ) {
            this.isPlaying = false;
        } else {
            this.currentPeriod++;
            // this.modifyStore();
            this.push();
        }
    }

    public getRegionOrder( regionCode: string ) { return this.orderDct_region[regionCode] || 0; }

    private tick() {
        this.timerId = setTimeout(() => {
            this.incrementPeriod();
            if ( this.isPlaying ) { this.tick(); }
            delete this.timerId;
        }, 200);
    }

    private removeTimer() {
        if ( this.timerId ) {
            clearTimeout(this.timerId);
            delete this.timerId;
        }
    }

    private modifyStore() {
        let dct = {};
        switch( this.mainEntityKey ){
            case 'region': dct = this.store.regionsDct; break;
            case 'city': dct = this.store.citiesDct; break;
        }
        const sortedRegions = Object.keys(dct).sort(
            ( regionCodeA: string, regionCodeB: string ) =>
                this.store.getStoredValue_region( this.currentSlice, regionCodeB, this.currentPeriod )
                - this.store.getStoredValue_region( this.currentSlice, regionCodeA, this.currentPeriod )
        ).filter( regionCode => !!this.store.getStoredValue_region( this.currentSlice, regionCode, this.currentPeriod ) );
        // console.log('[DEV] sorted', sortedRegions.map( key => this.store.getStoredValue_region( this.currentSlice, key, this.currentPeriod )));

        this.top_regions_max = [];
        this.top_regions_min = [];
        for ( let i = 0; i < TOP_LENGTH; i++ ) {
            let order = i;
            let regionCode = sortedRegions[i];
            if ( regionCode && this.store.getStoredValue_region( this.currentSlice, regionCode, this.currentPeriod )) {
                this.top_regions_max.push(dct[regionCode]);
            }

            order = sortedRegions.length - 1 - i;
            regionCode = sortedRegions[order];
            this.orderDct_region[regionCode] = order + 1;
            if ( regionCode && this.store.getStoredValue_region( this.currentSlice, regionCode, this.currentPeriod )) {
                this.top_regions_min.unshift(dct[regionCode]);
            }
        }
        // console.log('[DEV] sorted', this.top_regions_max, this.top_regions_min);
    }
}
