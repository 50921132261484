export const DCT_STATUSTKO_SLICES = {
    region_reformatko_status: {slug:'region_reformatko_status', parent:'', namePlural:'Статус реформы ТКО в субъектах РФ', nameSingle:'Статус реформы ТКО в субъекте РФ', dimension:'', type:'readiness-stats', ready:54, warning:3, danger:1, min:0, max:0, value:0 }, 
    ts_readiness_status: {slug:'ts_readiness_status', parent:'region_summary_status', namePlural:'Достаточность транспорта', nameSingle:'Достаточность транспорта', dimension:'', type:'readiness-stats', ready:35, warning:8, danger:17, min:0, max:0, value:0 }, 
    object_treatment_status: {slug:'object_treatment_status', parent:'region_summary_status', namePlural:'Достаточность обработки', nameSingle:'Достаточность обработки', dimension:'', type:'readiness-stats', ready:6, warning:21, danger:33, min:0, max:0, value:0 }, 
    object_placement_status: {slug:'object_placement_status', parent:'region_summary_status', namePlural:'Достаточность размещения', nameSingle:'Достаточность размещения', dimension:'', type:'readiness-stats', ready:20, warning:28, danger:12, min:0, max:0, value:0 }, 
    violations_reformatko_status: {slug:'violations_reformatko_status', parent:'', namePlural:'Наличие правонарушений', nameSingle:'Наличие правонарушений', dimension:'', type:'readiness-stats', ready:30, warning:6, danger:24, min:0, max:0, value:0 }, 
    object_utilization_power: {slug:'object_utilization_power', parent:'', namePlural:'Мощность объектов утилизации', nameSingle:'Мощность объектов утилизации', dimension:'тыс.тонн/год', type:'number', ready:'0', warning:'0', danger:'0', min:0, max:100000, value:54 }, 
    norm_min: {slug:'norm_min', parent:'', namePlural:'Минимальный норматив для населения', nameSingle:'Минимальный норматив для населения', dimension:'тонн/чел/год', type:'number', ready:'0', warning:'0', danger:'0', min:0, max:470, value:16 }, 
    norm_max: {slug:'norm_max', parent:'', namePlural:'Максимальный норматив для населения', nameSingle:'Максимальный норматив для населения', dimension:'тонн/чел/год', type:'number', ready:'0', warning:'0', danger:'0', min:0, max:723, value:723 }, 
    ro_count: {slug:'ro_count', parent:'', namePlural:'Общее количество РО', nameSingle:'Общее количество РО', dimension:'шт', type:'number', ready:'0', warning:'0', danger:'0', min:0, max:24, value:168 } 
}
