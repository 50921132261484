export const DATA_CITY_DYN = {
    emission_volume_city_nor: [ 1729.3, 1711.29, 1693.27, 1675.26, 1657.25, 1639.23, 1621.22, 1603.2, 1585.19, 1567.18, 1549.16, 1531.15, 1513.14, 1495.12, 1477.11, 1459.1, 1441.08, 1423.07, 1405.06, 1387.04, 1369.03, 1351.01, 1333, 1314.99, 1296.97, 1278.96, 1260.95, 1242.93, 1224.92, 1206.91, 1188.89, 1170.88, 1152.86, 1134.85, 1116.84, 1098.82, 1080.81, 1062.8, 1044.78, 1026.77, 1008.76, 990.74, 972.73, 954.71, 936.7, 918.69, 900.67, 882.66, 864.65, 846.63, 828.62, 810.61, 792.59, 774.58, 756.57, 738.55, 720.54, 702.52, 684.51, 666.5, 648.48, 630.47, 612.46, 594.44, 576.43, 558.42, 540.4, 522.39, 504.37, 486.36, 468.35, 450.33 ], 
    emission_volume_city_nov: [ 340.9, 339.78, 338.67, 337.55, 336.44, 335.32, 334.21, 333.09, 331.97, 330.86, 329.74, 328.63, 327.51, 326.4, 325.28, 324.16, 323.05, 321.93, 320.82, 319.7, 318.59, 317.47, 316.35, 315.24, 314.12, 313.01, 311.89, 310.78, 309.66, 308.54, 307.43, 306.31, 305.2, 304.08, 302.97, 301.85, 300.74, 299.62, 298.5, 297.39, 296.27, 295.16, 294.04, 292.93, 291.81, 290.69, 289.58, 288.46, 287.35, 286.23, 285.12, 284, 282.88, 281.77, 280.65, 279.54, 278.42, 277.31, 276.19, 275.07, 273.96, 272.84, 271.73, 270.61, 269.5, 268.38, 267.26, 266.15, 265.03, 263.92, 262.8, 261.69 ], 
    emission_volume_city_chel: [ 323.67, 322.61, 321.56, 320.51, 319.45, 318.4, 317.34, 316.29, 315.24, 314.18, 313.13, 312.07, 311.02, 309.97, 308.91, 307.86, 306.8, 305.75, 304.7, 303.64, 302.59, 301.53, 300.48, 299.43, 298.37, 297.32, 296.26, 295.21, 294.16, 293.1, 292.05, 290.99, 289.94, 288.89, 287.83, 286.78, 285.72, 284.67, 283.61, 282.56, 281.51, 280.45, 279.4, 278.34, 277.29, 276.24, 275.18, 274.13, 273.07, 272.02, 270.97, 269.91, 268.86, 267.8, 266.75, 265.7, 264.64, 263.59, 262.53, 261.48, 260.43, 259.37, 258.32, 257.26, 256.21, 255.16, 254.1, 253.05, 251.99, 250.94, 249.89, 248.83 ], 
    emission_volume_city_mag: [ 288, 286.87, 285.73, 284.6, 283.47, 282.33, 281.2, 280.07, 278.93, 277.8, 276.67, 275.53, 274.4, 273.27, 272.13, 271, 269.87, 268.73, 267.6, 266.47, 265.33, 264.2, 263.07, 261.93, 260.8, 259.67, 258.53, 257.4, 256.27, 255.13, 254, 252.87, 251.73, 250.6, 249.47, 248.33, 247.2, 246.07, 244.93, 243.8, 242.67, 241.53, 240.4, 239.27, 238.13, 237, 235.87, 234.73, 233.6, 232.47, 231.33, 230.2, 229.07, 227.93, 226.8, 225.67, 224.53, 223.4, 222.27, 221.13, 220, 218.87, 217.73, 216.6, 215.47, 214.33, 213.2, 212.07, 210.93, 209.8, 208.67, 207.53 ], 
    emission_volume_city_kra: [ 190.7, 190.11, 189.52, 188.93, 188.33, 187.74, 187.15, 186.56, 185.97, 185.38, 184.79, 184.19, 183.6, 183.01, 182.42, 181.83, 181.24, 180.65, 180.06, 179.46, 178.87, 178.28, 177.69, 177.1, 176.51, 175.92, 175.32, 174.73, 174.14, 173.55, 172.96, 172.37, 171.78, 171.18, 170.59, 170, 169.41, 168.82, 168.23, 167.64, 167.04, 166.45, 165.86, 165.27, 164.68, 164.09, 163.5, 162.9, 162.31, 161.72, 161.13, 160.54, 159.95, 159.36, 158.77, 158.17, 157.58, 156.99, 156.4, 155.81, 155.22, 154.63, 154.03, 153.44, 152.85, 152.26, 151.67, 151.08, 150.49, 149.89, 149.3, 148.71 ], 
    emission_volume_city_bra: [ 126.5, 126.15, 125.8, 125.45, 125.1, 124.75, 124.4, 124.05, 123.7, 123.35, 123, 122.65, 122.3, 121.94, 121.59, 121.24, 120.89, 120.54, 120.19, 119.84, 119.49, 119.14, 118.79, 118.44, 118.09, 117.74, 117.39, 117.04, 116.69, 116.34, 115.99, 115.64, 115.29, 114.94, 114.59, 114.24, 113.89, 113.53, 113.18, 112.83, 112.48, 112.13, 111.78, 111.43, 111.08, 110.73, 110.38, 110.03, 109.68, 109.33, 108.98, 108.63, 108.28, 107.93, 107.58, 107.23, 106.88, 106.53, 106.18, 105.83, 105.48, 105.12, 104.77, 104.42, 104.07, 103.72, 103.37, 103.02, 102.67, 102.32, 101.97, 101.62 ], 
    emission_volume_city_lip: [ 329.3, 329.21, 329.11, 329.02, 328.92, 328.83, 328.74, 328.64, 328.55, 328.46, 328.36, 328.27, 328.17, 328.08, 327.99, 327.89, 327.8, 327.7, 327.61, 327.52, 327.42, 327.33, 327.23, 327.14, 327.05, 326.95, 326.86, 326.77, 326.67, 326.58, 326.48, 326.39, 326.3, 326.2, 326.11, 326.01, 325.92, 325.83, 325.73, 325.64, 325.54, 325.45, 325.36, 325.26, 325.17, 325.08, 324.98, 324.89, 324.79, 324.7, 324.61, 324.51, 324.42, 324.32, 324.23, 324.14, 324.04, 323.95, 323.85, 323.76, 323.67, 323.57, 323.48, 323.39, 323.29, 323.2, 323.1, 323.01, 322.92, 322.82, 322.73, 322.63 ], 
    emission_volume_city_cher: [ 334.16, 333.65, 333.14, 332.63, 332.12, 331.61, 331.09, 330.58, 330.07, 329.56, 329.05, 328.54, 328.03, 327.52, 327.01, 326.5, 325.98, 325.47, 324.96, 324.45, 323.94, 323.43, 322.92, 322.41, 321.9, 321.39, 320.87, 320.36, 319.85, 319.34, 318.83, 318.32, 317.81, 317.3, 316.79, 316.28, 315.77, 315.25, 314.74, 314.23, 313.72, 313.21, 312.7, 312.19, 311.68, 311.17, 310.66, 310.14, 309.63, 309.12, 308.61, 308.1, 307.59, 307.08, 306.57, 306.06, 305.55, 305.03, 304.52, 304.01, 303.5, 302.99, 302.48, 301.97, 301.46, 300.95, 300.44, 299.92, 299.41, 298.9, 298.39, 297.88 ], 
    emission_volume_city_nt: [ 138.8, 138.41, 138.02, 137.64, 137.25, 136.86, 136.47, 136.08, 135.69, 135.31, 134.92, 134.53, 134.14, 133.75, 133.36, 132.98, 132.59, 132.2, 131.81, 131.42, 131.03, 130.65, 130.26, 129.87, 129.48, 129.09, 128.7, 128.32, 127.93, 127.54, 127.15, 126.76, 126.37, 125.99, 125.6, 125.21, 124.82, 124.43, 124.04, 123.66, 123.27, 122.88, 122.49, 122.1, 121.71, 121.33, 120.94, 120.55, 120.16, 119.77, 119.38, 119, 118.61, 118.22, 117.83, 117.44, 117.05, 116.67, 116.28, 115.89, 115.5, 115.11, 114.72, 114.34, 113.95, 113.56, 113.17, 112.78, 112.39, 112.01, 111.62, 111.23 ], 
    emission_volume_city_chit: [ 38.7, 38.58, 38.46, 38.34, 38.21, 38.09, 37.97, 37.85, 37.73, 37.61, 37.48, 37.36, 37.24, 37.12, 37, 36.88, 36.76, 36.63, 36.51, 36.39, 36.27, 36.15, 36.03, 35.9, 35.78, 35.66, 35.54, 35.42, 35.3, 35.18, 35.05, 34.93, 34.81, 34.69, 34.57, 34.45, 34.33, 34.2, 34.08, 33.96, 33.84, 33.72, 33.6, 33.47, 33.35, 33.23, 33.11, 32.99, 32.87, 32.75, 32.62, 32.5, 32.38, 32.26, 32.14, 32.02, 31.89, 31.77, 31.65, 31.53, 31.41, 31.29, 31.17, 31.04, 30.92, 30.8, 30.68, 30.56, 30.44, 30.31, 30.19, 30.07 ], 
    emission_volume_city_oms: [ 250.8, 250.02, 249.24, 248.46, 247.68, 246.9, 246.12, 245.34, 244.56, 243.78, 242.99, 242.21, 241.43, 240.65, 239.87, 239.09, 238.31, 237.53, 236.75, 235.97, 235.19, 234.41, 233.63, 232.85, 232.07, 231.29, 230.51, 229.73, 228.94, 228.16, 227.38, 226.6, 225.82, 225.04, 224.26, 223.48, 222.7, 221.92, 221.14, 220.36, 219.58, 218.8, 218.02, 217.24, 216.46, 215.68, 214.89, 214.11, 213.33, 212.55, 211.77, 210.99, 210.21, 209.43, 208.65, 207.87, 207.09, 206.31, 205.53, 204.75, 203.97, 203.19, 202.41, 201.63, 200.84, 200.06, 199.28, 198.5, 197.72, 196.94, 196.16, 195.38 ], 
    emission_volume_city_med: [ 9.55, 9.54, 9.53, 9.52, 9.51, 9.5, 9.49, 9.48, 9.47, 9.46, 9.45, 9.44, 9.44, 9.43, 9.42, 9.41, 9.4, 9.39, 9.38, 9.37, 9.36, 9.35, 9.34, 9.33, 9.32, 9.31, 9.3, 9.29, 9.28, 9.27, 9.26, 9.25, 9.24, 9.23, 9.22, 9.21, 9.21, 9.2, 9.19, 9.18, 9.17, 9.16, 9.15, 9.14, 9.13, 9.12, 9.11, 9.1, 9.09, 9.08, 9.07, 9.06, 9.05, 9.04, 9.03, 9.02, 9.01, 9, 8.99, 8.98, 8.98, 8.97, 8.96, 8.95, 8.94, 8.93, 8.92, 8.91, 8.9, 8.89, 8.88, 8.87 ], 

    emission_volume_all: [ 4100.38, 4076.21, 4052.05, 4027.89, 4003.72, 3979.56, 3955.4, 3931.23, 3907.07, 3882.91, 3858.74, 3834.58, 3810.42, 3786.25, 3762.09, 3737.93, 3713.76, 3689.6, 3665.44, 3641.27, 3617.11, 3592.95, 3568.78, 3544.62, 3520.46, 3496.29, 3472.13, 3447.97, 3423.8, 3399.64, 3375.48, 3351.31, 3327.15, 3302.99, 3278.82, 3254.66, 3230.5, 3206.33, 3182.17, 3158.01, 3133.84, 3109.68, 3085.52, 3061.35, 3037.19, 3013.03, 2988.86, 2964.7, 2940.54, 2916.37, 2892.21, 2868.05, 2843.88, 2819.72, 2795.56, 2771.39, 2747.23, 2723.07, 2698.9, 2674.74, 2650.58, 2626.41, 2602.25, 2578.09, 2553.92, 2529.76, 2505.6, 2481.43, 2457.27, 2433.11, 2408.94, 2384.78 ], 

    pollution_level_city_nor: [ 100, 99, 99, 98, 98, 97, 97, 96, 95, 95, 94, 94, 93, 93, 92, 92, 91, 90, 90, 89, 89, 88, 88, 87, 86, 86, 85, 85, 84, 84, 83, 83, 82, 81, 81, 80, 80, 79, 79, 78, 77, 77, 76, 76, 75, 75, 74, 74, 73, 72, 72, 71, 71, 70, 70, 69, 68, 68, 67, 67, 66, 66, 65, 65, 64, 63, 63, 62, 62, 61, 61, 60 ], 
    pollution_level_city_nov: [ 100, 99, 98, 97, 95, 94, 93, 92, 91, 90, 89, 87, 86, 85, 84, 83, 82, 81, 79, 78, 77, 76, 75, 74, 73, 71, 70, 69, 68, 67, 66, 65, 63, 62, 61, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60 ], 
    pollution_level_city_chel: [ 80, 79, 78, 77, 77, 76, 75, 74, 73, 72, 71, 70, 70, 69, 68, 67, 66, 65, 64, 63, 63, 62, 61, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60 ], 
    pollution_level_city_mag: [ 100, 99, 99, 98, 98, 97, 97, 96, 95, 95, 94, 94, 93, 93, 92, 92, 91, 90, 90, 89, 89, 88, 88, 87, 86, 86, 85, 85, 84, 84, 83, 83, 82, 81, 81, 80, 80, 79, 79, 78, 77, 77, 76, 76, 75, 75, 74, 74, 73, 72, 72, 71, 71, 70, 70, 69, 68, 68, 67, 67, 66, 66, 65, 65, 64, 63, 63, 62, 62, 61, 61, 60 ], 
    pollution_level_city_kra: [ 100, 99, 98, 97, 97, 96, 95, 94, 93, 92, 91, 91, 90, 89, 88, 87, 86, 86, 85, 84, 83, 82, 81, 80, 80, 79, 78, 77, 76, 75, 74, 74, 73, 72, 71, 70, 69, 69, 68, 67, 66, 65, 64, 63, 63, 62, 61, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60 ], 
    pollution_level_city_bra: [ 100, 99, 99, 98, 97, 97, 96, 95, 95, 94, 93, 93, 92, 91, 91, 90, 89, 88, 88, 87, 86, 86, 85, 84, 84, 83, 82, 82, 81, 80, 80, 79, 78, 78, 77, 76, 76, 75, 74, 74, 73, 72, 72, 71, 70, 69, 69, 68, 67, 67, 66, 65, 65, 64, 63, 63, 62, 61, 61, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60 ], 
    pollution_level_city_lip: [ 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60 ], 
    pollution_level_city_cher: [ 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60 ], 
    pollution_level_city_nt: [ 80, 79, 78, 77, 77, 76, 75, 74, 73, 72, 71, 70, 70, 69, 68, 67, 66, 65, 64, 63, 63, 62, 61, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60 ], 
    pollution_level_city_chit: [ 100, 99, 98, 97, 97, 96, 95, 94, 93, 92, 91, 91, 90, 89, 88, 87, 86, 86, 85, 84, 83, 82, 81, 80, 80, 79, 78, 77, 76, 75, 74, 74, 73, 72, 71, 70, 69, 69, 68, 67, 66, 65, 64, 63, 63, 62, 61, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60 ], 
    pollution_level_city_oms: [ 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40 ], 
    pollution_level_city_med: [ 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60 ], 

    pollution_level_all: [ 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 0 ], 
}; 
