import 'hammerjs';
import 'hammer-timejs';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule, StateResolver } from './app-routing.module';
// import { RegionInfoComponent } from './components/region-info/region-info.component';
import { AppComponent } from './app.component';
import { MapComponent } from './components/map/map.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { StoreService } from './store/store.service';
import { DataSelectorComponent } from './components/data-selector/data-selector.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StateService } from './store/state.service';

import {
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
} from '@angular/material';
import { DataGroupedSelectorComponent } from './components/data-grouped-selector/data-grouped-selector.component';
import { RegionCardComponent } from './components/region-card/region-card.component';
import { NavFormComponent } from './components/nav-form/nav-form.component';
import { ZoneSideCardComponent } from './components/side-card/zone/zone-side-card.component';
import { PanzoomDirective } from './ads/panzoom.directive';
import { PaperComponent } from './components/paper/paper.component';
import { TreatmentPage } from './pages/treatment/treatment-page.component';
import { ReadinessPage } from './pages/readiness/readiness-page.component';
import { HeadLayoutComponent } from './components/layout/head/head-layout.component';
import { ToolbarLayoutComponent } from './components/layout/toolbar/toolbar-layout.component';
import { SidecardLayoutComponent } from './components/layout/sidecard/sidecard-layout.component';
import { MapLayoutComponent } from './components/layout/map/map-layout.component';
import { DashboardLayoutComponent } from './components/layout/dashboard/dashboard-layout.component';
import { MenuLayoutComponent } from './components/layout/menu/menu-layout.component';
import { ReadinessMapComponent } from './components/readiness/map/readiness-map.component';
import { CommonTableComponent } from './components/common-table/common-table.component';
import { ReadinessTableComponent } from './components/readiness/table/readiness-table.component';
import { PopupcardLayoutComponent } from './components/layout/popupcard/popupcard-layout.component';
import { ReadinessStatsCellComponent } from './components/cell/readiness-stat/readiness-stats-cell.component';
import { AuxillaryElementsModule } from './ae/ae.module';
import { CellHostComponent } from './components/cell/cell-host.component';
import { CellDirective } from './components/cell/cell.directive';
import { FreshairPageComponent } from './pages/freshair/freshair-page.component';
import { QuantityCellComponent } from './components/cell/quantity/quantity-cell.component';
import { PercentCellComponent } from './components/cell/percent/percent-cell.component';
import { KtonnCellComponent } from './components/cell/ktonn/ktonn-cell.component';
import { ViaDimCellComponent } from './components/cell/via-dim/via-dim-cell.component';
import { CommonPage } from './pages/common/common-page.component';
import { NothingPage } from './pages/nothing/nothing-page.component';
import { CommonMapSection } from './pages/common/sections/map/common-map-section.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { NumberCellComponent } from './components/cell/number/number-cell.component';
import { StatustkoTableComponent } from './components/statustko-table/statustko-table.component';

@NgModule({
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    DragDropModule,
    AuxillaryElementsModule,


    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
  ],
  declarations: [
    CommonPage,
    CommonMapSection,
    NothingPage,
    TreatmentPage,
    ReadinessPage,
    FreshairPageComponent,
    HeadLayoutComponent,
    ToolbarLayoutComponent,
    SidecardLayoutComponent,
    PopupcardLayoutComponent,

    MapLayoutComponent,
    ReadinessMapComponent,
    ReadinessTableComponent,
    CommonTableComponent,

    DashboardLayoutComponent,
    MenuLayoutComponent,

    AppComponent,
    MapComponent,
    TimelineComponent,
    DataSelectorComponent,
    DataGroupedSelectorComponent,
    RegionCardComponent,
    NavFormComponent,
    ZoneSideCardComponent,
    PaperComponent,
    StatustkoTableComponent,

    PanzoomDirective,

    CellDirective,
    CellHostComponent,
    ReadinessStatsCellComponent,
    QuantityCellComponent,
    PercentCellComponent,
    ViaDimCellComponent,
    KtonnCellComponent,
    NumberCellComponent,
    TooltipDirective,
  ],
  providers: [
    StoreService,
    StateService,
    StateResolver,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    CommonPage,
    CommonMapSection,
    NothingPage,
    TreatmentPage,

    ReadinessPage,
    FreshairPageComponent,
    ReadinessMapComponent,
    ReadinessTableComponent,
    CommonTableComponent,

    HeadLayoutComponent,
    ToolbarLayoutComponent,
    SidecardLayoutComponent,
    PopupcardLayoutComponent,

    MapLayoutComponent,
    DashboardLayoutComponent,
    MenuLayoutComponent,

    MapComponent,
    TimelineComponent,
    DataSelectorComponent,
    RegionCardComponent,
    NavFormComponent,
    ZoneSideCardComponent,
    PaperComponent,
    StatustkoTableComponent,

    CellHostComponent,
    ReadinessStatsCellComponent,
    QuantityCellComponent,
    PercentCellComponent,
    ViaDimCellComponent,
    KtonnCellComponent,
    NumberCellComponent,
  ],
})
export class AppModule {
  constructor( private injector: Injector ) {}
  ngDoBootstrap() {
    // const customElement = createCustomElement(MapComponent, {injector: this.injector});
  }
}
