import { Component } from "@angular/core";
import { CellBaseComponent, CellBaseComponentConfig } from '../cell-base.component';
import { StateService } from 'src/app/store/state.service';

@Component({
    ...CellBaseComponentConfig,
    selector: 'apm-cell_number',
    templateUrl: 'number-cell.component.html',
    host:{class:'b3-p-slice__stats-cell'}
})
export class NumberCellComponent extends CellBaseComponent{
    constructor( protected state:StateService ){ super(); }
}
