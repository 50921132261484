import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { StateService } from '../../../store/state.service';
import { Bundle } from 'src/app/store/types';
import { StoreService } from 'src/app/store/store.service';

@Component({
    selector: 'apm-toolbar-layout',
    templateUrl: './toolbar-layout.component.html',
    host: {class: 'b3-p-toolbar b3-flex_row'},
})
export class ToolbarLayoutComponent{
    private isDragging = false;

    constructor(
        public state: StateService,
        public store: StoreService,
    ){}

    public get barPosition() {
        return (this.state.currentPeriod + Math.floor(this.state.currentPeriod / 12) + 1) * 8;
    }

    public onTimelineClick( event: any ) {
        // console.log(event.offsetX);
        let index = Math.floor((event.offsetX) / 8);
        index -= Math.floor(index / 12);
        if ( index > -1 && index < 72 && this.state.currentPeriod !== index ) {
            this.state.currentPeriod = index;
        }
    }

    public getBundleTotal( bundle: Bundle ): number {
        return bundle.total instanceof Array ? bundle.total[this.state.currentPeriod] : bundle.total;
    }

    public startDrag(event) {
        // console.log(event.offsetX);
        this.isDragging = true;
        this.onTimelineClick(event);
    }
    public doDrag( event ) {
        // console.log(event.offsetX);
        if ( this.isDragging ) {
            this.onTimelineClick(event);
        }
    }
    public stopDrag() {
        // console.log(event.offsetX);
        this.isDragging = false;
    }

    public getHeight( period ){
        if( this.state.currentSlice ){
            let h = (100 * this.store.getStoredValue_region( this.state.currentSlice, 'all', period ) / this.state.currentSliceMax); 
            h = h > 100 ? 100 : h;
            return h*0.8 + '%';
        }
        else
            return '50%';
    }
}
