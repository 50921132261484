import { Component, Input } from "@angular/core";
import { RZone } from '../../../store/types';
import { StateService } from '../../../store/state.service';
import { StoreService } from 'src/app/store/store.service';

@Component({
    selector: 'apm-zone-side-card',
    templateUrl: './zone-side-card.component.html',
    host:{class:'b3-card b3-card_side b3-side-card b3-side-card__promo', style:'top: 0; z-index: 100; height: 100%'}
})
export class ZoneSideCardComponent{
    @Input() rzone:RZone;
    public years = [
        2019,
        2020,
        2021,
        2022,
        2023,
        2024,
    ]

    constructor(
        public state:StateService,
        public store:StoreService,
    ){}
}
