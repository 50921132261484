export const DATA_DYNAMIC = {
    per_person_min_ALT:['36,69','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_AMU:['41,1','41,24','41,37','41,51','41,65','41,78','41,92','42,06','42,19','42,33','42,47','42,6','42,74','42,88','43,03','43,17','43,31','43,45','43,6','43,74','43,88','44,02','44,17','44,31','44,45','44,6','44,75','44,9','45,04','45,19','45,34','45,49','45,64','45,79','45,93','46,08','46,23','46,38','46,54','46,69','46,85','47','47,16','47,31','47,46','47,62','47,77','47,93','48,08','48,24','48,4','48,56','48,72','48,88','49,04','49,2','49,36','49,52','49,68','49,84','50','50','50','50','50','50','50','50','50','50','50','50'],
    per_person_min_ARK:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_AST:['24,23','24,31','24,39','24,47','24,55','24,63','24,72','24,8','24,88','24,96','25,04','25,12','25,2','25,28','25,37','25,45','25,54','25,62','25,71','25,79','25,87','25,96','26,04','26,13','26,21','26,3','26,39','26,47','26,56','26,65','26,74','26,82','26,91','27','27,09','27,17','27,26','27,35','27,44','27,53','27,62','27,71','27,81','27,9','27,99','28,08','28,17','28,26','28,35','28,44','28,54','28,63','28,73','28,82','28,92','29,01','29,1','29,2','29,29','29,39','29,48','29,48','29,48','29,48','29,48','29,48','29,48','29,48','29,48','29,48','29,48','29,48'],
    per_person_min_BEL:['97,8','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_BRY:['89,11','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_VLA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_VGG:['113,25','113,63','114,01','114,38','114,76','115,14','115,52','115,89','116,27','116,65','117,03','117,4','117,78','118,17','118,57','118,96','119,35','119,74','120,14','120,53','120,92','121,31','121,71','122,1','122,49','122,9','123,31','123,72','124,12','124,53','124,94','125,35','125,76','126,17','126,57','126,98','127,39','127,82','128,24','128,67','129,09','129,52','129,94','130,37','130,79','131,22','131,64','132,07','132,49','132,93','133,37','133,82','134,26','134,7','135,14','135,58','136,02','136,47','136,91','137,35','137,79','137,79','137,79','137,79','137,79','137,79','137,79','137,79','137,79','137,79','137,79','137,79'],
    per_person_min_VLG:['112,92','113,06','113,19','113,33','113,47','113,6','113,74','113,88','114,01','114,15','114,29','114,42','114,56','114,99','115,42','115,85','116,27','116,7','117,13','117,56','117,99','118,42','118,84','119,27','119,7','121,69','123,69','125,68','127,67','129,67','131,66','133,65','135,65','137,64','139,63','141,63','143,62','144,04','144,46','144,88','145,3','145,72','146,15','146,57','146,99','147,41','147,83','148,25','148,67','149,07','149,47','149,87','150,27','150,67','151,08','151,48','151,88','152,28','152,68','153,08','153,48','153,48','153,48','153,48','153,48','153,48','153,48','153,48','153,48','153,48','153,48','153,48'],
    per_person_min_VOR:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_YEV:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_ZAB:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_IVA:['88,01','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_IRK:['91,51','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_KGD:['85,13','85,41','85,7','85,98','86,27','86,55','86,84','87,12','87,4','87,69','87,97','88,26','88,54','88,84','89,13','89,43','89,72','90,02','90,31','90,61','90,9','91,2','91,49','91,79','92,08','92,39','92,69','93','93,31','93,61','93,92','94,23','94,53','94,84','95,15','95,45','95,76','96,08','96,4','96,72','97,04','97,36','97,68','97,99','98,31','98,63','98,95','99,27','99,59','99,92','100,25','100,59','100,92','101,25','101,58','101,91','102,24','102,58','102,91','103,24','103,57','103,57','103,57','103,57','103,57','103,57','103,57','103,57','103,57','103,57','103,57','103,57'],
    per_person_min_KLU:['69','69,23','69,46','69,69','69,92','70,15','70,38','70,61','70,84','71,07','71,3','71,53','71,76','72','72,24','72,48','72,72','72,96','73,2','73,43','73,67','73,91','74,15','74,39','74,63','74,88','75,13','75,38','75,63','75,88','76,13','76,37','76,62','76,87','77,12','77,37','77,62','77,88','78,14','78,4','78,65','78,91','79,17','79,43','79,69','79,95','80,2','80,46','80,72','80,99','81,26','81,53','81,8','82,07','82,34','82,6','82,87','83,14','83,41','83,68','83,95','83,95','83,95','83,95','83,95','83,95','83,95','83,95','83,95','83,95','83,95','83,95'],
    per_person_min_KAM:['190,53','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_KEM:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_KIR:['117','118,5','120','121,5','123','124,5','126','127,5','129','130,5','132','133,5','135','135,75','136,5','137,25','138','138,75','139,5','140,25','141','141,75','142,5','143,25','144','145,5','147','148,5','150','151,5','153','154,5','156','157,5','159','160,5','162','162,17','162,33','162,5','162,67','162,83','163','163,17','163,33','163,5','163,67','163,83','164','164,33','164,67','165','165,33','165,67','166','166,33','166,67','167','167,33','167,67','168','168','168','168','168','168','168','168','168','168','168','168'],
    per_person_min_KOS:['90,3','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_KDA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_KYA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_KGN:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_KRS:['52','52,17','52,35','52,52','52,69','52,87','53,04','53,21','53,39','53,56','53,73','53,91','54,08','54,26','54,44','54,62','54,8','54,98','55,16','55,34','55,52','55,7','55,88','56,06','56,24','56,43','56,62','56,8','56,99','57,18','57,37','57,55','57,74','57,93','58,12','58,3','58,49','58,69','58,88','59,08','59,27','59,47','59,66','59,86','60,05','60,25','60,44','60,64','60,83','61,03','61,24','61,44','61,64','61,85','62,05','62,25','62,46','62,66','62,86','63,07','63,27','63,27','63,27','63,27','63,27','63,27','63,27','63,27','63,27','63,27','63,27','63,27'],
    per_person_min_LEN:['108,75','109,11','109,48','109,84','110,2','110,56','110,93','111,29','111,65','112,01','112,38','112,74','113,1','113,48','113,85','114,23','114,61','114,98','115,36','115,74','116,11','116,49','116,87','117,24','117,62','118,01','118,41','118,8','119,19','119,58','119,98','120,37','120,76','121,15','121,55','121,94','122,33','122,74','123,15','123,55','123,96','124,37','124,78','125,18','125,59','126','126,41','126,81','127,22','127,64','128,07','128,49','128,92','129,34','129,77','130,19','130,61','131,04','131,46','131,89','132,31','132,31','132,31','132,31','132,31','132,31','132,31','132,31','132,31','132,31','132,31','132,31'],
    per_person_min_LIP:['83,7','89,59','95,49','101,38','107,28','113,17','119,07','124,96','130,85','136,75','142,64','148,54','154,43','155,24','156,05','156,86','157,67','158,48','159,29','160,09','160,9','161,71','162,52','163,33','164,14','168,1','172,05','176,01','179,96','183,92','187,88','191,83','195,79','199,74','203,7','207,65','211,61','212,23','212,85','213,47','214,08','214,7','215,32','215,94','216,56','217,18','217,79','218,41','219,03','219,36','219,69','220,02','220,35','220,68','221,01','221,33','221,66','221,99','222,32','222,65','222,98','222,98','222,98','222,98','222,98','222,98','222,98','222,98','222,98','222,98','222,98','222,98'],
    per_person_min_MAG:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_MOW:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_MOS:['126,48','126,9','127,32','127,75','128,17','128,59','129,01','129,43','129,85','130,28','130,7','131,12','131,54','131,98','132,42','132,86','133,29','133,73','134,17','134,61','135,05','135,49','135,92','136,36','136,8','137,26','137,71','138,17','138,62','139,08','139,54','139,99','140,45','140,9','141,36','141,81','142,27','142,74','143,22','143,69','144,17','144,64','145,12','145,59','146,06','146,54','147,01','147,49','147,96','148,45','148,95','149,44','149,93','150,43','150,92','151,41','151,91','152,4','152,89','153,39','153,88','153,88','153,88','153,88','153,88','153,88','153,88','153,88','153,88','153,88','153,88','153,88'],
    per_person_min_MUR:['133,56','134,01','134,45','134,9','135,34','135,79','136,23','136,68','137,12','137,57','138,01','138,46','138,9','139,36','139,83','140,29','140,75','141,22','141,68','142,14','142,61','143,07','143,53','144','144,46','144,94','145,42','145,91','146,39','146,87','147,35','147,83','148,31','148,8','149,28','149,76','150,24','150,74','151,24','151,74','152,24','152,74','153,25','153,75','154,25','154,75','155,25','155,75','156,25','156,77','157,29','157,81','158,33','158,85','159,38','159,9','160,42','160,94','161,46','161,98','162,5','162,5','162,5','162,5','162,5','162,5','162,5','162,5','162,5','162,5','162,5','162,5'],
    per_person_min_NEN:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_NIZ:['89,03','89,33','89,62','89,92','90,22','90,51','90,81','91,11','91,4','91,7','92','92,29','92,59','92,9','93,21','93,52','93,82','94,13','94,44','94,75','95,06','95,37','95,67','95,98','96,29','96,61','96,93','97,25','97,57','97,89','98,22','98,54','98,86','99,18','99,5','99,82','100,14','100,47','100,81','101,14','101,48','101,81','102,15','102,48','102,81','103,15','103,48','103,82','104,15','104,5','104,85','105,19','105,54','105,89','106,24','106,58','106,93','107,28','107,63','107,97','108,32','108,32','108,32','108,32','108,32','108,32','108,32','108,32','108,32','108,32','108,32','108,32'],
    per_person_min_NGR:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_NVS:['92,42','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_OMS:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_ORE:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_ORL:['78,24','78,5','78,76','79,02','79,28','79,54','79,81','80,07','80,33','80,59','80,85','81,11','81,37','81,64','81,91','82,18','82,45','82,72','83','83,27','83,54','83,81','84,08','84,35','84,62','84,9','85,19','85,47','85,75','86,03','86,32','86,6','86,88','87,16','87,45','87,73','88,01','88,3','88,6','88,89','89,18','89,48','89,77','90,06','90,36','90,65','90,94','91,24','91,53','91,84','92,14','92,45','92,75','93,06','93,36','93,67','93,97','94,28','94,58','94,89','95,19','95,19','95,19','95,19','95,19','95,19','95,19','95,19','95,19','95,19','95,19','95,19'],
    per_person_min_PNZ:['73,59','74,37','75,16','75,94','76,73','77,51','78,3','79,08','79,86','80,65','81,43','82,22','83','86,67','90,33','94','97,67','101,33','105','108,67','112,33','116','119,67','123,33','127','128','129','130','131','132','133','134','135','136','137','138','139','139,33','139,67','140','140,33','140,67','141','141,33','141,67','142','142,33','142,67','143','143,33','143,67','144','144,33','144,67','145','145,33','145,67','146','146,33','146,67','147','147','147','147','147','147','147','147','147','147','147','147'],
    per_person_min_PER:['8,24','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_PRI:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_PSK:['64,02','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_AD:['90,7','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_AL:['53,68','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_BA:['70,23','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_BU:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_DA:['24,95','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_IN:['84,04','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_KB:['68,77','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_KL:['55,6','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_KC:['91,11','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_KR:['86,27','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_KO:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_CR:['83,16','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_ME:['75,75','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_MO:['83,3','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_SA:['103,03','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_SE:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_TA:['86,34','88,09','89,84','91,59','93,34','95,09','96,84','98,59','100,34','102,09','103,84','105,59','107,34','107,99','108,64','109,29','109,94','110,59','111,25','111,9','112,55','113,2','113,85','114,5','115,15','118,34','121,52','124,71','127,9','131,08','134,27','137,46','140,64','143,83','147,02','150,2','153,39','153,47','153,56','153,64','153,72','153,8','153,89','153,97','154,05','154,13','154,22','154,3','154,38','154,39','154,41','154,42','154,44','154,45','154,47','154,48','154,49','154,51','154,52','154,54','154,55','154,55','154,55','154,55','154,55','154,55','154,55','154,55','154,55','154,55','154,55','154,55'],
    per_person_min_TY:['67,66','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_UD:['95,2','95,52','95,84','96,15','96,47','96,79','97,11','97,42','97,74','98,06','98,38','98,69','99,01','99,34','99,67','100','100,33','100,66','100,99','101,32','101,65','101,98','102,31','102,64','102,97','103,31','103,66','104','104,34','104,69','105,03','105,37','105,72','106,06','106,4','106,75','107,09','107,45','107,8','108,16','108,52','108,87','109,23','109,59','109,94','110,3','110,66','111,01','111,37','111,74','112,11','112,49','112,86','113,23','113,6','113,97','114,34','114,72','115,09','115,46','115,83','115,83','115,83','115,83','115,83','115,83','115,83','115,83','115,83','115,83','115,83','115,83'],
    per_person_min_KK:['55,82','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_CE:['77,14','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_CU:['75,7','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_ROS:['69,26','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_RYA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_SAM:['81,72','87,79','93,86','99,92','105,99','112,06','118,13','124,19','130,26','136,33','142,4','148,46','154,53','155,4','156,27','157,13','158','158,87','159,74','160,6','161,47','162,34','163,21','164,07','164,94','168','171,06','174,11','177,17','180,23','183,29','186,34','189,4','192,46','195,52','198,57','201,63','202,43','203,24','204,04','204,85','205,65','206,46','207,26','208,06','208,87','209,67','210,48','211,28','211,88','212,49','213,09','213,69','214,29','214,9','215,5','216,1','216,7','217,31','217,91','218,51','218,51','218,51','218,51','218,51','218,51','218,51','218,51','218,51','218,51','218,51','218,51'],
    per_person_min_SPE:['101,86','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_SAR:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_SAK:['70','70,23','70,47','70,7','70,93','71,17','71,4','71,63','71,87','72,1','72,33','72,57','72,8','73,04','73,29','73,53','73,77','74,01','74,26','74,5','74,74','74,98','75,23','75,47','75,71','75,96','76,22','76,47','76,72','76,97','77,23','77,48','77,73','77,98','78,24','78,49','78,74','79','79,27','79,53','79,79','80,05','80,32','80,58','80,84','81,1','81,37','81,63','81,89','82,16','82,44','82,71','82,98','83,26','83,53','83,8','84,08','84,35','84,62','84,9','85,17','85,17','85,17','85,17','85,17','85,17','85,17','85,17','85,17','85,17','85,17','85,17'],
    per_person_min_SVE:['120,59','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_SEV:['78,55','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_SMO:['92,26','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_STA:['96,43','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_TAM:['100,39','100,73','101,06','101,4','101,73','102,07','102,4','102,74','103,07','103,41','103,74','104,08','104,41','104,76','105,11','105,46','105,8','106,15','106,5','106,85','107,2','107,55','107,89','108,24','108,59','108,95','109,31','109,68','110,04','110,4','110,76','111,12','111,48','111,85','112,21','112,57','112,93','113,31','113,68','114,06','114,44','114,81','115,19','115,57','115,94','116,32','116,7','117,07','117,45','117,84','118,23','118,63','119,02','119,41','119,8','120,19','120,58','120,98','121,37','121,76','122,15','122,15','122,15','122,15','122,15','122,15','122,15','122,15','122,15','122,15','122,15','122,15'],
    per_person_min_TVE:['97,46','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_TOM:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_TUL:['119,2','119,6','120','120,39','120,79','121,19','121,59','121,98','122,38','122,78','123,18','123,57','123,97','124,38','124,8','125,21','125,62','126,04','126,45','126,86','127,28','127,69','128,1','128,52','128,93','129,36','129,79','130,22','130,65','131,08','131,51','131,93','132,36','132,79','133,22','133,65','134,08','134,53','134,98','135,42','135,87','136,32','136,77','137,21','137,66','138,11','138,56','139','139,45','139,92','140,38','140,85','141,31','141,78','142,24','142,71','143,17','143,64','144,1','144,57','145,03','145,03','145,03','145,03','145,03','145,03','145,03','145,03','145,03','145,03','145,03','145,03'],
    per_person_min_TYU:['174,26','174,84','175,42','176','176,58','177,16','177,75','178,33','178,91','179,49','180,07','180,65','181,23','181,83','182,44','183,04','183,65','184,25','184,86','185,46','186,06','186,67','187,27','187,88','188,48','189,11','189,74','190,37','190,99','191,62','192,25','192,88','193,51','194,14','194,76','195,39','196,02','196,67','197,33','197,98','198,63','199,29','199,94','200,59','201,25','201,9','202,55','203,21','203,86','204,54','205,22','205,9','206,58','207,26','207,94','208,61','209,29','209,97','210,65','211,33','212,01','212,01','212,01','212,01','212,01','212,01','212,01','212,01','212,01','212,01','212,01','212,01'],
    per_person_min_ULY:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_KHA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_KHM:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_CHE:['42,1','42,24','42,38','42,52','42,66','42,8','42,94','43,08','43,22','43,36','43,5','43,64','43,78','43,93','44,07','44,22','44,36','44,51','44,66','44,8','44,95','45,09','45,24','45,38','45,53','45,68','45,83','45,99','46,14','46,29','46,44','46,59','46,74','46,9','47,05','47,2','47,35','47,51','47,67','47,82','47,98','48,14','48,3','48,45','48,61','48,77','48,93','49,08','49,24','49,4','49,57','49,73','49,9','50,06','50,23','50,39','50,55','50,72','50,88','51,05','51,21','51,21','51,21','51,21','51,21','51,21','51,21','51,21','51,21','51,21','51,21','51,21'],
    per_person_min_CHU:['58,29','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_min_YAN:['150','150,5','151','151,5','152','152,5','153','153,5','154','154,5','155','155,5','156','156,52','157,04','157,56','158,08','158,6','159,12','159,64','160,16','160,68','161,2','161,72','162,24','162,78','163,32','163,86','164,4','164,94','165,49','166,03','166,57','167,11','167,65','168,19','168,73','169,29','169,86','170,42','170,98','171,54','172,11','172,67','173,23','173,79','174,36','174,92','175,48','176,07','176,65','177,24','177,82','178,41','178,99','179,58','180,16','180,75','181,33','181,92','182,5','182,5','182,5','182,5','182,5','182,5','182,5','182,5','182,5','182,5','182,5','182,5'],
    per_person_min_YAR:['75,2','75,45','75,7','75,95','76,2','76,45','76,71','76,96','77,21','77,46','77,71','77,96','78,21','78,47','78,73','78,99','79,25','79,51','79,78','80,04','80,3','80,56','80,82','81,08','81,34','81,61','81,88','82,15','82,42','82,69','82,97','83,24','83,51','83,78','84,05','84,32','84,59','84,87','85,15','85,44','85,72','86','86,28','86,56','86,84','87,13','87,41','87,69','87,97','88,26','88,56','88,85','89,14','89,44','89,73','90,02','90,32','90,61','90,9','91,2','91,49','91,49','91,49','91,49','91,49','91,49','91,49','91,49','91,49','91,49','91,49','91,49'],
    per_person_min_all:['8,24','24,31','24,39','24,47','24,55','24,63','24,72','24,8','24,88','24,96','25,04','25,12','25,2','25,28','25,37','25,45','25,54','25,62','25,71','25,79','25,87','25,96','26,04','26,13','26,21','26,3','26,39','26,47','26,56','26,65','26,74','26,82','26,91','27','27,09','27,17','27,26','27,35','27,44','27,53','27,62','27,71','27,81','27,9','27,99','28,08','28,17','28,26','28,35','28,44','28,54','28,63','28,73','28,82','28,92','29,01','29,1','29,2','29,29','29,39','29,48','29,48','29,48','29,48','29,48','29,48','29,48','29,48','29,48','29,48','29,48','29,48'],
    per_person_max_ALT:['111,28','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_AMU:['97,93','98,26','98,58','98,91','99,24','99,56','99,89','100,22','100,54','100,87','101,2','101,52','101,85','102,19','102,53','102,87','103,21','103,55','103,89','104,22','104,56','104,9','105,24','105,58','105,92','106,27','106,63','106,98','107,33','107,69','108,04','108,39','108,75','109,1','109,45','109,81','110,16','110,53','110,9','111,26','111,63','112','112,37','112,73','113,1','113,47','113,84','114,2','114,57','114,95','115,33','115,72','116,1','116,48','116,86','117,24','117,62','118,01','118,39','118,77','119,15','119,15','119,15','119,15','119,15','119,15','119,15','119,15','119,15','119,15','119,15','119,15'],
    per_person_max_ARK:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_AST:['96,46','96,78','97,1','97,43','97,75','98,07','98,39','98,71','99,03','99,36','99,68','100','100,32','100,65','100,99','101,32','101,66','101,99','102,33','102,66','102,99','103,33','103,66','104','104,33','104,68','105,03','105,37','105,72','106,07','106,42','106,76','107,11','107,46','107,81','108,15','108,5','108,86','109,22','109,59','109,95','110,31','110,67','111,03','111,39','111,76','112,12','112,48','112,84','113,22','113,59','113,97','114,35','114,72','115,1','115,48','115,85','116,23','116,61','116,98','117,36','117,36','117,36','117,36','117,36','117,36','117,36','117,36','117,36','117,36','117,36','117,36'],
    per_person_max_BEL:['106,69','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_BRY:['89,11','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_VLA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_VGG:['114,92','115,3','115,69','116,07','116,45','116,84','117,22','117,6','117,99','118,37','118,75','119,14','119,52','119,92','120,32','120,72','121,11','121,51','121,91','122,31','122,71','123,11','123,5','123,9','124,3','124,71','125,13','125,54','125,96','126,37','126,79','127,2','127,61','128,03','128,44','128,86','129,27','129,7','130,13','130,56','130,99','131,42','131,86','132,29','132,72','133,15','133,58','134,01','134,44','134,89','135,34','135,79','136,23','136,68','137,13','137,58','138,03','138,48','138,92','139,37','139,82','139,82','139,82','139,82','139,82','139,82','139,82','139,82','139,82','139,82','139,82','139,82'],
    per_person_max_VLG:['174,54','175,21','175,88','176,55','177,21','177,88','178,55','179,22','179,89','180,56','181,22','181,89','182,56','183,19','183,83','184,46','185,09','185,72','186,36','186,99','187,62','188,25','188,89','189,52','190,15','192,56','194,97','197,38','199,78','202,19','204,6','207,01','209,42','211,83','214,23','216,64','219,05','219,62','220,2','220,77','221,34','221,92','222,49','223,06','223,64','224,21','224,78','225,36','225,93','226,49','227,05','227,62','228,18','228,74','229,3','229,86','230,42','230,99','231,55','232,11','232,67','232,67','232,67','232,67','232,67','232,67','232,67','232,67','232,67','232,67','232,67','232,67'],
    per_person_max_VOR:['165,27','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_YEV:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_ZAB:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_IVA:['88,01','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_IRK:['104,18','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_KGD:['127,69','128,12','128,54','128,97','129,39','129,82','130,25','130,67','131,1','131,52','131,95','132,37','132,8','133,24','133,69','134,13','134,57','135,01','135,46','135,9','136,34','136,78','137,23','137,67','138,11','138,57','139,03','139,49','139,95','140,41','140,87','141,33','141,79','142,25','142,71','143,17','143,63','144,11','144,59','145,07','145,55','146,03','146,51','146,98','147,46','147,94','148,42','148,9','149,38','149,88','150,38','150,87','151,37','151,87','152,37','152,86','153,36','153,86','154,36','154,85','155,35','155,35','155,35','155,35','155,35','155,35','155,35','155,35','155,35','155,35','155,35','155,35'],
    per_person_max_KLU:['107','107,36','107,71','108,07','108,43','108,78','109,14','109,5','109,85','110,21','110,57','110,92','111,28','111,65','112,02','112,39','112,76','113,13','113,51','113,88','114,25','114,62','114,99','115,36','115,73','116,12','116,5','116,89','117,27','117,66','118,05','118,43','118,82','119,2','119,59','119,97','120,36','120,76','121,16','121,56','121,96','122,36','122,77','123,17','123,57','123,97','124,37','124,77','125,17','125,59','126,01','126,42','126,84','127,26','127,68','128,09','128,51','128,93','129,35','129,76','130,18','130,18','130,18','130,18','130,18','130,18','130,18','130,18','130,18','130,18','130,18','130,18'],
    per_person_max_KAM:['218,01','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_KEM:['88,94','89,24','89,53','89,83','90,13','90,42','90,72','91,02','91,31','91,61','91,91','92,2','92,5','92,81','93,12','93,43','93,73','94,04','94,35','94,66','94,97','95,28','95,58','95,89','96,2','96,52','96,84','97,16','97,48','97,8','98,13','98,45','98,77','99,09','99,41','99,73','100,05','100,38','100,72','101,05','101,38','101,72','102,05','102,38','102,72','103,05','103,38','103,72','104,05','104,4','104,74','105,09','105,44','105,78','106,13','106,48','106,82','107,17','107,52','107,86','108,21','108,21','108,21','108,21','108,21','108,21','108,21','108,21','108,21','108,21','108,21','108,21'],
    per_person_max_KIR:['140','141,75','143,5','145,25','147','148,75','150,5','152,25','154','155,75','157,5','159,25','161','161,92','162,83','163,75','164,67','165,58','166,5','167,42','168,33','169,25','170,17','171,08','172','173,75','175,5','177,25','179','180,75','182,5','184,25','186','187,75','189,5','191,25','193','193,25','193,5','193,75','194','194,25','194,5','194,75','195','195,25','195,5','195,75','196','196,42','196,83','197,25','197,67','198,08','198,5','198,92','199,33','199,75','200,17','200,58','201','201','201','201','201','201','201','201','201','201','201','201'],
    per_person_max_KOS:['127,59','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_KDA:['79,64','79,91','80,17','80,44','80,7','80,97','81,24','81,5','81,77','82,03','82,3','82,56','82,83','83,11','83,38','83,66','83,93','84,21','84,49','84,76','85,04','85,31','85,59','85,86','86,14','86,43','86,72','87','87,29','87,58','87,87','88,15','88,44','88,73','89,02','89,3','89,59','89,89','90,19','90,49','90,78','91,08','91,38','91,68','91,98','92,28','92,57','92,87','93,17','93,48','93,79','94,1','94,41','94,72','95,04','95,35','95,66','95,97','96,28','96,59','96,9','96,9','96,9','96,9','96,9','96,9','96,9','96,9','96,9','96,9','96,9','96,9'],
    per_person_max_KYA:['273,66','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_KGN:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_KRS:['72','72,24','72,48','72,72','72,96','73,2','73,44','73,68','73,92','74,16','74,4','74,64','74,88','75,13','75,38','75,63','75,88','76,13','76,38','76,63','76,88','77,13','77,38','77,63','77,88','78,14','78,4','78,66','78,92','79,18','79,44','79,69','79,95','80,21','80,47','80,73','80,99','81,26','81,53','81,8','82,07','82,34','82,61','82,88','83,15','83,42','83,69','83,96','84,23','84,51','84,79','85,07','85,35','85,63','85,92','86,2','86,48','86,76','87,04','87,32','87,6','87,6','87,6','87,6','87,6','87,6','87,6','87,6','87,6','87,6','87,6','87,6'],
    per_person_max_LEN:['122,81','123,22','123,63','124,04','124,45','124,86','125,27','125,67','126,08','126,49','126,9','127,31','127,72','128,15','128,57','129','129,42','129,85','130,28','130,7','131,13','131,55','131,98','132,4','132,83','133,27','133,72','134,16','134,6','135,04','135,49','135,93','136,37','136,81','137,26','137,7','138,14','138,6','139,06','139,52','139,98','140,44','140,91','141,37','141,83','142,29','142,75','143,21','143,67','144,15','144,63','145,11','145,59','146,07','146,55','147,02','147,5','147,98','148,46','148,94','149,42','149,42','149,42','149,42','149,42','149,42','149,42','149,42','149,42','149,42','149,42','149,42'],
    per_person_max_LIP:['149,97','160,97','171,97','182,97','193,97','204,97','215,98','226,98','237,98','248,98','259,98','270,98','281,98','286,68','291,38','296,08','300,77','305,47','310,17','314,87','319,57','324,27','328,96','333,66','338,36','341,18','344,01','346,83','349,65','352,48','355,3','358,12','360,95','363,77','366,59','369,42','372,24','372,98','373,72','374,46','375,2','375,94','376,69','377,43','378,17','378,91','379,65','380,39','381,13','382,52','383,92','385,31','386,7','388,09','389,49','390,88','392,27','393,66','395,06','396,45','397,84','397,84','397,84','397,84','397,84','397,84','397,84','397,84','397,84','397,84','397,84','397,84'],
    per_person_max_MAG:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_MOW:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_MOS:['162,37','162,91','163,45','163,99','164,53','165,07','165,62','166,16','166,7','167,24','167,78','168,32','168,86','169,42','169,99','170,55','171,11','171,67','172,24','172,8','173,36','173,92','174,49','175,05','175,61','176,2','176,78','177,37','177,95','178,54','179,12','179,71','180,29','180,88','181,46','182,05','182,63','183,24','183,85','184,46','185,07','185,68','186,29','186,89','187,5','188,11','188,72','189,33','189,94','190,57','191,21','191,84','192,47','193,11','193,74','194,37','195,01','195,64','196,27','196,91','197,54','197,54','197,54','197,54','197,54','197,54','197,54','197,54','197,54','197,54','197,54','197,54'],
    per_person_max_MUR:['133,56','134,01','134,45','134,9','135,34','135,79','136,23','136,68','137,12','137,57','138,01','138,46','138,9','139,36','139,83','140,29','140,75','141,22','141,68','142,14','142,61','143,07','143,53','144','144,46','144,94','145,42','145,91','146,39','146,87','147,35','147,83','148,31','148,8','149,28','149,76','150,24','150,74','151,24','151,74','152,24','152,74','153,25','153,75','154,25','154,75','155,25','155,75','156,25','156,77','157,29','157,81','158,33','158,85','159,38','159,9','160,42','160,94','161,46','161,98','162,5','162,5','162,5','162,5','162,5','162,5','162,5','162,5','162,5','162,5','162,5','162,5'],
    per_person_max_NEN:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_NIZ:['144,6','145,08','145,56','146,05','146,53','147,01','147,49','147,97','148,45','148,94','149,42','149,9','150,38','150,88','151,38','151,89','152,39','152,89','153,39','153,89','154,39','154,9','155,4','155,9','156,4','156,92','157,44','157,97','158,49','159,01','159,53','160,05','160,57','161,1','161,62','162,14','162,66','163,2','163,75','164,29','164,83','165,37','165,92','166,46','167','167,54','168,09','168,63','169,17','169,73','170,3','170,86','171,43','171,99','172,56','173,12','173,68','174,25','174,81','175,38','175,94','175,94','175,94','175,94','175,94','175,94','175,94','175,94','175,94','175,94','175,94','175,94'],
    per_person_max_NGR:['78,08','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_NVS:['92,42','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_OMS:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_ORE:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_ORL:['98','98,33','98,65','98,98','99,31','99,63','99,96','100,29','100,61','100,94','101,27','101,59','101,92','102,26','102,6','102,94','103,28','103,62','103,96','104,3','104,64','104,98','105,32','105,66','106','106,35','106,71','107,06','107,41','107,77','108,12','108,47','108,83','109,18','109,53','109,89','110,24','110,61','110,98','111,34','111,71','112,08','112,45','112,81','113,18','113,55','113,92','114,28','114,65','115,03','115,41','115,8','116,18','116,56','116,94','117,32','117,7','118,09','118,47','118,85','119,23','119,23','119,23','119,23','119,23','119,23','119,23','119,23','119,23','119,23','119,23','119,23'],
    per_person_max_PNZ:['128,17','129,24','130,31','131,38','132,45','133,52','134,59','135,65','136,72','137,79','138,86','139,93','141','142,67','144,33','146','147,67','149,33','151','152,67','154,33','156','157,67','159,33','161','165,5','170','174,5','179','183,5','188','192,5','197','201,5','206','210,5','215','215,67','216,33','217','217,67','218,33','219','219,67','220,33','221','221,67','222,33','223','223,58','224,17','224,75','225,33','225,92','226,5','227,08','227,67','228,25','228,83','229,42','230','230','230','230','230','230','230','230','230','230','230','230'],
    per_person_max_PER:['8,64','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_PRI:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_PSK:['94,94','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_AD:['106,91','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_AL:['105,86','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_BA:['105,7','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_BU:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_DA:['48,34','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_IN:['114,61','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_KB:['80,97','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_KL:['95,32','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_KC:['117,5','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_KR:['95,48','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_KO:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_CR:['138,64','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_ME:['85,86','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_MO:['90,28','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_SA:['116,25','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_SE:['45,95','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_TA:['93,66','97,35','101,05','104,74','108,44','112,13','115,83','119,52','123,21','126,91','130,6','134,3','137,99','138,86','139,74','140,61','141,48','142,35','143,23','144,1','144,97','145,84','146,72','147,59','148,46','155,74','163,02','170,3','177,57','184,85','192,13','199,41','206,69','213,97','221,24','228,52','235,8','236,21','236,61','237,02','237,43','237,83','238,24','238,65','239,05','239,46','239,87','240,27','240,68','240,99','241,29','241,6','241,9','242,21','242,51','242,82','243,12','243,43','243,73','244,04','244,34','244,34','244,34','244,34','244,34','244,34','244,34','244,34','244,34','244,34','244,34','244,34'],
    per_person_max_TY:['91,82','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_UD:['98,48','98,81','99,14','99,47','99,79','100,12','100,45','100,78','101,11','101,44','101,76','102,09','102,42','102,76','103,1','103,45','103,79','104,13','104,47','104,81','105,15','105,5','105,84','106,18','106,52','106,88','107,23','107,59','107,94','108,3','108,65','109,01','109,36','109,72','110,07','110,43','110,78','111,15','111,52','111,89','112,26','112,63','113','113,36','113,73','114,1','114,47','114,84','115,21','115,59','115,98','116,36','116,75','117,13','117,52','117,9','118,28','118,67','119,05','119,44','119,82','119,82','119,82','119,82','119,82','119,82','119,82','119,82','119,82','119,82','119,82','119,82'],
    per_person_max_KK:['138,85','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_CE:['77,98','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_CU:['75,7','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_ROS:['151,66','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_RYA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_SAM:['81,72','89,71','97,69','105,68','113,66','121,65','129,64','137,62','145,61','153,59','161,58','169,56','177,55','178,55','179,54','180,54','181,54','182,53','183,53','184,53','185,52','186,52','187,52','188,51','189,51','193,02','196,54','200,05','203,56','207,07','210,59','214,1','217,61','221,12','224,64','228,15','231,66','232,58','233,51','234,43','235,36','236,28','237,21','238,13','239,05','239,98','240,9','241,83','242,75','243,44','244,13','244,83','245,52','246,21','246,9','247,59','248,28','248,98','249,67','250,36','251,05','251,05','251,05','251,05','251,05','251,05','251,05','251,05','251,05','251,05','251,05','251,05'],
    per_person_max_SPE:['196,67','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_SAR:['117,21','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_SAK:['70','70,23','70,47','70,7','70,93','71,17','71,4','71,63','71,87','72,1','72,33','72,57','72,8','73,04','73,29','73,53','73,77','74,01','74,26','74,5','74,74','74,98','75,23','75,47','75,71','75,96','76,22','76,47','76,72','76,97','77,23','77,48','77,73','77,98','78,24','78,49','78,74','79','79,27','79,53','79,79','80,05','80,32','80,58','80,84','81,1','81,37','81,63','81,89','82,16','82,44','82,71','82,98','83,26','83,53','83,8','84,08','84,35','84,62','84,9','85,17','85,17','85,17','85,17','85,17','85,17','85,17','85,17','85,17','85,17','85,17','85,17'],
    per_person_max_SVE:['182','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_SEV:['84,84','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_SMO:['92,26','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_STA:['160,16','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_TAM:['132,5','132,94','133,38','133,83','134,27','134,71','135,15','135,59','136,03','136,48','136,92','137,36','137,8','138,26','138,72','139,18','139,64','140,1','140,56','141,01','141,47','141,93','142,39','142,85','143,31','143,79','144,27','144,74','145,22','145,7','146,18','146,65','147,13','147,61','148,09','148,56','149,04','149,54','150,03','150,53','151,03','151,52','152,02','152,52','153,01','153,51','154,01','154,5','155','155,52','156,03','156,55','157,07','157,58','158,1','158,62','159,13','159,65','160,17','160,68','161,2','161,2','161,2','161,2','161,2','161,2','161,2','161,2','161,2','161,2','161,2','161,2'],
    per_person_max_TVE:['116,81','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_TOM:['248,31','249,14','249,97','250,79','251,62','252,45','253,28','254,1','254,93','255,76','256,59','257,41','258,24','259,1','259,96','260,82','261,68','262,54','263,41','264,27','265,13','265,99','266,85','267,71','268,57','269,47','270,36','271,26','272,15','273,05','273,94','274,84','275,73','276,63','277,52','278,42','279,31','280,24','281,17','282,1','283,03','283,96','284,9','285,83','286,76','287,69','288,62','289,55','290,48','291,45','292,42','293,39','294,35','295,32','296,29','297,26','298,23','299,2','300,16','301,13','302,1','302,1','302,1','302,1','302,1','302,1','302,1','302,1','302,1','302,1','302,1','302,1'],
    per_person_max_TUL:['165,1','165,65','166,2','166,75','167,3','167,85','168,4','168,95','169,5','170,05','170,6','171,15','171,7','172,27','172,85','173,42','173,99','174,56','175,14','175,71','176,28','176,85','177,43','178','178,57','179,17','179,76','180,36','180,95','181,55','182,15','182,74','183,34','183,93','184,53','185,12','185,72','186,34','186,96','187,58','188,19','188,81','189,43','190,05','190,67','191,29','191,9','192,52','193,14','193,78','194,43','195,07','195,72','196,36','197,01','197,65','198,29','198,94','199,58','200,23','200,87','200,87','200,87','200,87','200,87','200,87','200,87','200,87','200,87','200,87','200,87','200,87'],
    per_person_max_TYU:['185,79','186,41','187,03','187,65','188,27','188,89','189,51','190,12','190,74','191,36','191,98','192,6','193,22','193,86','194,51','195,15','195,8','196,44','197,09','197,73','198,37','199,02','199,66','200,31','200,95','201,62','202,29','202,96','203,63','204,3','204,97','205,64','206,31','206,98','207,65','208,32','208,99','209,69','210,38','211,08','211,78','212,47','213,17','213,87','214,56','215,26','215,96','216,65','217,35','218,07','218,8','219,52','220,25','220,97','221,7','222,42','223,14','223,87','224,59','225,32','226,04','226,04','226,04','226,04','226,04','226,04','226,04','226,04','226,04','226,04','226,04','226,04'],
    per_person_max_ULY:['120,6','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_KHA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_KHM:['200,33','201','201,67','202,33','203','203,67','204,34','205','205,67','206,34','207,01','207,67','208,34','209,03','209,73','210,42','211,12','211,81','212,51','213,2','213,89','214,59','215,28','215,98','216,67','217,39','218,12','218,84','219,56','220,28','221,01','221,73','222,45','223,17','223,9','224,62','225,34','226,09','226,84','227,59','228,34','229,09','229,85','230,6','231,35','232,1','232,85','233,6','234,35','235,13','235,91','236,69','237,47','238,25','239,04','239,82','240,6','241,38','242,16','242,94','243,72','243,72','243,72','243,72','243,72','243,72','243,72','243,72','243,72','243,72','243,72','243,72'],
    per_person_max_CHE:['77,3','77,56','77,82','78,07','78,33','78,59','78,85','79,1','79,36','79,62','79,88','80,13','80,39','80,66','80,93','81,2','81,46','81,73','82','82,27','82,54','82,81','83,07','83,34','83,61','83,89','84,17','84,45','84,72','85','85,28','85,56','85,84','86,12','86,39','86,67','86,95','87,24','87,53','87,82','88,11','88,4','88,69','88,98','89,27','89,56','89,85','90,14','90,43','90,73','91,03','91,34','91,64','91,94','92,24','92,54','92,84','93,15','93,45','93,75','94,05','94,05','94,05','94,05','94,05','94,05','94,05','94,05','94,05','94,05','94,05','94,05'],
    per_person_max_CHU:['137,7','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    per_person_max_YAN:['188,59','189,22','189,85','190,48','191,1','191,73','192,36','192,99','193,62','194,25','194,87','195,5','196,13','196,78','197,44','198,09','198,75','199,4','200,06','200,71','201,36','202,02','202,67','203,33','203,98','204,66','205,34','206,02','206,7','207,38','208,06','208,74','209,42','210,1','210,78','211,46','212,14','212,85','213,56','214,26','214,97','215,68','216,39','217,09','217,8','218,51','219,22','219,92','220,63','221,37','222,1','222,84','223,57','224,31','225,05','225,78','226,52','227,25','227,99','228,72','229,46','229,46','229,46','229,46','229,46','229,46','229,46','229,46','229,46','229,46','229,46','229,46'],
    per_person_max_YAR:['85,22','85,5','85,79','86,07','86,36','86,64','86,93','87,21','87,49','87,78','88,06','88,35','88,63','88,93','89,22','89,52','89,81','90,11','90,4','90,7','90,99','91,29','91,58','91,88','92,17','92,48','92,79','93,09','93,4','93,71','94,02','94,32','94,63','94,94','95,25','95,55','95,86','96,18','96,5','96,82','97,14','97,46','97,78','98,1','98,42','98,74','99,06','99,38','99,7','100,03','100,36','100,7','101,03','101,36','101,69','102,02','102,35','102,69','103,02','103,35','103,68','103,68','103,68','103,68','103,68','103,68','103,68','103,68','103,68','103,68','103,68','103,68'],
    per_person_max_all:['273,66','249,14','249,97','250,79','251,62','252,45','253,28','254,1','254,93','255,76','259,98','270,98','281,98','286,68','291,38','296,08','300,77','305,47','310,17','314,87','319,57','324,27','328,96','333,66','338,36','341,18','344,01','346,83','349,65','352,48','355,3','358,12','360,95','363,77','366,59','369,42','372,24','372,98','373,72','374,46','375,2','375,94','376,69','377,43','378,17','378,91','379,65','380,39','381,13','382,52','383,92','385,31','386,7','388,09','389,49','390,88','392,27','393,66','395,06','396,45','397,84','397,84','397,84','397,84','397,84','397,84','397,84','397,84','397,84','397,84','397,84','397,84'],
    tarif_min_ALT:['303,61','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_AMU:['516,92','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_ARK:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_AST:['418,11','419,5','420,9','422,29','423,69','425,08','426,48','427,87','429,26','430,66','432,05','433,45','434,84','436,29','437,74','439,19','440,64','442,09','443,54','444,98','446,43','447,88','449,33','450,78','452,23','453,74','455,25','456,75','458,26','459,77','461,28','462,78','464,29','465,8','467,31','468,81','470,32','471,89','473,46','475,02','476,59','478,16','479,73','481,29','482,86','484,43','486','487,56','489,13','490,76','492,39','494,02','495,65','497,28','498,92','500,55','502,18','503,81','505,44','507,07','508,7','508,7','508,7','508,7','508,7','508,7','508,7','508,7','508,7','508,7','508,7','508,7'],
    tarif_min_BEL:['533,44','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_BRY:['526,76','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_VLA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_VGG:['623,25','625,33','627,41','629,48','631,56','633,64','635,72','637,79','639,87','641,95','644,03','646,1','648,18','650,34','652,5','654,66','656,82','658,98','661,14','663,3','665,46','667,62','669,78','671,94','674,1','676,35','678,6','680,84','683,09','685,34','687,59','689,83','692,08','694,33','696,58','698,82','701,07','703,41','705,74','708,08','710,42','712,75','715,09','717,43','719,76','722,1','724,44','726,77','729,11','731,54','733,97','736,4','738,83','741,26','743,69','746,12','748,55','750,98','753,41','755,84','758,27','758,27','758,27','758,27','758,27','758,27','758,27','758,27','758,27','758,27','758,27','758,27'],
    tarif_min_VLG:['912,72','913,83','914,94','916,04','917,15','918,26','919,37','920,47','921,58','922,69','923,8','924,9','926,01','929,47','932,93','936,39','939,84','943,3','946,76','950,22','953,68','957,14','960,59','964,05','967,51','983,62','999,74','1015,85','1031,96','1048,08','1064,19','1080,3','1096,42','1112,53','1128,64','1144,76','1160,87','1164,27','1167,67','1171,07','1174,47','1177,87','1181,27','1184,66','1188,06','1191,46','1194,86','1198,26','1201,66','1204,9','1208,14','1211,38','1214,62','1217,86','1221,1','1224,33','1227,57','1230,81','1234,05','1237,29','1240,53','1240,53','1240,53','1240,53','1240,53','1240,53','1240,53','1240,53','1240,53','1240,53','1240,53','1240,53'],
    tarif_min_VOR:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_YEV:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_ZAB:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_IVA:['488,95','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_IRK:['522,89','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_KGD:['734,28','736,73','739,18','741,62','744,07','746,52','748,97','751,41','753,86','756,31','758,76','761,2','763,65','766,2','768,74','771,29','773,83','776,38','778,92','781,47','784,01','786,56','789,1','791,65','794,19','796,84','799,49','802,13','804,78','807,43','810,08','812,72','815,37','818,02','820,67','823,31','825,96','828,71','831,47','834,22','836,97','839,73','842,48','845,23','847,99','850,74','853,49','856,25','859','861,86','864,73','867,59','870,45','873,32','876,18','879,04','881,91','884,77','887,63','890,5','893,36','893,36','893,36','893,36','893,36','893,36','893,36','893,36','893,36','893,36','893,36','893,36'],
    tarif_min_KLU:['499,25','500,91','502,58','504,24','505,91','507,57','509,24','510,9','512,56','514,23','515,89','517,56','519,22','520,95','522,68','524,41','526,14','527,87','529,61','531,34','533,07','534,8','536,53','538,26','539,99','541,79','543,59','545,39','547,19','548,99','550,79','552,59','554,39','556,19','557,99','559,79','561,59','563,46','565,34','567,21','569,08','570,95','572,83','574,7','576,57','578,44','580,32','582,19','584,06','586,01','587,95','589,9','591,85','593,79','595,74','597,69','599,63','601,58','603,53','605,47','607,42','607,42','607,42','607,42','607,42','607,42','607,42','607,42','607,42','607,42','607,42','607,42'],
    tarif_min_KAM:['916','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_KEM:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_KIR:['905,53','917,14','928,75','940,36','951,97','963,58','975,19','986,79','998,4','1010,01','1021,62','1033,23','1044,84','1050,65','1056,45','1062,26','1068,06','1073,87','1079,67','1085,48','1091,28','1097,09','1102,89','1108,7','1114,5','1126,11','1137,72','1149,33','1160,94','1172,55','1184,16','1195,76','1207,37','1218,98','1230,59','1242,2','1253,81','1255,1','1256,39','1257,68','1258,97','1260,26','1261,55','1262,84','1264,13','1265,42','1266,71','1268','1269,29','1271,87','1274,45','1277,03','1279,61','1282,19','1284,77','1287,35','1289,93','1292,51','1295,09','1297,67','1300,25','1300,25','1300,25','1300,25','1300,25','1300,25','1300,25','1300,25','1300,25','1300,25','1300,25','1300,25'],
    tarif_min_KOS:['497,07','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_KDA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_KYA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_KGN:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_KRS:['507,16','508,85','510,54','512,23','513,92','515,61','517,3','518,99','520,68','522,37','524,06','525,75','527,44','529,2','530,96','532,72','534,47','536,23','537,99','539,75','541,51','543,27','545,02','546,78','548,54','550,37','552,2','554,03','555,85','557,68','559,51','561,34','563,17','565','566,82','568,65','570,48','572,38','574,28','576,19','578,09','579,99','581,89','583,79','585,69','587,6','589,5','591,4','593,3','595,28','597,26','599,23','601,21','603,19','605,17','607,14','609,12','611,1','613,08','615,05','617,03','617,03','617,03','617,03','617,03','617,03','617,03','617,03','617,03','617,03','617,03','617,03'],
    tarif_min_LEN:['514,93','516,65','518,36','520,08','521,8','523,51','525,23','526,95','528,66','530,38','532,1','533,81','535,53','537,32','539,1','540,89','542,67','544,46','546,24','548,03','549,81','551,6','553,38','555,17','556,95','558,81','560,66','562,52','564,38','566,23','568,09','569,95','571,8','573,66','575,52','577,37','579,23','581,16','583,09','585,02','586,95','588,88','590,82','592,75','594,68','596,61','598,54','600,47','602,4','604,41','606,42','608,42','610,43','612,44','614,45','616,45','618,46','620,47','622,48','624,48','626,49','626,49','626,49','626,49','626,49','626,49','626,49','626,49','626,49','626,49','626,49','626,49'],
    tarif_min_LIP:['457,57','489,79','522,02','554,24','586,46','618,69','650,91','683,13','715,36','747,58','779,8','812,03','844,25','848,67','853,1','857,52','861,95','866,37','870,8','875,22','879,64','884,07','888,49','892,92','897,34','918,97','940,59','962,22','983,84','1005,47','1027,1','1048,72','1070,35','1091,97','1113,6','1135,22','1156,85','1160,23','1163,61','1166,99','1170,37','1173,75','1177,13','1180,5','1183,88','1187,26','1190,64','1194,02','1197,4','1199,2','1201','1202,8','1204,59','1206,39','1208,19','1209,99','1211,79','1213,59','1215,38','1217,18','1218,98','1218,98','1218,98','1218,98','1218,98','1218,98','1218,98','1218,98','1218,98','1218,98','1218,98','1218,98'],
    tarif_min_MAG:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_MOW:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_MOS:['739,67','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_MUR:['890,42','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_NEN:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_NIZ:['593,54','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_NGR:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_NVS:['466','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_OMS:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_ORE:['648,65','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_ORL:['469,46','471,02','472,59','474,15','475,72','477,28','478,85','480,41','481,97','483,54','485,1','486,67','488,23','489,86','491,49','493,11','494,74','496,37','498','499,62','501,25','502,88','504,51','506,13','507,76','509,45','511,15','512,84','514,53','516,22','517,92','519,61','521,3','522,99','524,69','526,38','528,07','529,83','531,59','533,35','535,11','536,87','538,64','540,4','542,16','543,92','545,68','547,44','549,2','551,03','552,86','554,69','556,52','558,35','560,18','562,01','563,84','565,67','567,5','569,33','571,16','571,16','571,16','571,16','571,16','571,16','571,16','571,16','571,16','571,16','571,16','571,16'],
    tarif_min_PNZ:['437,86','442,53','447,19','451,86','456,53','461,19','465,86','470,53','475,19','479,86','484,53','489,19','493,86','515,23','536,6','557,97','579,34','600,71','622,08','643,45','664,82','686,19','707,56','728,93','750,3','752,15','754,01','755,86','757,71','759,56','761,42','763,27','765,12','766,97','768,83','770,68','772,53','774,38','776,24','778,09','779,94','781,8','783,65','785,5','787,36','789,21','791,06','792,92','794,77','796,62','798,48','800,33','802,18','804,03','805,89','807,74','809,59','811,44','813,3','815,15','817','817','817','817','817','817','817','817','817','817','817','817'],
    tarif_min_PER:['518,4','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_PRI:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_PSK:['768,21','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_AD:['608,04','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_AL:['451,47','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_BA:['438,95','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_BU:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_DA:['199,59','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_IN:['458,42','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_KB:['276,02','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_KL:['476,58','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_KC:['433,84','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_KR:['547,15','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_KO:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_CR:['383,83','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_ME:['491,32','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_MO:['492,42','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_SA:['694,58','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_SE:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_TA:['486,58','496,44','506,3','516,16','526,02','535,88','545,75','555,61','565,47','575,33','585,19','595,05','604,91','608,58','612,24','615,91','619,58','623,24','626,91','630,58','634,24','637,91','641,58','645,24','648,91','666,87','684,83','702,8','720,76','738,72','756,68','774,64','792,6','810,57','828,53','846,49','864,45','864,92','865,38','865,85','866,31','866,78','867,24','867,71','868,17','868,64','869,1','869,57','870,03','870,11','870,19','870,27','870,35','870,43','870,52','870,6','870,68','870,76','870,84','870,92','871','871','871','871','871','871','871','871','871','871','871','871'],
    tarif_min_TY:['579,94','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_UD:['681,49','683,76','686,03','688,31','690,58','692,85','695,12','697,39','699,66','701,94','704,21','706,48','708,75','711,11','713,48','715,84','718,2','720,56','722,93','725,29','727,65','730,01','732,38','734,74','737,1','739,56','742,02','744,47','746,93','749,39','751,85','754,3','756,76','759,22','761,68','764,13','766,59','769,15','771,7','774,26','776,81','779,37','781,92','784,48','787,03','789,59','792,14','794,7','797,25','799,91','802,57','805,22','807,88','810,54','813,2','815,85','818,51','821,17','823,83','826,48','829,14','829,14','829,14','829,14','829,14','829,14','829,14','829,14','829,14','829,14','829,14','829,14'],
    tarif_min_KK:['362,48','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_CE:['503,09','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_CU:['449,71','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_ROS:['446,84','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_RYA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_SAM:['605,33','650,28','695,22','740,17','785,11','830,06','875,01','919,95','964,9','1009,84','1054,79','1099,73','1144,68','1151,11','1157,53','1163,96','1170,38','1176,81','1183,24','1189,66','1196,09','1202,51','1208,94','1215,36','1221,79','1244,44','1267,08','1289,73','1312,37','1335,02','1357,67','1380,31','1402,96','1425,6','1448,25','1470,89','1493,54','1499,5','1505,46','1511,42','1517,38','1523,34','1529,31','1535,27','1541,23','1547,19','1553,15','1559,11','1565,07','1569,53','1573,99','1578,45','1582,9','1587,36','1591,82','1596,28','1600,74','1605,2','1609,65','1614,11','1618,57','1618,57','1618,57','1618,57','1618,57','1618,57','1618,57','1618,57','1618,57','1618,57','1618,57','1618,57'],
    tarif_min_SPE:['594,8','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_SAR:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_SAK:['400','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_SVE:['697,76','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_SEV:['377,05','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_SMO:['608,3','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_STA:['462,88','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_TAM:['580,28','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_TVE:['641,52','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_TOM:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_TUL:['568,26','570,15','572,05','573,94','575,84','577,73','579,63','581,52','583,41','585,31','587,2','589,1','590,99','592,96','594,93','596,9','598,87','600,84','602,81','604,78','606,75','608,72','610,69','612,66','614,63','616,68','618,73','620,78','622,82','624,87','626,92','628,97','631,02','633,07','635,11','637,16','639,21','641,34','643,47','645,6','647,73','649,86','652','654,13','656,26','658,39','660,52','662,65','664,78','667','669,21','671,43','673,64','675,86','678,08','680,29','682,51','684,72','686,94','689,15','691,37','691,37','691,37','691,37','691,37','691,37','691,37','691,37','691,37','691,37','691,37','691,37'],
    tarif_min_TYU:['813,67','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_ULY:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_KHA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_KHM:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_CHE:['313,42','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_CHU:['777,2','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_YAN:['746,9','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_min_YAR:['433,49','434,94','436,38','437,83','439,27','440,72','442,16','443,61','445,05','446,5','447,94','449,39','450,83','452,33','453,84','455,34','456,84','458,34','459,85','461,35','462,85','464,35','465,86','467,36','468,86','470,42','471,99','473,55','475,11','476,68','478,24','479,8','481,37','482,93','484,49','486,06','487,62','489,25','490,87','492,5','494,12','495,75','497,37','499','500,62','502,25','503,87','505,5','507,12','508,81','510,5','512,19','513,88','515,57','517,27','518,96','520,65','522,34','524,03','525,72','527,41','527,41','527,41','527,41','527,41','527,41','527,41','527,41','527,41','527,41','527,41','527,41'],
    tarif_min_all:['199,59','419,5','420,9','422,29','423,69','425,08','426,48','427,87','429,26','430,66','432,05','433,45','434,84','436,29','437,74','439,19','440,64','442,09','443,54','444,98','446,43','447,88','449,33','450,78','452,23','453,74','455,25','456,75','458,26','459,77','461,28','462,78','464,29','465,8','467,31','468,81','470,32','471,89','473,46','475,02','476,59','478,16','479,73','481,29','482,86','484,43','486','487,56','489,13','490,76','492,39','494,02','495,65','497,28','498,92','500,55','502,18','503,81','505,44','507,07','508,7','508,7','508,7','508,7','508,7','508,7','508,7','508,7','508,7','508,7','508,7','508,7'],
    tarif_max_ALT:['920,95','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_AMU:['615,94','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_ARK:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_AST:['1088,8','1092,43','1096,06','1099,69','1103,32','1106,95','1110,58','1114,21','1117,84','1121,47','1125,1','1128,73','1132,36','1136,13','1139,91','1143,68','1147,46','1151,23','1155,01','1158,78','1162,55','1166,33','1170,1','1173,88','1177,65','1181,58','1185,5','1189,43','1193,35','1197,28','1201,21','1205,13','1209,06','1212,98','1216,91','1220,83','1224,76','1228,84','1232,93','1237,01','1241,09','1245,17','1249,26','1253,34','1257,42','1261,5','1265,59','1269,67','1273,75','1278','1282,24','1286,49','1290,73','1294,98','1299,23','1303,47','1307,72','1311,96','1316,21','1320,45','1324,7','1324,7','1324,7','1324,7','1324,7','1324,7','1324,7','1324,7','1324,7','1324,7','1324,7','1324,7'],
    tarif_max_BEL:['533,44','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_BRY:['526,76','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_VLA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_VGG:['558,65','560,51','562,37','564,24','566,1','567,96','569,82','571,68','573,54','575,41','577,27','579,13','580,99','582,93','584,86','586,8','588,74','590,67','592,61','594,55','596,48','598,42','600,36','602,29','604,23','606,24','608,26','610,27','612,29','614,3','616,32','618,33','620,34','622,36','624,37','626,39','628,4','630,5','632,59','634,69','636,78','638,88','640,97','643,07','645,16','647,26','649,35','651,45','653,54','655,72','657,9','660,08','662,25','664,43','666,61','668,79','670,97','673,15','675,32','677,5','679,68','679,68','679,68','679,68','679,68','679,68','679,68','679,68','679,68','679,68','679,68','679,68'],
    tarif_max_VLG:['786,94','789,95','792,97','795,98','798,99','802,01','805,02','808,03','811,05','814,06','817,07','820,09','823,1','825,95','828,81','831,66','834,51','837,36','840,22','843,07','845,92','848,77','851,63','854,48','857,33','868,19','879,05','889,9','900,76','911,62','922,48','933,33','944,19','955,05','965,91','976,76','987,62','990,21','992,79','995,38','997,96','1000,55','1003,13','1005,72','1008,3','1010,89','1013,47','1016,06','1018,64','1021,17','1023,71','1026,24','1028,77','1031,3','1033,84','1036,37','1038,9','1041,43','1043,97','1046,5','1049,03','1049,03','1049,03','1049,03','1049,03','1049,03','1049,03','1049,03','1049,03','1049,03','1049,03','1049,03'],
    tarif_max_VOR:['591,14','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_YEV:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_ZAB:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_IVA:['488,95','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_IRK:['595,33','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_KGD:['920,96','924,03','927,1','930,17','933,24','936,31','939,38','942,45','945,52','948,59','951,66','954,73','957,8','960,99','964,19','967,38','970,57','973,76','976,96','980,15','983,34','986,53','989,73','992,92','996,11','999,43','1002,75','1006,07','1009,39','1012,71','1016,04','1019,36','1022,68','1026','1029,32','1032,64','1035,96','1039,41','1042,87','1046,32','1049,77','1053,23','1056,68','1060,13','1063,59','1067,04','1070,49','1073,95','1077,4','1080,99','1084,58','1088,17','1091,76','1095,35','1098,95','1102,54','1106,13','1109,72','1113,31','1116,9','1120,49','1120,49','1120,49','1120,49','1120,49','1120,49','1120,49','1120,49','1120,49','1120,49','1120,49','1120,49'],
    tarif_max_KLU:['495,62','497,27','498,93','500,58','502,23','503,88','505,54','507,19','508,84','510,49','512,15','513,8','515,45','517,17','518,89','520,61','522,32','524,04','525,76','527,48','529,2','530,92','532,63','534,35','536,07','537,86','539,64','541,43','543,22','545','546,79','548,58','550,36','552,15','553,94','555,72','557,51','559,37','561,23','563,09','564,94','566,8','568,66','570,52','572,38','574,24','576,09','577,95','579,81','581,74','583,68','585,61','587,54','589,47','591,41','593,34','595,27','597,2','599,14','601,07','603','603','603','603','603','603','603','603','603','603','603','603'],
    tarif_max_KAM:['916','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_KEM:['514,04','515,75','517,47','519,18','520,89','522,61','524,32','526,03','527,75','529,46','531,17','532,89','534,6','536,38','538,17','539,95','541,73','543,51','545,3','547,08','548,86','550,64','552,43','554,21','555,99','557,84','559,7','561,55','563,4','565,26','567,11','568,96','570,82','572,67','574,52','576,38','578,23','580,16','582,09','584,01','585,94','587,87','589,8','591,72','593,65','595,58','597,51','599,43','601,36','603,36','605,37','607,37','609,38','611,38','613,39','615,39','617,39','619,4','621,4','623,41','625,41','625,41','625,41','625,41','625,41','625,41','625,41','625,41','625,41','625,41','625,41','625,41'],
    tarif_max_KIR:['908,65','920,01','931,37','942,73','954,08','965,44','976,8','988,16','999,52','1010,88','1022,23','1033,59','1044,95','1050,9','1056,85','1062,8','1068,75','1074,7','1080,65','1086,6','1092,55','1098,5','1104,45','1110,4','1116,35','1127,71','1139,07','1150,42','1161,78','1173,14','1184,5','1195,85','1207,21','1218,57','1229,93','1241,28','1252,64','1254,26','1255,89','1257,51','1259,13','1260,76','1262,38','1264','1265,63','1267,25','1268,87','1270,5','1272,12','1274,82','1277,53','1280,23','1282,94','1285,64','1288,35','1291,05','1293,75','1296,46','1299,16','1301,87','1304,57','1304,57','1304,57','1304,57','1304,57','1304,57','1304,57','1304,57','1304,57','1304,57','1304,57','1304,57'],
    tarif_max_KOS:['582,14','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_KDA:['360,63','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_KYA:['2487,85','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_KGN:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_KRS:['359,87','361,07','362,27','363,47','364,67','365,87','367,07','368,27','369,47','370,67','371,87','373,07','374,27','375,52','376,77','378,01','379,26','380,51','381,76','383','384,25','385,5','386,75','387,99','389,24','390,54','391,84','393,13','394,43','395,73','397,03','398,32','399,62','400,92','402,22','403,51','404,81','406,16','407,51','408,86','410,21','411,56','412,91','414,25','415,6','416,95','418,3','419,65','421','422,4','423,81','425,21','426,61','428,02','429,42','430,82','432,23','433,63','435,03','436,44','437,84','437,84','437,84','437,84','437,84','437,84','437,84','437,84','437,84','437,84','437,84','437,84'],
    tarif_max_LEN:['515,45','517,17','518,89','520,61','522,32','524,04','525,76','527,48','529,2','530,92','532,63','534,35','536,07','537,86','539,64','541,43','543,22','545','546,79','548,58','550,36','552,15','553,94','555,72','557,51','559,37','561,23','563,09','564,94','566,8','568,66','570,52','572,38','574,24','576,09','577,95','579,81','581,74','583,68','585,61','587,54','589,48','591,41','593,34','595,28','597,21','599,14','601,08','603,01','605,02','607,03','609,04','611,05','613,06','615,07','617,08','619,09','621,1','623,11','625,12','627,13','627,13','627,13','627,13','627,13','627,13','627,13','627,13','627,13','627,13','627,13','627,13'],
    tarif_max_LIP:['486,51','522,2','557,89','593,58','629,27','664,96','700,65','736,33','772,02','807,71','843,4','879,09','914,78','930,02','945,26','960,5','975,74','990,98','1006,23','1021,47','1036,71','1051,95','1067,19','1082,43','1097,67','1106,83','1115,99','1125,15','1134,31','1143,47','1152,63','1161,79','1170,95','1180,11','1189,27','1198,43','1207,59','1209,99','1212,4','1214,8','1217,2','1219,6','1222,01','1224,41','1226,81','1229,21','1231,62','1234,02','1236,42','1240,94','1245,45','1249,97','1254,48','1259','1263,52','1268,03','1272,55','1277,06','1281,58','1286,09','1290,61','1290,61','1290,61','1290,61','1290,61','1290,61','1290,61','1290,61','1290,61','1290,61','1290,61','1290,61'],
    tarif_max_MAG:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_MOW:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_MOS:['949,56','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_MUR:['890,42','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_NEN:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_NIZ:['744,72','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_NGR:['392,02','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_NVS:['466','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_OMS:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_ORE:['648,65','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_ORL:['470,35','471,92','473,49','475,05','476,62','478,19','479,76','481,32','482,89','484,46','486,03','487,59','489,16','490,79','492,42','494,05','495,68','497,31','498,95','500,58','502,21','503,84','505,47','507,1','508,73','510,43','512,12','513,82','515,51','517,21','518,91','520,6','522,3','523,99','525,69','527,38','529,08','530,84','532,61','534,37','536,13','537,9','539,66','541,42','543,19','544,95','546,71','548,48','550,24','552,07','553,91','555,74','557,58','559,41','561,25','563,08','564,91','566,75','568,58','570,42','572,25','572,25','572,25','572,25','572,25','572,25','572,25','572,25','572,25','572,25','572,25','572,25'],
    tarif_max_PNZ:['611,56','616,66','621,76','626,86','631,96','637,06','642,16','647,26','652,36','657,46','662,56','667,66','672,76','680,71','688,66','696,62','704,57','712,52','720,47','728,42','736,37','744,33','752,28','760,23','768,18','789,65','811,12','832,59','854,06','875,53','897,01','918,48','939,95','961,42','982,89','1004,36','1025,83','1029,01','1032,19','1035,37','1038,55','1041,73','1044,92','1048,1','1051,28','1054,46','1057,64','1060,82','1064','1066,78','1069,57','1072,35','1075,13','1077,92','1080,7','1083,48','1086,27','1089,05','1091,83','1094,62','1097,4','1097,4','1097,4','1097,4','1097,4','1097,4','1097,4','1097,4','1097,4','1097,4','1097,4','1097,4'],
    tarif_max_PER:['518,4','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_PRI:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_PSK:['791,18','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_AD:['608,04','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_AL:['774,2','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_BA:['650,45','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_BU:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_DA:['386,72','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_IN:['458,42','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_KB:['306,52','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_KL:['476,58','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_KC:['433,84','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_KR:['547,15','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_KO:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_CR:['639,88','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_ME:['536,62','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_MO:['492,42','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_SA:['750','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_SE:['306,3','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_TA:['417,42','433,89','450,35','466,82','483,28','499,75','516,22','532,68','549,15','565,61','582,08','598,54','615,01','618,9','622,78','626,67','630,56','634,44','638,33','642,22','646,1','649,99','653,88','657,76','661,65','694,09','726,53','758,96','791,4','823,84','856,28','888,71','921,15','953,59','986,03','1018,46','1050,9','1052,71','1054,53','1056,34','1058,16','1059,97','1061,79','1063,6','1065,41','1067,23','1069,04','1070,86','1072,67','1074,03','1075,39','1076,74','1078,1','1079,46','1080,82','1082,17','1083,53','1084,89','1086,25','1087,6','1088,96','1088,96','1088,96','1088,96','1088,96','1088,96','1088,96','1088,96','1088,96','1088,96','1088,96','1088,96'],
    tarif_max_TY:['579,94','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_UD:['121,55','121,96','122,36','122,77','123,17','123,58','123,98','124,39','124,79','125,2','125,6','126,01','126,41','126,83','127,25','127,68','128,1','128,52','128,94','129,36','129,78','130,21','130,63','131,05','131,47','131,91','132,35','132,79','133,22','133,66','134,1','134,54','134,98','135,42','135,85','136,29','136,73','137,19','137,64','138,1','138,55','139,01','139,46','139,92','140,37','140,83','141,28','141,74','142,19','142,66','143,14','143,61','144,09','144,56','145,04','145,51','145,98','146,46','146,93','147,41','147,88','147,88','147,88','147,88','147,88','147,88','147,88','147,88','147,88','147,88','147,88','147,88'],
    tarif_max_KK:['901,61','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_CE:['503,09','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_CU:['449,71','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_ROS:['590,88','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_RYA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_SAM:['605,33','664,48','723,64','782,79','841,94','901,09','960,25','1019,4','1078,55','1137,7','1196,86','1256,01','1315,16','1322,54','1329,93','1337,31','1344,69','1352,08','1359,46','1366,84','1374,23','1381,61','1388,99','1396,38','1403,76','1429,78','1455,8','1481,82','1507,83','1533,85','1559,87','1585,89','1611,91','1637,93','1663,94','1689,96','1715,98','1722,83','1729,68','1736,53','1743,37','1750,22','1757,07','1763,92','1770,77','1777,62','1784,46','1791,31','1798,16','1803,28','1808,41','1813,53','1818,65','1823,78','1828,9','1834,02','1839,15','1844,27','1849,39','1854,52','1859,64','1859,64','1859,64','1859,64','1859,64','1859,64','1859,64','1859,64','1859,64','1859,64','1859,64','1859,64'],
    tarif_max_SPE:['595,96','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_SAR:['586,07','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_SAK:['400','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_SVE:['845,87','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_SEV:['377,05','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_SMO:['608,3','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_STA:['686,4','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_TAM:['580,28','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_TVE:['641,52','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_TOM:['1007,67','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_TUL:['589,24','591,2','593,17','595,13','597,1','599,06','601,03','602,99','604,95','606,92','608,88','610,85','612,81','614,85','616,9','618,94','620,98','623,02','625,07','627,11','629,15','631,19','633,24','635,28','637,32','639,44','641,57','643,69','645,82','647,94','650,07','652,19','654,31','656,44','658,56','660,69','662,81','665,02','667,23','669,44','671,65','673,86','676,07','678,27','680,48','682,69','684,9','687,11','689,32','691,62','693,92','696,22','698,51','700,81','703,11','705,41','707,71','710,01','712,3','714,6','716,9','716,9','716,9','716,9','716,9','716,9','716,9','716,9','716,9','716,9','716,9','716,9'],
    tarif_max_TYU:['813,67','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_ULY:['621,1','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_KHA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_KHM:['731,8','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_CHE:['444,23','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_CHU:['1377','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_YAN:['746,9','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    tarif_max_YAR:['415,6','416,99','418,37','419,76','421,14','422,53','423,91','425,3','426,68','428,07','429,45','430,84','432,22','433,66','435,1','436,54','437,98','439,42','440,87','442,31','443,75','445,19','446,63','448,07','449,51','451,01','452,51','454,01','455,5','457','458,5','460','461,5','463','464,49','465,99','467,49','469,05','470,61','472,17','473,72','475,28','476,84','478,4','479,96','481,52','483,07','484,63','486,19','487,81','489,43','491,05','492,67','494,29','495,92','497,54','499,16','500,78','502,4','504,02','505,64','505,64','505,64','505,64','505,64','505,64','505,64','505,64','505,64','505,64','505,64','505,64'],
    tarif_max_all:['2487,85','1092,43','1096,06','1099,69','1103,32','1106,95','1110,58','1114,21','1117,84','1137,7','1196,86','1256,01','1315,16','1322,54','1329,93','1337,31','1344,69','1352,08','1359,46','1366,84','1374,23','1381,61','1388,99','1396,38','1403,76','1429,78','1455,8','1481,82','1507,83','1533,85','1559,87','1585,89','1611,91','1637,93','1663,94','1689,96','1715,98','1722,83','1729,68','1736,53','1743,37','1750,22','1757,07','1763,92','1770,77','1777,62','1784,46','1791,31','1798,16','1803,28','1808,41','1813,53','1818,65','1823,78','1828,9','1834,02','1839,15','1844,27','1849,39','1854,52','1859,64','1859,64','1859,64','1859,64','1859,64','1859,64','1859,64','1859,64','1859,64','1859,64','1859,64','1859,64'],
    invest_ALT:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_AMU:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_ARK:['1,8','1,8','1,8','1,8','1,8','1,8','1,81','1,81','1,81','1,81','1,81','1,81','1,81','1,86','1,9','1,95','1,99','2,04','2,08','2,13','2,17','2,22','2,26','2,31','2,35','2,35','2,35','2,35','2,35','2,35','2,35','2,35','2,35','2,35','2,35','2,35','2,35','2,36','2,37','2,38','2,38','2,39','2,4','2,41','2,42','2,42','2,43','2,44','2,45','2,45','2,45','2,45','2,45','2,45','2,45','2,45','2,45','2,45','2,45','2,45','2,45','2,45','2,45','2,45','2,45','2,45','2,45','2,45','2,45','2,45','2,45','2,45'],
    invest_AST:['0,377','0,39','0,39','0,4','0,41','0,42','0,43','0,44','0,44','0,45','0,46','0,47','0,48','0,48','0,49','0,5','0,51','0,51','0,52','0,53','0,53','0,54','0,55','0,55','0,56','0,57','0,58','0,58','0,59','0,6','0,6','0,61','0,62','0,62','0,63','0,64','0,65','0,65','0,66','0,67','0,67','0,68','0,69','0,69','0,7','0,71','0,71','0,72','0,73','0,73','0,74','0,75','0,76','0,76','0,77','0,78','0,78','0,79','0,8','0,8','0,81','0,81','0,81','0,81','0,81','0,81','0,81','0,81','0,81','0,81','0,81','0,81'],
    invest_BEL:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_BRY:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_VLA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_VGG:['1,446895','1,45','1,45','1,45','1,45','1,45','1,45','1,45','1,45','1,45','1,45','1,45','1,45','1,45','1,45','1,45','1,45','1,46','1,46','1,46','1,46','1,46','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47','1,47'],
    invest_VLG:['1,82','1,85','1,88','1,91','1,93','1,96','1,99','2,02','2,05','2,08','2,1','2,13','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16','2,16'],
    invest_VOR:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_YEV:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_ZAB:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_IVA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_IRK:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_KGD:['0,217126','0,22','0,22','0,22','0,22','0,22','0,22','0,22','0,22','0,22','0,22','0,22','0,22','0,44','0,67','0,89','1,11','1,34','1,56','1,79','2,01','2,23','2,46','2,68','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91','2,91'],
    invest_KLU:['1,36','1,37','1,39','1,4','1,42','1,43','1,45','1,46','1,47','1,49','1,5','1,52','1,53','1,66','1,79','1,92','2,04','2,17','2,3','2,43','2,56','2,69','2,81','2,94','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07','3,07'],
    invest_KAM:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_KEM:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_KIR:['0,363778','0,38','0,4','0,41','0,43','0,45','0,46','0,48','0,5','0,52','0,53','0,55','0,57','0,61','0,65','0,69','0,73','0,78','0,82','0,86','0,9','0,94','0,99','1,03','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07','1,07'],
    invest_KOS:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_KDA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_KYA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_KGN:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_KRS:['0,0468001151943702','0,12','0,19','0,26','0,34','0,41','0,48','0,56','0,63','0,7','0,77','0,85','0,92','0,98','1,04','1,1','1,16','1,22','1,28','1,33','1,39','1,45','1,51','1,57','1,63','1,64','1,64','1,65','1,66','1,66','1,67','1,67','1,68','1,69','1,69','1,7','1,71','1,71','1,72','1,72','1,73','1,74','1,74','1,75','1,76','1,76','1,77','1,78','1,78','1,79','1,8','1,81','1,81','1,82','1,83','1,84','1,84','1,85','1,86','1,87','1,87','1,87','1,87','1,87','1,87','1,87','1,87','1,87','1,87','1,87','1,87','1,87'],
    invest_LEN:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_LIP:['0,601799649874621','0,63','0,66','0,69','0,72','0,75','0,78','0,81','0,84','0,87','0,9','0,93','0,96','1,07','1,18','1,29','1,39','1,5','1,61','1,72','1,83','1,93','2,04','2,15','2,26','2,26','2,26','2,27','2,27','2,27','2,27','2,28','2,28','2,28','2,28','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,3','2,3','2,3','2,3','2,3','2,3','2,31','2,31','2,31','2,31','2,31','2,31','2,31','2,31','2,31','2,31','2,31','2,31','2,31','2,31'],
    invest_MAG:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_MOW:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_MOS:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_MUR:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_NEN:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_NIZ:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_NGR:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_NVS:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_OMS:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_ORE:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_ORL:['1,026759','1,04','1,04','1,05','1,06','1,07','1,08','1,09','1,1','1,11','1,11','1,12','1,13','1,13','1,13','1,13','1,13','1,13','1,13','1,13','1,13','1,13','1,13','1,13','1,13','1,13','1,14','1,14','1,14','1,14','1,15','1,15','1,15','1,15','1,16','1,16','1,16','1,16','1,16','1,16','1,16','1,16','1,16','1,16','1,16','1,16','1,16','1,16','1,16','1,16','1,16','1,16','1,16','1,16','1,16','1,16','1,16','1,16','1,16','1,16','1,16','1,16','1,16','1,16','1,16','1,16','1,16','1,16','1,16','1,16','1,16','1,16'],
    invest_PNZ:['0','0,19','0,38','0,57','0,76','0,95','1,14','1,33','1,52','1,72','1,91','2,1','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29','2,29'],
    invest_PER:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_PRI:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_PSK:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_AD:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_AL:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_BA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_BU:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_DA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_IN:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_KB:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_KL:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_KC:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_KR:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_KO:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_CR:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_ME:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_MO:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_SA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_SE:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_TA:['0,473','0,47','0,47','0,47','0,47','0,47','0,47','0,47','0,47','0,47','0,47','0,47','0,47','3,17','5,87','8,57','11,26','13,96','16,66','19,36','22,05','24,75','27,45','30,15','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84','32,84'],
    invest_TY:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_UD:['1,99971988142321','2,05','2,1','2,15','2,2','2,25','2,3','2,35','2,41','2,46','2,51','2,56','2,61','2,61','2,61','2,61','2,61','2,61','2,61','2,61','2,61','2,61','2,61','2,61','2,61','2,61','2,61','2,61','2,61','2,61','2,61','2,61','2,61','2,61','2,61','2,61','2,61','2,62','2,62','2,63','2,64','2,65','2,65','2,66','2,67','2,68','2,69','2,69','2,7','2,71','2,73','2,74','2,75','2,77','2,78','2,79','2,81','2,82','2,83','2,84','2,86','2,86','2,86','2,86','2,86','2,86','2,86','2,86','2,86','2,86','2,86','2,86'],
    invest_KK:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_CE:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_CU:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_ROS:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_RYA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_SAM:['0','0,29','0,58','0,87','1,16','1,45','1,74','2,03','2,32','2,61','2,9','3,19','3,48','3,8','4,12','4,44','4,75','5,07','5,39','5,7','6,02','6,34','6,65','6,97','7,29','7,3','7,32','7,33','7,35','7,36','7,38','7,39','7,4','7,42','7,43','7,45','7,46','7,46','7,46','7,46','7,46','7,46','7,46','7,46','7,46','7,46','7,46','7,46','7,46','7,46','7,46','7,46','7,46','7,46','7,46','7,46','7,46','7,46','7,46','7,46','7,46','7,46','7,46','7,46','7,46','7,46','7,46','7,46','7,46','7,46','7,46','7,46'],
    invest_SPE:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_SAR:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_SAK:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_SVE:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_SEV:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_SMO:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_STA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_TAM:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_TVE:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_TOM:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_TUL:['2,43','2,44','2,44','2,45','2,45','2,46','2,46','2,47','2,47','2,48','2,48','2,49','2,49','2,5','2,52','2,53','2,55','2,56','2,58','2,59','2,6','2,62','2,63','2,65','2,66','2,66','2,66','2,66','2,66','2,66','2,66','2,66','2,66','2,66','2,66','2,66','2,66','2,67','2,68','2,69','2,7','2,71','2,72','2,73','2,74','2,75','2,76','2,77','2,78','2,78','2,78','2,78','2,78','2,78','2,78','2,78','2,78','2,78','2,78','2,78','2,78','2,78','2,78','2,78','2,78','2,78','2,78','2,78','2,78','2,78','2,78','2,78'],
    invest_TYU:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_ULY:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_KHA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_KHM:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_CHE:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_CHU:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_YAN:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    invest_YAR:['0,7','0,8','0,89','0,99','1,09','1,18','1,28','1,38','1,47','1,57','1,67','1,76','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86','1,86'],
    invest_all:['14,66','15,49','16,28','17,09','17,91','18,72','19,54','20,37','21,17','22,01','22,79','23,62','24,43','28,07','31,73','35,38','38,99','42,66','46,31','49,95','53,57','57,22','60,87','64,52','68,16','68,19','68,23','68,26','68,3','68,32','68,36','68,39','68,42','68,45','68,48','68,53','68,56','68,59','68,63','68,67','68,7','68,75','68,78','68,82','68,87','68,9','68,94','68,98','69,02','69,04','69,08','69,11','69,14','69,17','69,2','69,23','69,25','69,28','69,32','69,34','69,37','69,37','69,37','69,37','69,37','69,37','69,37','69,37','69,37','69,37','69,37','69,37'],
    rate_disposal_ALT:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_AMU:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_ARK:['0,16413959664164','0,22','0,28','0,35','0,41','0,47','0,53','0,59','0,65','0,71','0,77','0,83','0,89','0,89','0,89','0,89','0,89','0,89','0,89','0,89','0,89','0,89','0,89','0,89','0,89','0,89','0,89','0,89','0,89','0,89','0,89','0,89','0,89','0,89','0,89','0,89','0,89','0,89','0,89','0,89','0,89','0,89','0,89','0,89','0,89','0,89','0,89','0,89','0,89','0,93','0,98','1,03','1,07','1,12','1,16','1,21','1,25','1,3','1,34','1,39','1,43','1,43','1,43','1,43','1,43','1,43','1,43','1,43','1,43','1,43','1,43','1,43'],
    rate_disposal_AST:['8,12112187182446','8,12','8,12','8,12','8,12','8,12','8,12','8,13','8,13','8,13','8,13','8,13','8,13','8,13','8,13','8,13','8,13','8,13','8,13','8,13','8,13','8,13','8,13','8,13','8,13','8,13','8,13','8,13','8,13','8,13','8,13','8,13','8,14','8,14','8,14','8,14','8,14','8,14','8,14','8,14','8,13','8,13','8,13','8,13','8,13','8,13','8,13','8,13','8,13','8,13','8,13','8,13','8,13','8,13','8,13','8,13','8,13','8,13','8,14','8,14','8,14','8,14','8,14','8,14','8,14','8,14','8,14','8,14','8,14','8,14','8,14','8,14'],
    rate_disposal_BEL:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_BRY:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_VLA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_VGG:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_VLG:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_VOR:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_YEV:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_ZAB:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_IVA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_IRK:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_KGD:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_KLU:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_KAM:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_KEM:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_KIR:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_KOS:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_KDA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_KYA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_KGN:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_KRS:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_LEN:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_LIP:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_MAG:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_MOW:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_MOS:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_MUR:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_NEN:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_NIZ:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_NGR:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_NVS:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_OMS:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_ORE:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_ORL:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_PNZ:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_PER:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_PRI:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_PSK:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_AD:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_AL:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_BA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_BU:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_DA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_IN:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_KB:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_KL:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_KC:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_KR:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_KO:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_CR:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_ME:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_MO:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_SA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_SE:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_TA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','2,5','5','7,49','9,99','12,49','14,99','17,48','19,98','22,48','24,98','27,48','29,97','29,93','29,89','29,85','29,81','29,77','29,73','29,69','29,65','29,62','29,58','29,54','29,5','29,46','29,42','29,38','29,34','29,3','29,26','29,22','29,18','29,14','29,1','29,06','29,02','29,02','29,02','29,02','29,02','29,02','29,02','29,02','29,02','29,02','29,02','29,02'],
    rate_disposal_TY:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_UD:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_KK:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_CE:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_CU:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_ROS:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_RYA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_SAM:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_SPE:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_SAR:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_SAK:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_SVE:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_SEV:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_SMO:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_STA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_TAM:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_TVE:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_TOM:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_TUL:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_TYU:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_ULY:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_KHA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_KHM:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_CHE:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_CHU:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_YAN:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_YAR:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_disposal_all:['4,14','4,17','4,2','4,24','4,27','4,3','4,33','4,36','4,39','4,42','4,45','4,48','4,51','4,51','4,51','4,51','4,51','4,51','4,51','4,51','4,51','4,51','4,51','4,51','4,51','3,84','4,67','5,5','6,34','7,17','8','8,83','9,67','10,5','11,34','12,17','13','12,99','12,97','12,96','12,94','12,93','12,92','12,9','12,89','12,88','12,87','12,85','12,84','12,84','12,84','12,85','12,85','12,85','12,85','12,85','12,85','12,86','12,86','12,86','12,86','12,86','12,86','12,86','12,86','12,86','12,86','12,86','12,86','12,86','12,86','12,86'],
    rate_treatment_ALT:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_AMU:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_ARK:['19,1117174235952','22,57','26,02','29,48','32,93','36,38','39,84','43,29','46,75','50,2','53,66','57,11','60,57','60,57','60,57','60,57','60,57','60,57','60,57','60,57','60,57','60,57','60,57','60,57','60,57','62,1','63,63','65,16','66,69','68,22','69,75','71,29','72,82','74,35','75,88','77,41','78,94','78,94','78,94','78,94','78,94','78,94','78,94','78,94','78,94','78,94','78,94','78,94','78,94','79,3','79,65','80,01','80,36','80,71','81,07','81,42','81,78','82,13','82,48','82,84','83,19','83,19','83,19','83,19','83,19','83,19','83,19','83,19','83,19','83,19','83,19','83,19'],
    rate_treatment_AST:['37,1015722679713','38,1','39,11','40,11','41,12','42,12','43,12','44,13','45,13','46,13','47,14','48,14','49,14','53,31','57,48','61,64','65,81','69,98','74,14','78,31','82,48','86,64','90,81','94,98','99,14','99,14','99,14','99,14','99,14','99,14','99,14','99,14','99,14','99,14','99,14','99,14','99,14','99,14','99,14','99,14','99,14','99,14','99,14','99,14','99,14','99,14','99,14','99,14','99,14','99,16','99,17','99,19','99,21','99,23','99,24','99,26','99,28','99,29','99,31','99,33','99,34','99,34','99,34','99,34','99,34','99,34','99,34','99,34','99,34','99,34','99,34','99,34'],
    rate_treatment_BEL:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_BRY:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_VLA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_VGG:['100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100'],
    rate_treatment_VLG:['55,7082585085934','59,4','63,09','66,78','70,47','74,16','77,85','81,55','85,24','88,93','92,62','96,31','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100'],
    rate_treatment_VOR:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_YEV:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_ZAB:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_IVA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_IRK:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_KGD:['8,99878903514869','10,3','11,61','12,91','14,21','15,52','16,82','18,12','19,43','20,73','22,03','23,34','24,64','24,63','24,61','24,6','24,59','24,58','24,56','24,55','24,54','24,53','24,51','24,5','24,49','30,78','37,07','43,37','49,66','55,95','62,24','68,54','74,83','81,12','87,41','93,71','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100'],
    rate_treatment_KLU:['81,5881439876922','82,76','83,94','85,11','86,28','87,46','88,63','89,8','90,98','92,15','93,33','94,5','95,67','96,03','96,39','96,76','97,12','97,48','97,84','98,2','98,56','98,92','99,28','99,64','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100'],
    rate_treatment_KAM:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_KEM:['99,1629548860976','99,17','99,18','99,19','99,2','99,21','99,22','99,23','99,24','99,25','99,26','99,27','99,28','99,3','99,32','99,34','99,35','99,37','99,39','99,41','99,43','99,44','99,46','99,48','99,5','99,5','99,51','99,52','99,52','99,53','99,53','99,54','99,55','99,55','99,56','99,56','99,57','99,57','99,57','99,57','99,57','99,57','99,57','99,57','99,57','99,57','99,57','99,57','99,57','99,57','99,57','99,57','99,57','99,57','99,57','99,57','99,57','99,57','99,57','99,57','99,57','99,57','99,57','99,57','99,57','99,57','99,57','99,57','99,57','99,57','99,57','99,57'],
    rate_treatment_KIR:['45,2028992167089','45,58','45,95','46,33','46,7','47,08','47,46','47,83','48,21','48,58','48,96','49,33','49,71','50,67','51,62','52,58','53,54','54,5','55,46','56,42','57,38','58,34','59,29','60,25','61,21','64,44','67,68','70,91','74,14','77,37','80,61','83,84','87,07','90,3','93,54','96,77','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100'],
    rate_treatment_KOS:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_KDA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_KYA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_KGN:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_KRS:['53,8791563018444','55,16','56,44','57,72','59','60,28','61,56','62,84','64,12','65,4','66,68','67,96','69,24','69,25','69,26','69,27','69,29','69,3','69,31','69,32','69,34','69,35','69,36','69,37','69,39','71,94','74,49','77,04','79,59','82,14','84,69','87,24','89,8','92,35','94,9','97,45','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100'],
    rate_treatment_LEN:['52,9049080447127','52,9','52,89','52,88','52,87','52,86','52,85','52,84','52,83','52,83','52,82','52,81','52,8','52,79','52,78','52,78','52,77','52,76','52,75','52,74','52,74','52,73','52,72','52,71','52,71','52,7','52,69','52,68','52,67','52,67','52,66','52,65','52,64','52,63','52,62','52,62','52,61','52,6','52,59','52,58','52,58','52,57','52,56','52,55','52,54','52,53','52,53','52,52','52,51','52,5','52,49','52,48','52,47','52,47','52,46','52,45','52,44','52,43','52,42','52,42','52,41','52,41','52,41','52,41','52,41','52,41','52,41','52,41','52,41','52,41','52,41','52,41'],
    rate_treatment_LIP:['33,0642601121688','33,06','33,06','33,06','33,05','33,05','33,05','33,04','33,04','33,04','33,03','33,03','33,03','33','32,97','32,94','32,91','32,88','32,85','32,82','32,79','32,76','32,73','32,7','32,67','36,35','40,03','43,7','47,38','51,06','54,74','58,42','62,1','65,78','69,46','73,14','76,82','76,82','76,82','76,82','76,82','76,82','76,82','76,82','76,81','76,81','76,81','76,81','76,81','76,88','76,95','77,03','77,1','77,17','77,24','77,31','77,38','77,45','77,52','77,6','77,67','77,67','77,67','77,67','77,67','77,67','77,67','77,67','77,67','77,67','77,67','77,67'],
    rate_treatment_MAG:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_MOW:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_MOS:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_MUR:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_NEN:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_NIZ:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_NGR:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_NVS:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_OMS:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_ORE:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_ORL:['79,3314884271947','81,05','82,78','84,5','86,22','87,94','89,67','91,39','93,11','94,83','96,56','98,28','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100'],
    rate_treatment_PNZ:['0','0','0','0','0','0','0','0','0','0','0','0','0','7,31','14,62','21,93','29,24','36,55','43,86','51,17','58,48','65,79','73,1','80,41','87,72','88,73','89,73','90,74','91,75','92,76','93,76','94,77','95,78','96,79','97,8','98,8','99,81','99,81','99,81','99,82','99,82','99,82','99,82','99,82','99,82','99,83','99,83','99,83','99,83','99,83','99,83','99,83','99,84','99,84','99,84','99,84','99,84','99,84','99,84','99,84','99,85','99,85','99,85','99,85','99,85','99,85','99,85','99,85','99,85','99,85','99,85','99,85'],
    rate_treatment_PER:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_PRI:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_PSK:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_AD:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_AL:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_BA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_BU:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_DA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_IN:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_KB:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_KL:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_KC:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_KR:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_KO:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_CR:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_ME:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_MO:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_SA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_SE:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_TA:['33,9833707596245','33,99','34','34,01','34,02','34,03','34,03','34,04','34,05','34,06','34,07','34,08','34,09','34,09','34,1','34,11','34,12','34,13','34,14','34,15','34,15','34,16','34,17','34,18','34,19','39,67','45,16','50,64','56,13','61,61','67,09','72,58','78,06','83,55','89,03','94,52','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100'],
    rate_treatment_TY:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_UD:['16,2279635936492','19,18','22,13','25,08','28,03','30,98','33,93','36,88','39,83','42,78','45,73','48,68','51,63','55,2','58,78','62,35','65,92','69,5','73,07','76,64','80,21','83,79','87,36','90,93','94,51','94,5','94,5','94,5','94,5','94,5','94,5','94,5','94,5','94,5','94,5','94,5','94,5','94,96','95,42','95,87','96,33','96,79','97,25','97,71','98,17','98,62','99,08','99,54','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100'],
    rate_treatment_KK:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_CE:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_CU:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_ROS:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_RYA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_SAM:['37,5591279341875','37,69','37,81','37,94','38,07','38,2','38,33','38,45','38,58','38,71','38,84','38,96','39,09','39,69','40,29','40,89','41,49','42,09','42,69','43,29','43,89','44,49','45,09','45,69','46,28','50,76','55,24','59,71','64,19','68,67','73,14','77,62','82,09','86,57','91,05','95,52','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100'],
    rate_treatment_SPE:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_SAR:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_SAK:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_SVE:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_SEV:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_SMO:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_STA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_TAM:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_TVE:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_TOM:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_TUL:['10,4717338841415','15,49','20,51','25,52','30,54','35,56','40,58','45,59','50,61','55,63','60,65','65,66','70,68','73,13','75,57','78,01','80,45','82,9','85,34','87,78','90,23','92,67','95,11','97,56','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100'],
    rate_treatment_TYU:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_ULY:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_KHA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_KHM:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_CHE:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_CHU:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_YAN:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_treatment_YAR:['53,8173487332422','55,56','57,3','59,04','60,77','62,51','64,25','65,99','67,73','69,47','71,21','72,95','74,69','76,8','78,91','81,02','83,13','85,24','87,34','89,45','91,56','93,67','95,78','97,89','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100','100'],
    rate_treatment_all:['48,12','49,53','50,93','52,33','53,73','55,14','56,54','57,94','59,35','60,75','62,15','63,55','64,96','62,54','63,74','64,93','66,13','67,32','68,52','69,71','70,91','72,1','73,3','74,49','75,69','77,26','78,83','80,4','81,96','83,53','85,1','86,67','88,24','89,81','91,38','92,95','94,52','94,55','94,57','94,6','94,62','94,65','94,67','94,7','94,72','94,75','94,77','94,8','94,82','94,85','94,87','94,9','94,92','94,94','94,97','94,99','95,02','95,04','95,06','95,09','95,11','95,11','95,11','95,11','95,11','95,11','95,11','95,11','95,11','95,11','95,11','95,11'],
    rate_used_ALT:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_AMU:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_ARK:['0,0286675746585783','0,05','0,06','0,08','0,1','0,12','0,14','0,15','0,17','0,19','0,21','0,22','0,24','0,24','0,24','0,24','0,24','0,24','0,24','0,24','0,24','0,24','0,24','0,24','0,24','0,25','0,25','0,26','0,27','0,27','0,28','0,29','0,29','0,3','0,3','0,31','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,33','0,33','0,33','0,33','0,33','0,33','0,33','0,33','0,33','0,33','0,33','0,33','0,33','0,33','0,33','0,33','0,33'],
    rate_used_AST:['0,129855504689918','0,13','0,14','0,14','0,15','0,15','0,16','0,16','0,16','0,17','0,17','0,18','0,18','0,19','0,19','0,2','0,2','0,2','0,21','0,21','0,22','0,22','0,23','0,23','0,24','0,24','0,24','0,24','0,24','0,24','0,24','0,25','0,25','0,25','0,25','0,25','0,25','0,25','0,26','0,26','0,26','0,26','0,26','0,26','0,26','0,27','0,27','0,27','0,27','0,27','0,27','0,28','0,28','0,28','0,28','0,28','0,28','0,28','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29'],
    rate_used_BEL:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_BRY:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_VLA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_VGG:['0,0609947887426986','0,07','0,08','0,09','0,11','0,12','0,13','0,14','0,15','0,16','0,17','0,18','0,19','0,2','0,21','0,23','0,24','0,25','0,26','0,27','0,28','0,29','0,3','0,31','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,33','0,33','0,33','0,33','0,33','0,33','0,33','0,33','0,33','0,33','0,33','0,33','0,34','0,34','0,34','0,34','0,34','0,34','0,34','0,34','0,34','0,34','0,34','0,34','0,34','0,34','0,34','0,34','0,34','0,34','0,34','0,34','0,34','0,34','0,34','0,34','0,34','0,34','0,34'],
    rate_used_VLG:['0,0786214796573455','0,08','0,09','0,09','0,1','0,11','0,11','0,12','0,12','0,13','0,13','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14','0,14'],
    rate_used_VOR:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_YEV:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_ZAB:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_IVA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_IRK:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_KGD:['0,00899878273599605','0,01','0,01','0,01','0,02','0,02','0,02','0,02','0,02','0,02','0,03','0,03','0,03','0,03','0,03','0,03','0,03','0,03','0,03','0,03','0,03','0,03','0,03','0,03','0,03','0,05','0,08','0,1','0,13','0,15','0,17','0,2','0,22','0,25','0,27','0,3','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32','0,32'],
    rate_used_KLU:['0,246942822128573','0,26','0,27','0,28','0,29','0,3','0,31','0,32','0,33','0,34','0,35','0,36','0,37','0,37','0,37','0,37','0,37','0,37','0,37','0,37','0,37','0,38','0,38','0,38','0,38','0,38','0,39','0,39','0,4','0,4','0,41','0,42','0,42','0,43','0,43','0,44','0,44','0,44','0,44','0,44','0,44','0,44','0,44','0,44','0,44','0,44','0,44','0,44','0,44','0,44','0,44','0,44','0,44','0,44','0,44','0,44','0,44','0,44','0,44','0,44','0,44','0,44','0,44','0,44','0,44','0,44','0,44','0,44','0,44','0,44','0,44','0,44'],
    rate_used_KAM:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_KEM:['0,101890970875759','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1'],
    rate_used_KIR:['0,0226014455569198','0,02','0,02','0,03','0,03','0,03','0,03','0,03','0,03','0,03','0,03','0,03','0,03','0,03','0,04','0,04','0,04','0,04','0,04','0,04','0,04','0,04','0,05','0,05','0,05','0,05','0,06','0,06','0,07','0,07','0,07','0,08','0,08','0,09','0,09','0,1','0,1','0,1','0,1','0,11','0,11','0,11','0,11','0,11','0,11','0,12','0,12','0,12','0,12','0,12','0,12','0,12','0,12','0,12','0,12','0,13','0,13','0,13','0,13','0,13','0,13','0,13','0,13','0,13','0,13','0,13','0,13','0,13','0,13','0,13','0,13','0,13'],
    rate_used_KOS:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_KDA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_KYA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_KGN:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_KRS:['0,0122598781270296','0,01','0,01','0,01','0,01','0,02','0,02','0,02','0,02','0,02','0,02','0,02','0,02','0,02','0,02','0,02','0,02','0,02','0,02','0,02','0,02','0,02','0,02','0,02','0,02','0,03','0,03','0,03','0,04','0,04','0,04','0,05','0,05','0,05','0,05','0,06','0,06','0,06','0,07','0,07','0,07','0,08','0,08','0,08','0,09','0,09','0,09','0,1','0,1','0,1','0,1','0,1','0,1','0,11','0,11','0,11','0,11','0,11','0,11','0,11','0,11','0,11','0,11','0,11','0,11','0,11','0,11','0,11','0,11','0,11','0,11','0,11'],
    rate_used_LEN:['0,0503807254722952','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05'],
    rate_used_LIP:['0,0101838005809139','0,01','0,01','0,01','0,01','0,01','0,01','0,01','0,01','0,01','0,01','0,01','0,01','0,01','0,01','0,01','0,01','0,01','0,01','0,01','0,01','0,01','0,01','0,01','0,01','0,02','0,02','0,02','0,02','0,02','0,03','0,03','0,03','0,03','0,03','0,04','0,04','0,04','0,04','0,04','0,04','0,04','0,04','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,05','0,06','0,06','0,06','0,06','0,06','0,06','0,06','0,06','0,06','0,06','0,06','0,06','0,06','0,06','0,06','0,06','0,06','0,06','0,06'],
    rate_used_MAG:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_MOW:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_MOS:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_MUR:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_NEN:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_NIZ:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_NGR:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_NVS:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_OMS:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_ORE:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_ORL:['0,314506124098691','0,32','0,32','0,33','0,33','0,34','0,34','0,35','0,35','0,36','0,36','0,37','0,37','0,38','0,38','0,38','0,38','0,38','0,39','0,39','0,39','0,39','0,39','0,39','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4','0,4'],
    rate_used_PNZ:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0,01','0,01','0,02','0,02','0,03','0,03','0,04','0,04','0,05','0,05','0,06','0,06','0,07','0,07','0,08','0,08','0,09','0,09','0,1','0,1','0,11','0,11','0,12','0,12','0,13','0,13','0,13','0,14','0,14','0,14','0,14','0,15','0,15','0,15','0,16','0,16','0,16','0,16','0,16','0,17','0,17','0,17','0,17','0,17','0,18','0,18','0,18','0,18','0,18','0,18','0,18','0,18','0,18','0,18','0,18','0,18','0,18','0,18'],
    rate_used_PER:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_PRI:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_PSK:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_AD:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_AL:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_BA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_BU:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_DA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_IN:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_KB:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_KL:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_KC:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_KR:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_KO:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_CR:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_ME:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_MO:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_SA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_SE:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_TA:['0,0169916869213234','0,02','0,02','0,02','0,02','0,02','0,02','0,02','0,02','0,02','0,02','0,02','0,02','0,02','0,02','0,02','0,02','0,02','0,02','0,02','0,02','0,02','0,02','0,02','0,02','0,02','0,02','0,03','0,03','0,03','0,04','0,04','0,05','0,05','0,05','0,06','0,06','0,06','0,06','0,06','0,07','0,07','0,07','0,07','0,07','0,07','0,07','0,07','0,07','0,08','0,08','0,08','0,08','0,08','0,08','0,08','0,09','0,09','0,09','0,09','0,09','0,09','0,09','0,09','0,09','0,09','0,09','0,09','0,09','0,09','0,09','0,09'],
    rate_used_TY:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_UD:['0,022719134060011','0,03','0,03','0,03','0,03','0,04','0,04','0,04','0,05','0,05','0,05','0,06','0,06','0,07','0,07','0,08','0,09','0,09','0,1','0,11','0,11','0,12','0,13','0,13','0,14','0,14','0,14','0,14','0,14','0,15','0,15','0,15','0,15','0,15','0,15','0,15','0,15','0,15','0,15','0,15','0,15','0,16','0,16','0,16','0,16','0,16','0,16','0,16','0,16','0,16','0,16','0,16','0,16','0,16','0,16','0,16','0,16','0,16','0,16','0,16','0,16','0,16','0,16','0,16','0,16','0,16','0,16','0,16','0,16','0,16','0,16','0,16'],
    rate_used_KK:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_CE:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_CU:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_ROS:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_RYA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_SAM:['0,0375591313334207','0,04','0,04','0,04','0,04','0,04','0,04','0,04','0,04','0,04','0,04','0,04','0,04','0,04','0,04','0,04','0,04','0,04','0,04','0,04','0,04','0,04','0,04','0,04','0,05','0,05','0,05','0,06','0,06','0,06','0,07','0,07','0,07','0,08','0,08','0,08','0,09','0,09','0,09','0,09','0,09','0,09','0,09','0,09','0,09','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,1','0,11','0,11','0,11','0,11','0,11','0,11','0,11','0,11','0,11','0,11','0,11','0,11','0,11','0,11','0,11','0,11'],
    rate_used_SPE:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_SAR:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_SAK:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_SVE:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_SEV:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_SMO:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_STA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_TAM:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_TVE:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_TOM:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_TUL:['0,0124743831999646','0,03','0,04','0,06','0,07','0,08','0,1','0,11','0,13','0,14','0,16','0,17','0,18','0,19','0,2','0,21','0,22','0,23','0,24','0,25','0,26','0,27','0,28','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29','0,29'],
    rate_used_TYU:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_ULY:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_KHA:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_KHM:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_CHE:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_CHU:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_YAN:['0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0','0'],
    rate_used_YAR:['0,0538173424431793','0,06','0,07','0,07','0,08','0,09','0,09','0,1','0,11','0,11','0,12','0,13','0,13','0,13','0,14','0,14','0,14','0,14','0,14','0,15','0,15','0,15','0,15','0,16','0,16','0,16','0,16','0,16','0,16','0,17','0,17','0,17','0,17','0,17','0,17','0,18','0,18','0,18','0,18','0,18','0,19','0,19','0,19','0,19','0,2','0,2','0,2','0,2','0,21','0,21','0,21','0,21','0,21','0,21','0,21','0,21','0,21','0,21','0,21','0,21','0,21','0,21','0,21','0,21','0,21','0,21','0,21','0,21','0,21','0,21','0,21','0,21'],
    rate_used_all:['0,07','0,08','0,08','0,08','0,09','0,1','0,1','0,1','0,11','0,11','0,12','0,12','0,13','0,13','0,13','0,13','0,13','0,13','0,14','0,14','0,14','0,14','0,15','0,15','0,15','0,15','0,16','0,16','0,16','0,17','0,17','0,17','0,18','0,18','0,18','0,19','0,19','0,19','0,19','0,19','0,19','0,2','0,2','0,2','0,2','0,2','0,2','0,2','0,2','0,2','0,2','0,2','0,2','0,21','0,21','0,21','0,21','0,21','0,21','0,21','0,21','0,21','0,21','0,21','0,21','0,21','0,21','0,21','0,21','0,21','0,21','0,21'],
};
